import {React  ,useState }from 'react';
 import Secoundtest from "./secoundtest";
     //const obj = {};
 function Testarray() {
      function handleAdd() {
        const   obj = {name: 'Alice', age: 29, country: 'Austria'};
        console.log(obj);
        setData({ name: obj.name, age: obj.age } );
      }
      const [Data, setData] = useState();
    return (
        <>
      <div>
      <div className="shopping-cart  mb-1 me-3">
				 
                            <button type="button" onClick={handleAdd}>
                            Add
                          </button>
                     
                    <Secoundtest  {...Data} />
									</div>
      </div> 
      
    
      </>
    )};
  export default  Testarray;


