import React, { useState, forwardRef, useEffect,useImperativeHandle  } from 'react';
import {Modal} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import axios from "axios";
 import {useNavigate, useParams} from "react-router-dom";

const InviteCustomer = forwardRef((props, ref) => {
   const navigate = useNavigate();
      const [inviteModal , setInviteModal] = useState(false);
      const [data , setData] = useState([]);
      const [d , setD] = useState([]);

    useImperativeHandle(ref, () => ({
        showInviteModal() {
            setInviteModal(true)
        }
    
      }))      ;
      
     
      const userdata = localStorage.getItem('USER_data');
            const userData = JSON.parse(userdata);
              const userid = userData.id;     

    const [hallData, setHallData] = useState({
          Amount: '',
          ExpenseDate: '',
          ExpenseTypeID: '20',
          EmployeeID: '3',
          CompanyID: '1',
          UserID: userid,
      
     
    });
    const [errors, setErrors] = useState('')

    useEffect(() => {
      getexpensis();
             }, [])
    const getexpensis = () => {
      axios.get('https://suhba.server.com.ly/back/api/fillexpeses')
       .then(({ data }) => {
          setData(data.ExpenseType)
       })
       .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
       
       }
console.log('data',hallData)
const handleInputChange1 = (e) => {
  setErrors('')
  const { name, value } = e.target;

     if (/^\d*$/.test(value)) {
      setHallData((prevData) => ({
        ...prevData,
        [name]: value,
      }));

    }else {
      setErrors("هده الخانه لاتقبل الا الاعداد الصحيحة")

  
  
  }

};
   const handleInputChange = (e) => {
    
    const { name, value } = e.target;
    setHallData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async(e)=>{
    e.preventDefault();
    await axios.post('https://suhba.server.com.ly/back/api/expenses', hallData)
     // setNotification('User was successfully created')
     // navigate('/users')
     .catch(function (error) {
        if (error.response) {
           console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
     
      setInviteModal(false);
      props.parentFunction()
      setHallData('')
   
      ;  };
      const showInviteModal = () => {   
        
        setInviteModal(false)

    }
     

    return(
        <>
            <Modal className="modal fade" id="exampleModal1" show={inviteModal}  centered>                
                <div className="modal-content">
                <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">إضافة مصروفات الشركة</h1>
                        <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={()=>setInviteModal(false)}></button>
                    </div>
                          <form onSubmit={handleSubmit}>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-xl-12">
                      

                            <div className="row">
                                    <div className="col-xl-6">
                                        <label className="form-label ">تاريخ الصرف<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <input type="date"className="form-control" name="ExpenseDate" defaultValue={hallData.ExpenseDate} onChange={handleInputChange} />

                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <label className="form-label ">القيمة<span className="text-danger">*</span></label>
                                        <div className="input-group">
 
                                        <input type="text" className="form-control" name="Amount" defaultValue={hallData.Amount} onChange={handleInputChange1} />

                                         </div>
                                         {errors ? <div className=' alert alert-danger'>{errors}</div> : ""}

                                    </div>
                                </div>
                                <div className="col-xl-12">
                                <label className="form-label mt-3">دالك لــ<span className="text-danger">*</span></label>
                             
                                <select className="default-select form-control" name="ExpenseTypeID" defaultValue={hallData.ExpenseTypeID} onChange={handleInputChange}>
                                 <option  data-display="Select">اختر من القائمة</option>
                                 {
                                   data.map((row)=>(
                                
                                 <option key={row.ExpenseTypeID} value={row.ExpenseTypeID}>
                                            {row.TypeName}
                                    </option>
                                     ))}
                                 </select>   </div>


                               
                            </div>
                        </div>                                
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-primary" >
                       
                        حفظ</button>
                        <button type="button" className="btn btn-danger light" onClick={()=>showInviteModal()}>إلغاء</button>

                    </div> 
                     </form>
                </div>               
            </Modal>
        </>
    )
})
export default InviteCustomer;

