//import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';



function dateFormat(value){
	let objectDate = new Date(value);
	let day = ("0" + (objectDate.getDate() + 1)).slice(-2);
	let month = ("0" + (objectDate.getMonth() + 1)).slice(-2);
	let year = objectDate.getFullYear();
	
	return  day + "/" + month + "/" + year;	 
	
}


   

export const COLUMNS = [
	{
		Header : 'ItemID',
		Footer : 'HallID',
		accessor: 'ItemID',
		Filter: ColumnFilter,
		//isVisible:"false"
		//disableFilters: true,

	},
	{  
		Header : 'اسم الصنف',
		Footer : 'First Name',
		accessor: 'Name',
		Filter: ColumnFilter,
	},
	{  
		Header : 'المجموعه',
		Footer : 'First Name',
		accessor: 'FoodName',
		Filter: ColumnFilter,
	},
	{
		Header : 'نوع التخزين',
		Footer : 'Last Name',
		accessor: 'StorageTypesName',
		Filter: ColumnFilter,
	},
	{
		Header : 'السعر',
		Footer : 'Email Id',
		accessor: 'ItemPrice',
		Filter: ColumnFilter,
	},
	{
		Header : 'الحالة',
		Footer : 'Email Id',
		accessor: 'status',
		Filter: ColumnFilter,
	},
	 
	
]

export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Name',
		Footer : 'Name',
		columns: [
			{
				Header : 'First Name',
				Footer : 'First Name',
				accessor: 'first_name'
			},
			{
				Header : 'Last Name',
				Footer : 'Last Name',
				accessor: 'last_name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Date of  Birth',
				Footer : 'Date of  Birth',
				accessor: 'date_of_birth'
			},
			{
				Header : 'Country',
				Footer : 'Country',
				accessor: 'country',
			},
			{
				Header : 'Phone',
				Footer : 'Phone',
				accessor: 'phone'
			},
		]
	},
]