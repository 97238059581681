import React, { useState, forwardRef, useEffect, useImperativeHandle } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
 import "react-toastify/dist/ReactToastify.css";
const InviteCustomer = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const showInviteModal = () => {
    setInviteModal(false)
    setUser('')
  }
  const [inviteModal , setInviteModal] = useState(null);


  useImperativeHandle(ref, () => ({
    showInviteModal() {
      setUser(props.data)

        setInviteModal(true)
    }

  }))    
  const userdata = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata);
  const userid = userData.id;
  const notifyTopRight = () => {
    toast.success("✔️ تمت عملية التعديل بنجاح", {
      position: "top-right",
      autoClose: 800,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });
  };  
  //------------------------
  const [user, setUser] = useState({
    Name: '',
    CategoryID: '',
    StorageID: '',
    ItemPrice: '',
    QuantityInStock: '1',
    UserID: userid,
    status: '',
    extension:''
  });

  useEffect(() => {
    getFoodCatigory();
    getstorage();
    setUser(props.data)

  }, [props.data, setUser]);
  console.log('user', user);

  //--------------------------------------------------
  const [catigorydata, setCatigorydata] = useState([])
  const [storagedata, setStoragedata] = useState([])
  const onSubmit = ev => {
    ev.preventDefault()
    //console.log('userid',user.ExpenseID);

    axios.put(`https://suhba.server.com.ly/back/api/food_items/${user.ItemID}`, user)
      .then(() => {
        setUser('')
        props.parentFunction()
        notifyTopRight()

        setInviteModal(false)
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });

  }
  console.log('uder', user)
  const getFoodCatigory = () => {
    axios.get('https://suhba.server.com.ly/back/api/getFoodCatigory')
      .then(({ data }) => {
        setCatigorydata(data.FoodCategory)
      })
  }
  const getstorage = () => {
    axios.get('https://suhba.server.com.ly/back/api/getstorage')
      .then(({ data }) => {
        setStoragedata(data.storageType)
      })
  }
  useEffect(() => {
    const handleEnterKey = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const form = e.target.form;
        const fields = Array.from(form.elements).filter(el => (
          (el.tagName === 'INPUT' && !el.disabled) ||
          (el.tagName === 'TEXTAREA' && !el.disabled) ||
          (el.tagName === 'SELECT' && !el.disabled)
        ));      const currentFieldIndex = fields.findIndex(el => el === document.activeElement);
        const nextFieldIndex = currentFieldIndex + 1;
        if (nextFieldIndex < fields.length) {
          fields[nextFieldIndex].focus();
        }
      }
    };
    
    document.addEventListener('keydown', handleEnterKey);
    
    return () => {
      document.removeEventListener('keydown', handleEnterKey);
    };
  }, []);
   
  //---------------------------------------------
  return (
    <>
      <div>
      <ToastContainer />

      </div>
      <Modal className="modal fade" id="exampleModal1" show={inviteModal} centered>


        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">تعديل بيانات الاصناف</h1>
            <button type="button" className="btn-close " style={{ marginLeft: 0 }} padding="" onClick={() => showInviteModal()}></button>
          </div>
          <form onSubmit={onSubmit}>
            <div className="modal-body">
                 <div className="col-xl-12">
                  <div className="row">
                    <div className="col-xl-2 p-0">
                      <label className="form-label  ">اسم الصنف</label>
                    </div>
                    <div className="col-xl-4">
                      <div className="input-group ">
                        <input style={{ height: "2rem" }}
                          type="text"
                          pattern="[a-zA-Z\u0600-\u06FF\s]+"
                          title="الرجاء إدخال أحرف فقط، الفراغات، والحروف العربية"

                          required
                          id="inputcity"
                          name="Name"
                          className="form-control "
                          value={user.Name}
                          onChange={ev => setUser({ ...user, Name: ev.target.value })} />
                      </div>   </div>
                    <div className="col-xl-2">
                      <label className="form-label  ">السعر </label>
                    </div>
                    <div className="col-xl-4">
                      <div className="input-group ">
                        <input style={{ height: "2rem" }}
                          type="text"
                          pattern="\d+(\.\d{1,2})?"
                          title="الرجاء إدخال ارقام فقط"

                          required
                          id="inputcity"
                          name="ItemPrice"
                          className="form-control "
                          value={user.ItemPrice}
                          onChange={ev => setUser({ ...user, ItemPrice: ev.target.value })}
                        />
                      </div>   </div>
                 

                   <div className="col-xl-2">

                    <label className="form-label mt-3">المجموعة</label>
</div>
<div className="col-xl-10">

                    <select className="default-select form-control  mt-3"
                      name="CategoryID"
                      value={user.CategoryID}
                      onChange={(ev) => setUser({ ...user, CategoryID: ev.target.value })}
                    >
                      <option value="" data-display="Select">
                        {user.CategoryID}
                      </option>
                      {catigorydata.length > 0 &&
                        catigorydata.map((row) => (
                          <option key={row.FoodCategoryID} value={row.FoodCategoryID}>
                            {row.FoodName}
                          </option>
                        ))}
                    </select>
</div>





                  <div className="col-xl-2">
                    <label className="form-label mt-3">نوع التخزين<span className="text-danger">*</span></label>
</div>
<div className="col-xl-10">

                    <select className="default-select form-control  mt-3"
                      name="StorageID"
                      value={user.StorageID}
                      onChange={(ev) => setUser({ ...user, StorageID: ev.target.value })}
                    >
                      
                      {catigorydata.length > 0 &&
                        storagedata.map((row) => (
                          <option key={row.StorageTypesID} value={row.StorageTypesID}>
                            {row.StorageTypesName}
                          </option>
                        ))}
                    </select>






                  </div>
                  <div className="col-xl-2 ">
                                        <label className="form-label mt-3 ">إضافات </label> 
                                         </div>
                                        <div className="col-xl-4">
                                        <div className="input-group ">
                                            <input style={{  height: "2rem" }} 
                                            type="text" 
                                            pattern="[a-zA-Z\u0600-\u06FF\s]+"
                                            title="الرجاء إدخال أحرف فقط، الفراغات، والحروف العربية"
                                         
                                               
                                            id="inputcity"
                                              name="extension"
                                            className="form-control mt-3" 
                                            value={user.extension} 
                                            onChange={(ev) => setUser({ ...user, extension: ev.target.value })}
                                            />
                                        </div>   </div>

                  <input type="text" hidden="true" name="UserID" value={userid} />
                  <input type="text" id="ItemID" hidden="true" name="ItemID" value={props.ItemID} />

                </div>
              </div>
            </div>



            <div className="modal-footer">
              <button type="submit" className="btn btn-primary" >

                حفظ</button>
              <button type="button" className="btn btn-danger light" onClick={() => showInviteModal()}>إلغاء</button>

            </div>
          </form>
        </div>
      </Modal>
    </>
  )
})
export default InviteCustomer;