import React,{ useRef,useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import MOCK_DATA from '../jsx/components/table/FilteringTable/MOCK_DATA_2.json';
import { COLUMNS } from '../jsx/components/table/FilteringTable/ColumnsComExpenses copy';
 import { Link,useNavigate } from "react-router-dom";
 import axios from "axios";
 import swal from 'sweetalert'; 
import '../jsx/components/table/FilteringTable/filtering.css';
import { Col, Row ,Dropdown} from 'react-bootstrap';
import InviteCustomer1 from '../jsx/constant/Employeeexpences';
import InviteCustomer from '../jsx/constant/EditCompanyExpenses';
//import InviteCustomer2 from '../jsx/constant/AsnafExpenses';

import  { useEffect,useState     } from 'react';


export const CompanyExpenses = () => {
	const columns = useMemo( () => COLUMNS, [] )
	//const data = useMemo( () => MOCK_DATA, [] )
  const [data, setData] = useState([])
  const [data2, setData2] = useState([])

  const [ID, setID] = useState(0)
  const [inviteModal , setInviteModal] = useState(false);
	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0}
	}, useFilters, useGlobalFilter, usePagination)
	
	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance
    const {globalFilter, pageIndex} = state	
    const navigate = useNavigate();

    const userdata = useRef();
    const invite1 = useRef();
    const invite = useRef();
    const invite2 = useRef();

    useEffect(() => {
      fetchProducts();
  }, [])

  const fetchProducts = async () => {
      await axios.get('https://suhba.server.com.ly/back/api/empexpences').then(({ data }) => {setData(data)}) 
  }
    const deleteUser = async (e,id)=> {
        e.preventDefault();
        swal({
          title: "هل متأكد من الحدف",
          text: " في حال حدف الملف لايمكنك استعادة هدا الملف",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then(willDelete => {
          if (willDelete) {
    
            axios.delete(`https://suhba.server.com.ly/back/api/expenses/${id}`)
            
              .then(res => {
                fetchProducts();
                swal({
                  title: "تم",
                  text: "تم الحدف بنجاح",
                  icon: "success",
                  timer: 2000,
                  button: false
                })
                navigate('#')
            
               
            });
        
          } else {
            swal("تم الغاء عملية الحدف");
          }
        
        });
    
    };
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
          </g>
        </svg>
    )

    const EditData = async (e,id)=> {
      console.log(id);
      data.map((item) => {
        if (id == item.ExpenseID) {
          setData2(item)}})
        
        
      setID(id);setInviteModal(true)
     // invite.current.showEmployeModal()
    }
    const show =(value)=>
{
setInviteModal(value)

}
const [totalSum, setTotalSum] = useState(0);
useEffect(() => {
  // Calculate the total sum of the amounts whenever `data` changes
  let sum = 0;
  page.forEach(item => {
    const amountStr = item.values?.Amount || item.original?.Amount;
    const amount = parseFloat(amountStr);
    if (!isNaN(amount)) {
      sum += amount;
    }
  });

  setTotalSum(sum);
}, [page]); // This useEffect runs whenever `data` changes

console.log("page",totalSum)
    return(
<>
<div className='container-fluid'>
				 
                 <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting p-0">   

                 <div className="tbl-caption p-0"  style={{float:"left"}}>
                  <div>
                        < Link to={"#"} className="btn btn-primary btn-sm m-1" data-bs-toggle="offcanvas"
                                 onClick={() => invite1.current.showInviteModal()}
                               > + إضافة مصروفات </Link>   
                                  
                                 <Link to={"#"} className="btn btn-secondary btn-sm m-1" data-bs-toggle="offcanvas"
                                 ><i class="fa-solid fa-print ms-1"></i>طباعه</Link> 
                                 <Link to={"#"} className="btn btn-secondary btn-sm m-1" data-bs-toggle="offcanvas">
                                 <i className="fa-solid fa-file-excel" /> تصدير كاملف اكسل </Link>                             

                            </div>
                            
                            
                        </div>  
                        <div className="col-md-4" style={{display :'flex'}}>
                              
                            <i style={{color: 'green'}} class="fa-solid fa-money-bill-transfer fa-xl m-2"></i>

          <label htmlFor="startMonth" style={{fontsize: '15px', fontWeight: 'bold',color: 'blue'}}>  اجمالي الصرف :</label>
       <div style={{color: 'green'}}> 
       <strong>{totalSum}</strong>  
        </div>  </div>
                        </div>     
                   
                       
                        		 
								<div className="table-responsive">
									<table {...getTableProps()} className="table dataTable display table-hover">
										<thead>
										{headerGroups.map(headerGroup => (
												<tr {...headerGroup.getHeaderGroupProps()}>
												      {headerGroup.headers.map((column) => (
                        // Check if the column accessor is 'age' and render accordingly
                        column.id !== 'ExpenseID' ? (
                          <th {...column.getHeaderProps()}>
                            {column.render('Header')}
                            {column.canFilter ? column.render('Filter') : null}
                          </th>
                        ) : null
                      ))}
												</tr>
										))}
										</thead> 
                                        <tbody {...getTableBodyProps()} className="" >
                                        
                                        {page.map((row) => {
                                            prepareRow(row)
                                            return(
                                                <tr {...row.getRowProps()}>

                                                    {row.cells.map((cell) => (
          // Check if the column accessor is 'age' and render accordingly
          cell.column.id !== 'ExpenseID' ? (
            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
          ) :null
        ))}


                                                     <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        className="light sharp i-false" 
                      >
                           {svg1}
                       </Dropdown.Toggle>
                      <Dropdown.Menu dir="rtl" className="dropdown-menu-right">
                        <Dropdown.Item>
                        <Link to className=""
                        onClick={() => invite.current.showInviteModal()}
                        >عرض
                            </Link>
                            </Dropdown.Item>    

                        <Dropdown.Item> 
                       
                            
                            


                        </Dropdown.Item>
                        <Dropdown.Item>                               
                                                        
                         <Link to className=""
                               onClick={(e) =>EditData(e,row.original.ExpenseID)}
                               >تعديل
                            </Link>
                        </Dropdown.Item>

                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
									</table>



									<div className="d-flex justify-content-between mx-2">
										<span>
											الصفحه{' '}
											<strong>
												{pageIndex + 1} من {pageOptions.length}
											</strong>{''}
										</span>
										<span className="table-index ">
											ادهب الى : {' '}
											<input type="number" 
												className="ml-2"
												defaultValue={pageIndex + 1} 
												onChange = {e => { 
													const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
													gotoPage(pageNumber)
												} } 
											/>
										</span>
									</div>
                                    <div className="text-center mb-3">  
                                        <div className="filter-pagination  mt-3">
                                            <button className="previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                                {/* {'<'} */}
                                                <i className='fa-solid fa-angle-right' />
                                            </button>
                                            
                                            <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                                السابق
                                            </button>
                                            <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                                التالي
                                            </button>
                                            <button className="next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                                {/* {'>'} */}
                                                <i className='fa-solid fa-angle-left' />
                                            </button>
                                        </div>
                                    </div>
								</div>
						 
						 
			</div>
            <InviteCustomer1
                ref={invite1}     
                parentFunction={fetchProducts}  
            /> 
             
             <InviteCustomer
                
                data={data2}
                parentFunction={fetchProducts}  
                inviteModal={inviteModal}
                show={show}
            /> 
</>


    );
}
export default CompanyExpenses;