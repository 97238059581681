import {Navigate, Outlet} from "react-router-dom";
import { useStateContext } from "../context/ContextProvider";
import "../jsx/other/swiper/swiper-bundle.min.css";
import "../jsx/other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "../../src/css/style.css";
 
export default function GuestLayout() {
  const { user, token } = useStateContext();

 /* if (token) {
    return <Navigate to="/Dashboard_1" />;
  }*/
  return (
    <div id="guestLayout">
      <Outlet />
    </div>
  );
}
