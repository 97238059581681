import React, { Fragment, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import swal from 'sweetalert';
import axios from "axios";
import { Badge } from "react-bootstrap";

import useRef from "react";
import {
  Row,
  Col,
  Card,
  Popover,
  Tooltip,
  OverlayTrigger,
  Button,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Tab, Nav, ListGroup } from "react-bootstrap";
 import { Modal } from 'react-bootstrap';

const messageBlog = [
  { id: '01' }, { id: '02' }, { id: '03' }, { id: '04' }, { id: '05' },
  { id: '06' }, { id: '07' }, { id: '08' }, { id: '09' }, { id: '10' },
  { id: '11' }, { id: '12' }, { id: '13' }, { id: '14' }, { id: '15' },
  { id: '16' }, { id: '17' }, { id: '18' }, { id: '19' }, { id: '20' },
  { id: '21' }, { id: '22' }, { id: '23' }, { id: '24' }, { id: '25' },
  { id: '26' }, { id: '27' }, { id: '28' }, { id: '29' }, { id: '30' }
];

const Orders = forwardRef((props, ref) => {
  const navigate = useNavigate();


  const [startDate, setStartDate] = useState(new Date());
  const [inviteModal, setInviteModal] = useState("hidden");
  const [inviteModal1, setInviteModal1] = useState(true);
  const [listItem, setlistItem] = React.useState([])
  const [listItem1, setlistItem1] = useState([])
  const [popupModal, setpopupModal] = useState(false);

  useImperativeHandle(ref, () => ({
    showInviteModal() {
      setInviteModal("hidden")
      setInviteModal1(true)

    }
  }));
  const location = useLocation();
  const location2 = useLocation();
  const [itemName, setItemName] = useState('');

  const [cName, setCName] = useState('');
  const [last, setlast] = useState(0)
  console.log("Location search:", location2.search);
   const searchParamscoust = new URLSearchParams(location2.search);
   const receivedDataArrayss = JSON.parse(searchParamscoust.get('data')) || [];

   const receivedcoustId = searchParamscoust.get('id') || '';
   const receivedcoustname= searchParamscoust.get('cname') || '';
  const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');
  console.log('fffffff', receivedDataArrayss)

  // Parse the JSON string back to an array
  const userPermissions = JSON.parse(userPermissionsJSON);
  // Function to search for permissions for a specific page
  function getActionsForPage(pageName) {
    // Find the permissions object for the given page
    const pagePermissions = userPermissions.find(permission => permission.resource === pageName);
  console.log('actionsForPage',pagePermissions); 
  
    // If permissions for the page are found, return the actions
    if (pagePermissions) {
        return pagePermissions.action;
    } else {
   navigate('/dashboard_1')
  
        return [];
    }
  }
  
  // Usage
  const pageName = 'Orders';
  const actionsForPage = getActionsForPage(pageName);

  const [data, setData] = useState([])
  const [qun, setQun] = useState(0)
  const [data1, setData1] = useState([])
  const [total, setTotal] = useState(0)
  const [atbak, setAtbak] = useState([])


  const showInviteModal1 = () => {

    setInviteModal1(false)
    setInviteModal("Block")
  }
  const notifyInfo = () => {
    swal({
      title: "هل متاكد من الغاء هده العملية",
      text:
        "بمجرد الضفظ على نعم سيتم اعادة توجيهك الى الصفحه الرئيسية",
      icon: "warning",
      buttons: true,
      showCancelButton: true,
      dangerMode: true,
      confirmButtonAriaLabel: 'نعم',
      cancelButtonText: "العودة",
    }).then((willDelete) => {
      if (willDelete) {
        navigate('/dashboard_1')
      } else {
        setInviteModal(false)
      }
    })

  }

  const handleDelete = (index) => {
    // Create a copy of the list
    const updatedList = [...listItem];

    // Remove the item at the specified index
    updatedList.splice(index, 1);
    const totalAA = updatedList.reduce((total, item) => total + item.aa, 0);
    setTotal(totalAA);
    // Update the state with the new list
    setlistItem(updatedList);
  };



  const [catigorydata, setCatigorydata] = useState([])
  const input = document.querySelector('input[name="city"]');
  if (input) {
    input.addEventListener('invalid', function (event) {
      if (event.target.validity.valueMissing) {
        event.target.setCustomValidity('الرجاء ادخال  القيمة');
      }
    })

    input.addEventListener('change', function (event) {
      event.target.setCustomValidity('');
    })
  }
  const fetchatbak = async () => {
    await axios.get('https://suhba.server.com.ly/back/api/Atbaks').then(({ data }) => {
      setAtbak(data)
      console.log('new data', atbak)
    })
  }
  const getFoodCatigory = () => {
    axios.get('https://suhba.server.com.ly/back/api/getFoodCatigory')
      .then(({ data }) => {
        setCatigorydata(data.FoodCategory)
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });

  } 
 

  const fetchProducts = async () => {
    //await axios.get('https://suhba.server.com.ly/back/api/order_items').then(({ data }) => { setData(data) })
    axios.get('https://suhba.server.com.ly/back/api/order_items')
    .then(response => {
      setlistItem1(response.data.result)
    const itemMap = {};
    response.data.result.forEach(item => {
      if (itemMap[item.ItemID]) {
        itemMap[item.ItemID].totalQuantity += item.Quantity;
        itemMap[item.ItemID].totalprice += item.ItemPrice ? item.ItemPrice * item.Quantity : 0;
      } else {
        itemMap[item.ItemID] = {
          ItemID: item.ItemID,
          Name: item.Name,
          CategoryID:item.CategoryID,
          totalQuantity: item.Quantity,
          ItemPrice: item.ItemPrice,
          totalprice: item.ItemPrice ? item.ItemPrice * item.Quantity : 0
        };
      }
    });
    const totalQuantityByItem = Object.values(itemMap);
    totalQuantityByItem.sort((a, b) => b.totalQuantity - a.totalQuantity);
    setData(totalQuantityByItem);
  })
  }

  const handleSearch = () => {
    console.log('تتت',itemName);
const filteredData = listItem1.filter(item => {
 		  
		  // Check if the item name includes the entered item name
		 // const itemNameMatch = item.Name.toLowerCase().includes(itemName.toLowerCase());
		 const itemNameMatch = item.Name.toString().includes(itemName.toString());
		  // Check if month and year filters are provided
 	  
		  // Return true if all conditions match
		  return  itemNameMatch;
		});
    const itemMap = {};

    filteredData.forEach(item => {
      if (itemMap[item.ItemID]) {
        itemMap[item.ItemID].totalQuantity += item.Quantity;
        itemMap[item.ItemID].totalprice += item.ItemPrice ? item.ItemPrice * item.Quantity : 0;
      } else {
        itemMap[item.ItemID] = {
          ItemID: item.ItemID,
          Name: item.Name,
          CategoryID:item.CategoryID,
          totalQuantity: item.Quantity,
          ItemPrice: item.ItemPrice,
          totalprice: item.ItemPrice ? item.ItemPrice * item.Quantity : 0
        };
      }
    });

    const totalQuantityByItem = Object.values(itemMap);
    totalQuantityByItem.sort((a, b) => b.totalQuantity - a.totalQuantity);
     console.log("totalQuantityByItem",totalQuantityByItem)
     setFilteredData(totalQuantityByItem);
    

     
  };
  const [activeTab, setActiveTab] = useState('tools');
  const [filteredData, setFilteredData] = useState([]);

  console.log('activeTab', activeTab)

  const handleTabSelect = (key) => {
    setActiveTab(key);
    // setFilteredData([])
    // const acc =[]
    const filtered = data.reduce((acc, item) => {

      if (item.CategoryID == key) {
        acc.push(item);
      }
      return acc;
    }, []);

    setFilteredData(filtered);
    console.log('filtered', filtered)
  };

 
  useEffect(() => {
  
   if (receivedcoustId != null) {
    setlast(receivedcoustId)
    setCName(receivedcoustname)
    console.log('receivedid', receivedcoustId)

   }
    fetchProducts();
 
    getcities();
    getFoodCatigory();
    fetchProducts1();
    fetchatbak();
    //getLastCustomer();
    //if (receivedItemId != null) {
      //setlast(receivedItemId)
      //setlistItem(receivedDataArray)
      //setInviteModal1(false)
   // }
    const filtered = data.filter((item) => item.CategoryID == activeTab);
    setFilteredData(filtered);
  }, []);
  console.log('activeTab', data)

  console.log('filteredData',filteredData)
  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setCustomers((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [Hideshow, setHideshow] = useState(false);

  const handleInputChange = (e) => {
    const input = e.target.value;

    // Check if the input is a number or an empty string
    if (/^\d*$/.test(input)) {
      setQun(input);
      setErrors('')

    } else {
      setErrors("هده الخانه لاتقبل حروف")

    }
  };
  const popupfunction = () => {
   // setQun('');
    setpopupModal(false)
    setErrors('')
  }
  const [errors, setErrors] = useState('')


  const listClickHandler = (id, jop, itemid, TItemID, AtbakID,CategoryID) => {
    console.log('itemid', itemid)
    if (qun === '') {
      setErrors("يجب ادخال الكمية")

    } else {
      if (TItemID != '') {
        data1.map((item) => {
          if (TItemID == item.TItemID) {
      
              const calc = 0;
              let cal = jop * qun;
              let updatedList;
              const qunAsNumber = parseInt(qun, 10) || 0; // Convert qun to a number, defaulting to 0 if conversion fails
              const itemExists = listItem.some(
                (item) => item.ItemName === id && item.ItemID === itemid
              );

              if (!itemExists) {
              //  updatedList = listItem.map((item) => {
                 // if (item.ItemName === id && item.ItemID === itemid) {
                 //   return {
                  //    ...item,
                  //    aa: item.aa + cal,
                  //    Quantity: item.Quantity + qunAsNumber,
                   // };
                  //}
                 // return item;
                //});
             // } else {
                updatedList = [
                  ...listItem,
                  { ItemName: id, ItemPrice: jop, aa: cal, Quantity: qunAsNumber, ItemID: itemid, TItemID: TItemID, AtbakID: AtbakID ,FoodCategoryID:CategoryID},
                ];
              }

              setlistItem(updatedList);
              const totalAA = updatedList.reduce((total, item) => total + item.aa, 0);
              setTotal(totalAA);
           //   setQun('');
              setpopupModal(false)
              setErrors('')

         
          }
        })
      }
      else {
        const calc = 0;
        let cal = jop * qun;
        let updatedList;
        const qunAsNumber = parseInt(qun, 10) || 0; // Convert qun to a number, defaulting to 0 if conversion fails
        const itemExists = listItem.some(
          (item) => item.ItemName === id && item.ItemID === itemid
        );

        if (itemExists) {
          updatedList = listItem.map((item) => {
            if (item.ItemName === id && item.ItemID === itemid) {
              return {
                ...item,
                aa: item.aa + cal,
                Quantity: item.Quantity + qunAsNumber,
              };
            }
            return item;
          });
        } else {
          updatedList = [
            ...listItem,
            { ItemName: id, ItemPrice: jop, aa: cal, Quantity: qunAsNumber, ItemID: itemid, TItemID: TItemID, AtbakID: AtbakID, FoodCategoryID:CategoryID},
          ];
        }

        setlistItem(updatedList);
        const totalAA = updatedList.reduce((total, item) => total + item.aa, 0);
        setTotal(totalAA);
     //   setQun('');
        setpopupModal(false)
        setErrors('')

      }
    }
  }
  const handleEdit =(indexToUpdate,updatedItem)=> {
const updatedItems = [...listItem];
   const itemToUpdate = updatedItems[indexToUpdate];
   itemToUpdate.Quantity = updatedItem;
   itemToUpdate.aa=(itemToUpdate.ItemPrice * updatedItem)
   setlistItem(updatedItems);
   const totalAA = updatedItems.reduce((total, item) => total + item.aa, 0);
   setTotal(totalAA);
   
console.log("updatedItems",updatedItems)
  }
  
  const [Name, setName] = useState('');
  const [ItemPrice, setItemPrice] = useState('');
  const [ItemID, setItemID] = useState('');
  const [TItemID, setTItemID] = useState('');
  const [TName, setTName] = useState('');
  const [TabakName, setTabakName] = useState('');
  const [AtbakID, setAtbakID] = useState('');
  const [FoodCategoryID, setFoodCategoryID] = useState('');


  const listClickHandler1 = (id, jop, itemid, field,CategoryID) => {
    if (field === 'tool') {
      setTItemID(itemid)
      setTName(id)
      setItemPrice(jop)
      setTabakName('')
      setAtbakID('')
      setItemID('')
      setName(id)

    } else
      if (field === 'tabak') {
        setTabakName(id)
        setAtbakID(itemid)
        setItemPrice(jop)
        setTItemID('')
        setTName('')
        setName(id)

        setItemID('')
      } else {
        setFoodCategoryID(CategoryID)
        setItemPrice(jop)
        setItemID(itemid)
        setName(id)
        setTabakName('')
        setAtbakID('')
        setTItemID('')
        setTName('')

      }
 setpopupModal(true);
if (qun != 0){
   
  setHideshow(true)
  }
  }
  // const queryString = new URLSearchParams({ data: JSON.stringify(listItem) }).toString();

  const [city, setCity] = useState([])
console.log('listItemlistItemlistItemlistItem',listItem)
  const queryString = new URLSearchParams({
    data: JSON.stringify(listItem),
    id: last.toString(),
    cname:cName,
    //total:total,

  }).toString();
    console.log('queryString', queryString)

  //const searchParams = new URLSearchParams(location.search);
  //const receivedDataArray = JSON.parse(searchParams.get('data')) || [];
  //const receivedItemId = searchParams.get('id') || '';
  //console.log('receivedDataArray', receivedDataArray)
  const userdata = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata);
    const userid = userData.id;
  const [customers, setCustomers] = useState({
    CustomerID: '',
    CustomerName: '',
    Notes: 'ww',
    AreaID: '',
    PhoneNumber: '',
    PhoneNumber1: '22',
    PhoneNumber2: '11',
    UserID: userid,
  });
  const getcities = () => {
    axios.get('https://suhba.server.com.ly/back/api/areas')
      .then(({ data }) => {
        setCity(data)
      })
  }
  const getLastCustomer = () => {
    try {
      axios.get('https://suhba.server.com.ly/back/api/getLastCustomer')
        .then(({ data }) => {
          setlast(data.CustomerID)
        })
    } catch (error) {
      console.error('Error fetching last customer:', error);
    }
  }

  //CustomerID 	CustomerName 	Notes 	AreaID 	PhoneNumber 	PhoneNumber1 	PhoneNumber2 	UserID
 /*  const createintitem = async (e) => {
    e.preventDefault();
     if (!customers.PhoneNumber.match(/^(218)?09[123456][0-9]{7}$/) || !customers.PhoneNumber1.match(/^(218)?09[123456][0-9]{7}$/)
    ||!customers.PhoneNumber2.match(/^(218)?09[123456][0-9]{7}$/)) {
   
setErrors('رقم الهاتف  : 00 00 000 091 او 00 00 000 092')
console.log(errors)
   }
else{
    await axios.post('https://suhba.server.com.ly/back/api/customers', customers).then(({ data }) => {
      setlast(data.CustomerID)
    setCName(data.CustomerName)
    console.log('last in', data.CustomerID)

  })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
    //getfooditeming(Name);
   // getLastCustomer();
   // console.log('last', last)
    setInviteModal1(false)

  }}*/
  const fetchProducts1 = async () => {
    await axios.get('https://suhba.server.com.ly/back/api/gettoolitem').then(({ data }) => { setData1(data.ToolsItem) })

   .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });}
  
  const handleClearSearch = () => {
   
    setItemName('');
    fetchProducts();
    const filtered = data.filter((item) => item.CategoryID == activeTab);
    setFilteredData(filtered);
  };
  return (

    <>
    <div className="" id="exampleModal1" style={{ visibility: { inviteModal } }} centered>

      <div className="container-fluid " style={{padding:'0.500rem'}}>
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-0 h-auto">
              <div className="card-body p-0">
                <div className="row gx-0">
                  <div className="col-xl-10 col-xxl-9 col-lg-9">
                  <div className="email-left-box dlab-scroll  pt-3 ps-2">
                <center>  <div className="col-md-8 pb-3" style={{ display: 'flex' }}>
                <label htmlFor="itemName" className="form-label ms-2 mt-3" style={{whitespace: "nowrap"}}>بحث </label>
                  <input style={{  height: "2rem" }} 
                                          type="text" 
                                             
                                             name="itemName"
                                          className="form-control mt-3" 
                                          value={itemName} 
                                          onChange={e => {
                                            setItemName(e.target.value);
                                            //handleSearch();
                                          }}
                                          
                                          />
                        
        
           
              <div className="col-md-3 ">
                <button className="btn btn-primary btn-sm mt-3 me-1" onClick={handleSearch}>ابحت</button>
                <button className="btn btn-secondary btn-sm  mt-3 me-1" onClick={handleClearSearch}> الغاء</button>

               </div></div> </center>
                    <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
                      <div role="toolbar" className="toolbar ms-1 ms-sm-0">
                        <div className="saprat">
                          <div className="d-flex align-items-center">

                            <Nav as="ul" className="nav nav-pills" id="pills-tab" role="tablist">
                            
                            {/* <Nav.Item as="li" className="nav-item btn-group" role="presentation">
                                <Nav.Link as="button" eventKey={'Tabak'} type="button" className="btn effect mx-2 nav-link" id="pills-home-tab" >
                                  <i class="fa-solid fa-plate-wheat"></i>                                                                                           اطباق
                                </Nav.Link>
                              </Nav.Item>*/} 
                              {
                                catigorydata.length > 0 && (
                                  catigorydata.map((row) => (

                                    <Nav.Item as="li" className="nav-item btn-group" role="presentation" key={row.FoodCategoryID} >
                                      <Nav.Link as="button" eventKey={` ${row.FoodCategoryID}`} type="button" className="btn effect mx-2 nav-link" id="pills-home-tab" >
                                        <i className={`fa-solid ${row.Description} ms-2`}></i>
                                        {row.FoodName}
                                      </Nav.Link>
                                    </Nav.Item>
                                  ))
                                )
                              }
                                <Nav.Item as="li" className="nav-item btn-group" role="presentation">
                                <Nav.Link as="button" eventKey={'tools'} type="button" className="btn effect mx-2 nav-link" id="pills-home-tab" >
                                  <i className="fa-solid fa-kitchen-set"></i>                                                                                            معدات
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>

                          </div>

                        </div>
                      </div>
                      {/** Single Message */}


                      <Tab.Content>
                      
                      {/*   <Tab.Pane eventKey={'Tabak'}>
                          <div className="row mt-2">

                            {
                              atbak.length > 0 && (
                                atbak.map((row, key) => (

                                  <div className="col-xl-2 col-xxl-3 col-md-4 col-sm-6" key={row.ItemID}>
                                    <div className="card">


                                      <div className="col-lg-12">
                                        <Link to className="btn  mx-2  p-0 pt-1  effect nav-link nav-link"
                                          onClick={() => listClickHandler1(row.TabakName, row.TabakPrice, row.AtbakID, 'tabak')}   >
                                          <div className="col-sm-12">
                                            <div className="new-arrival-content text-center ">
                                              <h5>
                                                <span className="" >{row.TabakName}</span> </h5>
                                            </div>
                                          </div>

                                          <div className="col-sm-12">

                                            <div className="new-arrival-content text-center ">

                                              <h4> <span className="price" > {row.TabakPrice}د </span> </h4>
                                            </div> </div>
                                        </Link>
                                        <div className="col-sm-12">
                                          <div className="new-arrival-content text-center ">

                                            <span className="item">
                                              <p>
                                                المتوفر حاليا:{" "}
                                                {row.QuantityInStock}
                                                {row.QuantityInStock === 0 ? (
                                                  <i className="fa fa-check-circle text-danger me-2" />
                                                ) : (
                                                  <i className="fa fa-check-circle text-success me-2" />
                                                )}
                                              </p> </span>
                                            <div className="bootstrap-popover-wrapper">
                                              <div className="bootstrap-popover d-inline-block">
                                                <OverlayTrigger
                                                  trigger="click"
                                                  placement="bottom"
                                                  responsive={true}
                                                  overlay={
                                                    <Tooltip className='tooltip-popover' style={{ width: "150px" }} id="popover-positioned-bottom">
                                                      <h3 className='popover-header'> الاصناف</h3>
                                                      <strong>
                                                        {row.orderItems.map(orderItem => (
                                                          <li key={orderItem.ItemID}>
                                                            {orderItem.Name}
                                                          </li>
                                                        ))}

                                                      </strong>
                                                    </Tooltip>
                                                  }
                                                >
                                                  <Button variant="primary" size="sm" className="">
                                                    الاصناف
                                                  </Button>
                                                </OverlayTrigger>
                                              </div>
                                            </div>
                                          </div> </div>


                                      </div>
                                    </div>

                                  </div>
                                ))
                              )
                            }
                          </div>

                        </Tab.Pane>*/}
                        <Tab.Pane eventKey={activeTab}>
                          <div className="row mt-2">

                            {
                              filteredData.length > 0 && (
                                filteredData.map((row) => (


                                  <div className="col-xl-2 col-xxl-3 col-md-4 col-sm-6" key={row.ItemID}>
                                    <div className="card">
                                      <Link to className="btn  mx-2 effect nav-link nav-link"
                                        onClick={() => listClickHandler1(row.Name, row.ItemPrice, row.ItemID  ,'item', row.CategoryID)}   >

                                        <div className="col-lg-12">

                                          <div className="col-sm-12">
                                            <div className="new-arrival-content text-center ">
                                              <h5>
                                                <span className="" >{row.Name}</span> </h5>
                                            </div>
                                          </div>

                                          <div className="col-sm-12">

                                            <div className="new-arrival-content text-center ">

                                              <h4> <span className="price" > {row.ItemPrice}د </span> </h4>
                                            </div> </div>






                                        </div>
                                      </Link>
                                    </div>

                                  </div>
                                ))
                              )
                            }
                          </div>

                        </Tab.Pane>
                        <Tab.Pane eventKey={'tools'}>
                          <div className="row mt-2">

                            {
                              data1.length > 0 && (
                                data1.map((row, key) => (

                                  <div className="col-xl-2 col-xxl-3 col-md-4 col-sm-6" key={row.ItemID}>
                                    <div className="card">
                                      <Link to className="btn  mx-2 effect nav-link nav-link"
                                        onClick={() => listClickHandler1(row.TName, row.TItemPrice, row.TItemID, 'tool')}   >

                                        <div className="col-lg-12">

                                          <div className="col-sm-12">
                                            <div className="new-arrival-content text-center ">
                                              <h5>
                                                <span className="" >{row.TName}</span> </h5>
                                            </div>
                                          </div>

                                          <div className="col-sm-12">

                                            <div className="new-arrival-content text-center ">

                                              <h4> <span className="price" > {row.TItemPrice}د </span> </h4>
                                            </div> </div>






                                        </div>
                                      </Link>
                                    </div>

                                  </div>
                                ))
                              )
                            }
                          </div>

                        </Tab.Pane>
                      </Tab.Content>

                    </Tab.Container>
                  </div>
                </div>
                  <div className="col-xl-2 col-xxl-3 col-lg-3">
                    <div className="email-left-box p-0">
                      <div className=" ">  
                       <div className="card-header border-0">
                          <h4 className="heading mb-0">محتويات السلة</h4>
                     <Badge as="a" href="" bg="primary badge-circle">
                      <i class="fa fa-shopping-basket me-2"></i> {listItem.length}
                    </Badge>
                    <span className="badge badge-primary badge-pill">

                    </span></div>
                    {/*<Link to={`/Orders?${SecqueryString}`} className="btn btn-primary">السابق</Link>*/}

                 
                        <div className="card-body p-0">
                          <div className="table-responsive active-projects dlab-scroll hover" style={{ height: "400px" }}>
                            <table id="projects-tbl4" className="table">
                              <thead>
                                <tr>
                                  <th>اسم الصنف</th>
                                  <th>الكمية</th>
                                  <th>السعر</th>
                                  <th>الغاء</th>
                                </tr>
                              </thead>
                              <tbody>
                                {listItem.map((item, index) => (
                                  <tr key={index} >
                                    <td>{item.ItemName}
                                    </td>


                                    <td
                                    contentEditable
                                    onBlur={(e) => handleEdit(index, e.target.textContent)}
                                     > {item.Quantity}</td>
                                    <td>{item.aa}د</td>
                                    <td>     <Link onClick={() => handleDelete(index)}><i class="fas fa-trash-alt"



                                    ></i></Link></td>

                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>



                          <table className="table table-clear">
                            <tbody>
                              <tr>
                                <td className="left">
                                  <strong>الحسابى الكلي</strong>
                                </td>
                                <td className="right">{total}</td>
                                <td>
                                  <Link to={`/OrderCheckout?${queryString}`} className="btn btn-primary">التالي</Link>

                                </td>
                              </tr>

                            </tbody>
                          </table>


                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <Modal className="modal fade" id="exampleModal1" show={false} centered>
      <div className="modal-content">
      <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel1">بيانات الزبون</h1>
                      <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={notifyInfo}></button>
                  </div>
        <form >
                  <div className="modal-body">
                      <div className="row">
                      <div className="col-xl-12">
                      {errors? <div className=' alert alert-danger'>{errors}</div> : ""}
                      </div>
                      <div className="col-xl-6">

              <label className="form-label mt-3">الاسم<span className="text-danger">*</span></label>
              <input className="form-control"
        type="text"
        name="CustomerName"
         pattern="[a-zA-Z\u0600-\u06FF\s]+"
        title="الرجاء إدخال أحرف فقط، الفراغات، والحروف العربية"
        required
        defaultValue={customers.CustomerName} onChange={handleInputChange1}                      /></div>

               <div className="col-xl-6">
              <label className="form-label mt-3">المدينة<span className="text-danger">*</span></label>
           
              <select   className="default-select form-control" required name="AreaID" defaultValue={customers.AreaID} onChange={handleInputChange1}>
               <option  data-display="Select">اختر من القائمة</option>
               {
                 city.map((row)=>(
                <option key={row.AreaID} value={row.AreaID}>
                          {row.AreaName}
                  </option>
                   ))}    
               </select> 
                 </div>
                 
               <div className="col-xl-6">
                  <label className="form-label  mt-3 ">رقم الهاتف<span className="text-danger">*</span></label>
                  <input type="text" className="form-control" name="PhoneNumber" defaultValue={customers.PhoneNumber} 
                      required
                     onChange={handleInputChange1}/>
              </div> 	
              <div className="col-xl-6">
                  <label className="form-label  mt-3 "> العنوان<span className="text-danger">*</span></label>
                  <input type="text"  className="form-control" name="Notes" 
                   pattern="[a-zA-Z\u0600-\u06FF\s]+"
                   title="الرجاء إدخال أحرف فقط، الفراغات، والحروف العربية"
                   required
                   defaultValue={customers.Notes} onChange={handleInputChange1}                      />
              </div> 	
              <div className="col-xl-6">
                  <label className="form-label  mt-3 ">رقم الهاتف 1 </label>
                  <input type="text"  className="form-control" name="PhoneNumber1" 
                   defaultValue={customers.PhoneNumber1} onChange={handleInputChange1}  required  
                   title=" الرجاء إدخال رقم الهاتف بصيغه صحصحه "
                   />
              </div> 
              <div className="col-xl-6">
                  <label className="form-label  mt-3"  >رقم الهاتف 2</label>
                  <input type="text"  className="form-control" name="PhoneNumber2" 
                    defaultValue={customers.PhoneNumber2} onChange={handleInputChange1} required                     />
              </div>
              <div className="col-xl-12">
                  <label className="form-label  mt-3">ملاحظة</label>
                  <textarea type="text"  className="form-control" name="Description" 
                    defaultValue={customers.Description} onChange={handleInputChange1} required  
                                       />
              </div>
                       </div>                                
                  </div>
                  <div className="modal-footer">
                      <button type="submit" className="btn btn-primary" >
                     
                      حفظ</button>
                      <button type="button" className="btn btn-danger light" onClick={notifyInfo}
            >ألغاء</button>
                  </div> 
                   </form>
       {/* <form onSubmit={createintitem}>
          <div className="modal-body">
            <div className="row">
              <div className="col-xl-12">
                <label className="form-label">الاسم<span className="text-danger">*</span></label>
                <input type="text" className="form-control" name="CustomerName" defaultValuevalue={customers.CustomerName} onChange={handleInputChange1} />

                <div className="row">
                  <div className="col-xl-6">
                    <label className="form-label mt-3">المدينة</label>

                    <select className="default-select form-control" name="AreaID" defaultValuevalue={customers.AreaID} onChange={handleInputChange1}>
                      <option data-display="Select">اختر من القائمة</option>
                      {
                        city.map((row) => (
                          <option key={row.AreaID} value={row.AreaID}>
                            {row.AreaName}
                          </option>
                        ))}
                    </select>
                  </div>

                </div>
                <div className="col-xl-6">
                  <label className="form-label">رقم الهاتف<span className="text-danger">*</span></label>
                  <input type="number" className="form-control" name="PhoneNumber" defaultValuevalue={customers.PhoneNumber} onChange={handleInputChange1} />
                </div>
                <div className="col-xl-6">
                  <label className="form-label"  >رقم الهاتف 2<span className="text-danger">*</span></label>
                  <input type="number" className="form-control" name="PhoneNumber1" defaultValuevalue={customers.PhoneNumber1} onChange={handleInputChange1} />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger light" onClick={notifyInfo}
            >Close</button>
            <button type="submit" className="btn btn-primary" >

              حفظ</button>

          </div>
        </form> */}
      </div>
    </Modal>
    <Modal className="fade bd-example-modal-sm" size="sm" id="exampleModal1" show={popupModal} centered>
      <div className="modal-content">
        <div className="modal-body">
          <div className="col-sm-12 mb-3" hidden={Hideshow}>
            <label className="form-label">ادخل الكمية<span className="text-danger">*</span></label>

            <input type="text"
              className="form-control"
              value={qun}

              //placeholder="ادخل قيم عددية صحيحه  فقط"
              required
              id="inputcity"
              name="city"
              onChange={handleInputChange} ></input>
          </div>
          {errors ? <div className=' alert alert-danger'>{errors}</div> : ""}

          <center>


            <button type="button" className="btn btn-primary light mx-1 " onClick={() => listClickHandler(Name, ItemPrice, ItemID, TItemID, AtbakID,FoodCategoryID)}>اضف</button>

            <button type="button" className="btn btn-danger light" onClick={popupfunction}>اغلاق</button>
          </center>
        </div>
      </div>

    </Modal>
  </>
  );
});

export default Orders;
