
import React, { useState, forwardRef, useImperativeHandle  ,useEffect} from 'react';
import {Modal} from 'react-bootstrap';
 import { Dropdown } from 'react-bootstrap';
 import axios from "axios";
  import { useNavigate ,useParams} from "react-router-dom";
  import swal from 'sweetalert';
const InviteCustomer = forwardRef((props, ref) => {
    const [inviteModal , setInviteModal] = useState(false);
 
     const [catigorydata, setCatigorydata] = useState([])
     const [storagedata, setStoragedata] = useState([])

     const userdata = localStorage.getItem('USER_data');
     const userData = JSON.parse(userdata);
     const userid = userData.id;
            
  //  const { AreaID } = useParams();
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    showInviteModal() {
      setFoodItem(initialFoodItemState); // Reset to initial state
        setInviteModal(true)
    }

  }))      ;
  
  const closeInviteModal =() =>{
     setFoodItem('')
    props.parentFunction()
    setInviteModal(false)
}  

    /////////////////////////////////////
    const initialFoodItemState = {
      Name: '',
      CategoryID: '',
      StorageID: '',
      ItemPrice: '',
      QuantityInStock: '1',
      UserID: userid,
      status: 'متوفر',
      extension: ''
    };
    const [foodItem, setFoodItem] = useState(initialFoodItemState);       
          
          
                         console.log("foodItem",foodItem);

     
      
     const input = document.querySelector('input[name="city"]');
     if(input)
{
     input.addEventListener('invalid', function (event) {
       if (event.target.validity.valueMissing) {
         event.target.setCustomValidity('الرجاء ادخال اسم الاداة');
       } 
     })
     
     input.addEventListener('change', function (event) {
       event.target.setCustomValidity('');
     })}
        
     const getFoodCatigory = () => {
        axios.get('https://suhba.server.com.ly/back/api/getFoodCatigory')
         .then(({ data }) => {
            setCatigorydata(data.FoodCategory)
         })
         .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
         
         }
         const getstorage = () => {
            axios.get('https://suhba.server.com.ly/back/api/getstorage')
             .then(({ data }) => {
                setStoragedata(data.storageType)
             })
             .catch(function (error) {
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
            });
             
             } 

      useEffect(() => {
         getFoodCatigory();
        getstorage();
      
    }, [])
    console.log(foodItem)
    const createEmployee = async(e)=>{
        e.preventDefault();
              await axios.post('https://suhba.server.com.ly/back/api/food_items', foodItem)
        .then(res=>{
             if(res.data.status === 200)
            swal("عذرا",res.data.message,"warning");
        } )  .catch(function (error) {
            if (error.response) {
               console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('Error', error.message);
            }
            console.log(error.config);
          });
             setInviteModal(false)
             setFoodItem('')
             props.parentFunction()
      } 

   
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFoodItem((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
   
      console.log(foodItem)
          
         
      useEffect(() => {
        const handleEnterKey = (e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            const form = e.target.form;
            const fields = Array.from(form.elements).filter(el => (
              (el.tagName === 'INPUT' && !el.disabled) ||
              (el.tagName === 'TEXTAREA' && !el.disabled) ||
              (el.tagName === 'SELECT' && !el.disabled)
            ));      const currentFieldIndex = fields.findIndex(el => el === document.activeElement);
            const nextFieldIndex = currentFieldIndex + 1;
            if (nextFieldIndex < fields.length) {
              fields[nextFieldIndex].focus();
            }
          }
        };
        
        document.addEventListener('keydown', handleEnterKey);
        
        return () => {
          document.removeEventListener('keydown', handleEnterKey);
        };
      }, []);
           
      
    return(
        <>
            <Modal className="modal fade" id="exampleModal1" show={inviteModal}  centered>                
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">أسماء الاصناف</h1>
                        <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={()=> closeInviteModal()}></button>
                    </div>
                            <form  onSubmit={createEmployee}>

                    <div className="modal-body py-2 px-4 ">
                        <div className="row">
                            <div className="col-xl-12">

                                <div className="row">
                                    <div className="col-xl-12">
                                    <div className="row">
                                    <div className="col-xl-2 ">
                                        <label className="form-label mt-3 ">اسم الصنف</label> 
                                         </div>
                                        <div className="col-xl-4">
                                        <div className="input-group ">
                                            <input style={{  height: "2rem" }} 
                                            type="text" 
                                            pattern="[a-zA-Z\u0600-\u06FF\s]+"
                                            title="الرجاء إدخال أحرف فقط، الفراغات، والحروف العربية"
                                         
                                            required  
                                            id="inputcity"
                                              name="Name"
                                            className="form-control mt-3" 
                                            defaultValue={foodItem.Name} onChange={handleInputChange}/>
                                        </div>   </div>
                                        <div className="col-xl-2">
                                        <label className="form-label mt-3 ">السعر </label> 
                                         </div>
                                        <div className="col-xl-4">
                                             <input style={{  height: "2rem" }} 
                                            type="text" 
                                            pattern="\d+(\.\d{1,2})?"
                                            title="الرجاء إدخال ارقام فقط"
 
                                            required  
                                            id="inputcity"
                                              name="ItemPrice"
                                            className="form-control mt-3" 
                                            defaultValue={foodItem.ItemPrice} onChange={handleInputChange}/>
                                        </div>    
                                         <div className="col-xl-2 ">
                                <label className="form-label mt-3  mb-3">يخزن في</label>
                             </div>                                        <div className="col-xl-4">

                                <select className="default-select form-control mt-3  mb-3" name="StorageID" defaultValue={foodItem.StorageTypesID} onChange={handleInputChange}>
                                 <option  data-display="Select">اختر من القائمة</option>
                                 {
                                   storagedata.map((row)=>(
                                  <option key={row.StorageTypesID} value={row.StorageTypesID}>
                                            {row.StorageTypesName}
                                    </option>
                                     ))}
                                 </select>   </div>
                                 <div className="col-xl-2">
                                <label className="form-label mt-3  mb-3">المجموعة</label>
                             </div>                                        <div className="col-xl-4">

                                <select className="default-select form-control mt-3 mb-3" name="CategoryID" defaultValue={foodItem.FoodCategoryID} onChange={handleInputChange}>
                                 <option  data-display="Select">اختر من القائمة</option>
                                 {
                                   catigorydata.map((row)=>(
                                  <option key={row.FoodCategoryID} value={row.FoodCategoryID}>
                                            {row.FoodName}
                                    </option>
                                     ))}
                                 </select>   </div>
                                 <div className="col-xl-2 ">
                                        <label className="form-label mt-3 ">إضافات </label> 
                                         </div>
                                        <div className="col-xl-4">
                                        <div className="input-group ">
                                            <input style={{  height: "2rem" }} 
                                            type="text" 
                                            pattern="[a-zA-Z\u0600-\u06FF\s]+"
                                            title="الرجاء إدخال أحرف فقط، الفراغات، والحروف العربية"
                                         
                                               
                                            id="inputcity"
                                              name="extension"
                                            className="form-control mt-3" 
                                            defaultValue={foodItem.extension} onChange={handleInputChange}/>
                                        </div>   </div>

                                         
                                      
                                        </div>
 
                                    </div>
                                    
                                </div>
                               
                             
                                 </div>  </div>    
                                 </div> 
                                 <div className="modal-footer">
                       <button type="submit" className="btn btn-primary" >
                       
                        حفظ</button>
                        <button type="button" className="btn btn-danger light" onClick={()=>closeInviteModal()}>إلغاء</button>

                    </div> 
                      </form>
                     



                           
                                                  
                  
                   
                </div>               
            </Modal>
        </>
    )
})
export default InviteCustomer;