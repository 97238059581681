
import React from 'react';

import InviteCustomer from '../jsx/constant/ProcessStatusModal';
import { useNavigate, useParams } from "react-router-dom";

const ProcessStatus = () => {
    const navigate = useNavigate();

    const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');
    const userPermissions = JSON.parse(userPermissionsJSON);
    function getActionsForPage(pageName) {
      const pagePermissions = userPermissions.find(permission => permission.resource === pageName);
   console.log('actionsForPage',pagePermissions); 
     if (pagePermissions) {
         return pagePermissions.action;
     } else {
    navigate('/dashboard_1')
        return [];
     }
   }
   const pageName = 'ProcessStatus';
   const actionsForPage = getActionsForPage(pageName);
    return(
<>
<InviteCustomer/> 

</>





    )
//ref={invite}     
//Title="إضافة دورة"
}
export default ProcessStatus;