import React,{ useRef,useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import MOCK_DATA from '../jsx/components/table/FilteringTable/MOCK_DATA_2.json';
import { COLUMNS } from '../jsx/components/table/FilteringTable/ColumnsCompanyPurchases';
 import { Link,useNavigate } from "react-router-dom";
 import axios from "axios";
 import swal from 'sweetalert'; 
import '../jsx/components/table/FilteringTable/filtering.css';
import { Col, Row ,Dropdown} from 'react-bootstrap';
import InviteCustomer1 from '../jsx/constant/ListItem';
import InviteCustomer from '../jsx/constant/ListItem';
import InviteCustomer2 from '../jsx/constant/AsnafExpenses';
import {Modal} from 'react-bootstrap';
import InviteCustomer3 from '../jsx/constant/ModalList2';

import  { useEffect,useState     } from 'react';


export const CompanyPurchases = () => {
	const columns = useMemo( () => COLUMNS, [] )
	//const data = useMemo( () => MOCK_DATA, [] )
  const [data, setData] = useState([])
  const [datapopup, setDatapopup] = useState('')
  const [data2, setData2] = useState([])
  const invite3 = useRef();

  const [value, setValue] = useState()
  const [listitem, setListitem] = useState([])
  const [popupModal, setpopupModal] = useState(false);

  const [ID, setID] = useState(0)
  const [inviteModal , setInviteModal] = useState(false);
  const [inviteModal1 , setInviteModal1] = useState(false);

	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0}
	}, useFilters, useGlobalFilter, usePagination)
  const [qun, setQun] = useState(0)

	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance
    const {globalFilter, pageIndex} = state	
    const navigate = useNavigate();

    const userdata = useRef();
    const invite1 = useRef();
    const invite = useRef();
    const invite2 = useRef();

    useEffect(() => {
      fetchProducts();
  }, [])
  const userdata1 = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata1);
    const userid = userData.id;
  const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');
  const userPermissions = JSON.parse(userPermissionsJSON);
  function getActionsForPage(pageName) {
    const pagePermissions = userPermissions.find(permission => permission.resource === pageName);
 console.log('actionsForPage',pagePermissions); 
   if (pagePermissions) {
       return pagePermissions.action;
   } else {
  navigate('/dashboard_1')
      return [];
   }
 }
 const pageName = 'CompanyPurchases';
 const actionsForPage = getActionsForPage(pageName);
  const fetchProducts = async () => {
      await axios.get('https://suhba.server.com.ly/back/api/asnaf_expensesses').then(({ data }) => {setData(data); console.log('datafroget',data)}) 
  }
    const deleteUser = async (e,id)=> {
        e.preventDefault();
        swal({
          title: "هل متأكد من الحدف",
          text: " في حال حدف الملف لايمكنك استعادة هدا الملف",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then(willDelete => {
          if (willDelete) {
    
            axios.delete(`https://suhba.server.com.ly/back/api/expenses/${id}`)
            
              .then(res => {
                fetchProducts();
                swal({
                  title: "تم",
                  text: "تم الحدف بنجاح",
                  icon: "success",
                  timer: 2000,
                  button: false
                })
                navigate('#')
            
               
            });
        
          } else {
            swal("تم الغاء عملية الحدف");
          }
        
        });
    
    };
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
          </g>
        </svg>
    )

    const EditData = async (e,id)=> {
      console.log(id);

      e.preventDefault();
      await axios.get(`https://suhba.server.com.ly/back/api/expenses/${id}`).then(({ data }) => 
      {setData2(data.data)
        console.log('data',data2);
        console.log('data.darta',data2.data);
       } )
       .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
      setID(id);setInviteModal(true)
     // invite.current.showEmployeModal()
    }
    const show =(value)=>
{
setInviteModal1(value)

}
const getfooditeming = async (e,id,sender)=> {
  data.map((item) => {
    if (id == item.AsnafExpensess) {
      setData2(item)
      console.log(item)
      console.log(item.OrderItems)

      setListitem(item.OrderItems)
      if(sender==='pdf')
      {invite3.current.showInviteModal()}
    else{setInviteModal1(true)}
           

    }

  })

} 
 const [errors, setErrors] = useState('')

const checkfunction =  (e)=> {
  setErrors('');
  const { value } = e.target;
    

 

  // Check if the input is a number or an empty string
  if (/^\d*$/.test(value)) {
   
   setValue(value)

  } else {
    setErrors("هده الخانه لاتقبل حروف")

  }
}
const closepopup =  ()=> {
  setErrors('')

setpopupModal(false)

}
const getpopup = async (e,id)=> {
 
  setDatapopup(id)
setpopupModal(true)

}
const handleEdit = () => {

        const error =  axios.put(`https://suhba.server.com.ly/back/api/asnaf_expensesses/${datapopup}`, {
           ['purchasesAmount']: value,
           ['status']: 'تم الدفع',
           ['UserID']: userid,
            });
               if (!error.response) {
                let temp = data.map((data) => {
                  if (datapopup === data.AsnafExpensess) {
                    return { ...data, status: 'تم الدفع',purchasesAmount: value};
                  }
                  return data;
                });
                setData(temp);
               }else{
                 console.log(error.response.data);
                 console.log(error.response.status);
                 console.log(error.response.headers);
               }  if (error.request) {
                 console.log(error.request);
               } else {
                 console.log('Error', error.message);
               }
               console.log(error.config);
               setpopupModal(false)
            } 
         
    return(
<>
<div className='container-fluid'>
				<Row>
					<Col lg="12">
						<div className="card">
            <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">   

                 <div className="tbl-caption">
                 <h4 className="heading mb-0">مصروفات المشتريات</h4>
                 <div>
                 {actionsForPage.includes('create') &&(
                                  < Link to={"#"} className="btn btn-primary btn-sm m-1" data-bs-toggle="offcanvas"
                                 onClick={() => invite2.current.showInviteModal()}
                               > + إضافة مشتريات الطلبيات </Link> )} 
                                
                            </div>
                        </div>  
                        </div>   </div> 
                   
                        
                        		 
								<div className="table-responsive">
									<table {...getTableProps()} className="table dataTable display table-hover">
										<thead>
										{headerGroups.map(headerGroup => (
												<tr {...headerGroup.getHeaderGroupProps()}>
												      {headerGroup.headers.map((column) => (
                        // Check if the column accessor is 'age' and render accordingly
                        column.id !== 'AsnafExpensess' ? (
                          <th {...column.getHeaderProps()}>
                            {column.render('Header')}
                            {column.canFilter ? column.render('Filter') : null}
                          </th>
                        ) : null
                      ))}
                                             <th style={{ paddingBottom: "4.5rem" }}>الايصال </th>

												</tr>
										))}
										</thead> 
                                        <tbody {...getTableBodyProps()} className="" >
                                        
                                        {page.map((row) => {
                                            prepareRow(row)
                                            return(
                                                <tr {...row.getRowProps()}>

                                                    {row.cells.map((cell) => (
          // Check if the column accessor is 'age' and render accordingly
          cell.column.id !== 'AsnafExpensess' ? (
            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
          ) :null
        ))}
<td> <Link   dir="rtl" className=""
                                    to={"#"} 
                                    onClick={(e) =>getfooditeming(e,row.original.AsnafExpensess,'pdf')}

                                    
                                   > <i class="fa-solid fa-file-lines fa-2x"></i>
                                  </Link>

 </td>

                                                     <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        className="light sharp i-false" 
                      >
                           {svg1}
                       </Dropdown.Toggle>
                      <Dropdown.Menu dir="rtl" className="dropdown-menu-right">
                      {actionsForPage.includes('read') && (                        <Dropdown.Item      
                             onClick={(e) =>getfooditeming(e,row.original.AsnafExpensess,'show')}
>
                        <Link to className=""
                                >عرض
                            </Link>
                            </Dropdown.Item> )}   
                            {actionsForPage.includes('edit') && (
                        <Dropdown.Item  onClick={(e) =>getpopup(e,row.original.AsnafExpensess)} >
                        <Link to className=""
                              
                            >صرف المبلغ
                            </Link>
                      </Dropdown.Item>)}
                     
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
									</table>



									<div className="d-flex justify-content-between mx-2">
										<span>
											الصفحه{' '}
											<strong>
												{pageIndex + 1} من {pageOptions.length}
											</strong>{''}
										</span>
										<span className="table-index ">
											ادهب الى : {' '}
											<input type="number" 
												className="ml-2"
												defaultValue={pageIndex + 1} 
												onChange = {e => { 
													const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
													gotoPage(pageNumber)
												} } 
											/>
										</span>
									</div>
                                    <div className="text-center mb-3">  
                                        <div className="filter-pagination  mt-3">
                                            <button className="previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                                {/* {'<'} */}
                                                <i className='fa-solid fa-angle-right' />
                                            </button>
                                            
                                            <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                                السابق
                                            </button>
                                            <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                                التالي
                                            </button>
                                            <button className="next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                                {/* {'>'} */}
                                                <i className='fa-solid fa-angle-left' />
                                            </button>
                                        </div>
                                    </div>
								</div>
						 
						</div>
					</Col>
				</Row>
			</div>
      <Modal className="fade bd-example-modal-sm" size="sm" id="exampleModal1" show={popupModal} centered>
        <div className="modal-content">
          <div className="modal-body">
            <div className="col-sm-12">
              <label className="form-label">ادخل الكمية<span className="text-danger">*</span></label>

              <input type="text"
                className="form-control"
                defaultValue=''

                //placeholder="ادخل قيم عددية صحيحه  فقط"
                required
                id="inputcity"
                name="city"
                onChange={checkfunction} ></input>
           {errors ? <div className=' alert alert-danger'>{errors}</div> : ""}

            </div>
           {console.log(value)}

            <div className="card-footer" >

              <button type="button" className="btn btn-primary light mx-1" onClick={handleEdit}>اضف</button>

              <button type="button" className="btn btn-danger light"  onClick={closepopup}
>اغلاق</button>
            </div>
          </div>
        </div>

      </Modal>
      <InviteCustomer2
                ref={invite2}     
                parentFunction={fetchProducts}  
            /> 
            <InviteCustomer3
                ref={invite3}  data1={data2}
                data={listitem}
                />
             <InviteCustomer
                
                data={data2}
                data2={listitem}
                parentFunction={fetchProducts}  
                inviteModal={inviteModal1}
                show={show}
            /> 
</>


    );
}
export default CompanyPurchases;