import React from 'react';
import  { useState, useEffect  } from 'react';
import DatePicker from "react-datepicker";
import {Link, useNavigate} from 'react-router-dom';
import  { CurrentTimePicker } from '../jsx/components/Forms/Pickers/TimePicker';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import axios from "axios";
 import "react-toastify/dist/ReactToastify.css";
import {useStateContext} from "../context/ContextProvider";
import axiosClient from "../axios-client.js";
import InviteCustomer1 from './Company.js';

  
import InviteCustomer from './Company copy.js';
const City = () => {    
  
     useEffect(()=>{
        fetchProduct();
    },[])
    const userdata = localStorage.getItem('USER_data');
    const userData = JSON.parse(userdata);
      const userid = userData.id;
    const [products, setProducts] = useState([])

    const fetchProduct = async() =>{
        await axios.get(`https://suhba.server.com.ly/back/api/maincompanies`)
            .then(({ data }) => {
                if(data){
                  navigate('/EditCompany')

                }else{
                  navigate('/AddCompany')

                }
              //  const { CompanyName, Address } = data.maincompany
             //   setCompanyName(data.com)
               // setAddress(data.Address)
              // console.log(Address)

            }).catch(({ response: {data} }) => {
                console.log(data.message)
            })
    }
  
    
    const navigate = useNavigate();
    
    const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');
    const userPermissions = JSON.parse(userPermissionsJSON);
    function getActionsForPage(pageName) {
      const pagePermissions = userPermissions.find(permission => permission.resource === pageName);
    console.log('actionsForPage',pagePermissions); 
     if (pagePermissions) {
         return pagePermissions.action;
     } else {
    navigate('/dashboard_1')
        return [];
     }
    }
    const pageName = 'city';
    const actionsForPage = getActionsForPage(pageName);
        return(
    <>
     
    </>
    
    
    
    
    
        )
    //ref={invite}     
    //Title="إضافة دورة"
    }
    export default City;