import React, { useContext, useState } from "react";
import { useDispatch , useSelector } from 'react-redux';
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { SVGICON } from "../../constant/theme";
import { navtoggle } from "../../../store/actions/AuthActions";
 

const NavHader = () => {
  
  const {  openMenuToggle } = useContext(
    
    ThemeContext
  );
  const [val , setVal]=useState(false)
  const dispatch = useDispatch();
  const sideMenu = useSelector(state => state.sideMenu);
  const handleToogle = () => {
    dispatch(navtoggle());
    setVal(!val)
  };
  return (
    <div className="nav-header">
      <Link to="/Dashboard_1" className="brand-logo pe-0"> 
      <div className="" >
        								<div className="profile-photo"style={{paddingRight:"5px"}}>  <img className="img-fluid rounded-circle " width="40" height="40" src='https://suhba.server.com.ly/back/storage/ImageName/food.png'/>
            <label style={{fontSize:"15px"}}className="m-1" hidden={val}>مطبخ صحبة طيبة</label>
            </div>
            </div>

      </Link>

      <div
        className="nav-control"
        onClick={() => {          
          openMenuToggle();
          setVal(!val)
          handleToogle()
        }}
      >
        <div className={`hamburger ${sideMenu ? "is-active" : ""}`}
          
        >
          <span className="line">
						{SVGICON.NavHeaderIcon}
					</span>        
        </div>
      </div>
    </div>
  );
};

export default NavHader;
