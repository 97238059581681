import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";

function YourComponent() {
  const [startMonth, setStartMonth] = useState('');
  const [endMonth, setEndMonth] = useState('');
  const [outcomeData, setOutcomeData] = useState([]);
  const [totalAmountSum, setTotalAmountSum] = useState(0);
  const [prepayAmountSum, setPrepayAmountSum] = useState(0);
  const [restValue, setRestValue] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [year, setYear] = useState('');
  const arabicMonthNames = [
    "يناير", "فبراير", "مارس", "إبريل", "مايو", "يونيو",
    "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"
  ];

  useEffect(() => {
    // Fetch data from the API
    axios.get("https://suhba.server.com.ly/back/api/getTotalAmountByMonth")
      .then(response => {
        const data = response.data;
        setOutcomeData(data);
        setFilteredData(data);

        // Calculate total amount and prepay amount sums
        const totalAmountSum = data.reduce((acc, item) => acc + parseFloat(item.total_amount), 0);
        const prepayAmountSum = data.reduce((acc, item) => acc + parseFloat(item.prepay_amount), 0);
        setTotalAmountSum(totalAmountSum);
        setPrepayAmountSum(prepayAmountSum);
        setRestValue(totalAmountSum - prepayAmountSum);
      })
      .catch(error => {
        console.error("Error fetching outcome data:", error);
      });
  }, []);
  const handleSearch = () => {
    // Filter outcomeData based on startMonth, endMonth, and year
    const filtered = outcomeData.filter(item => {
      const month = item.month;
       const itemYear = item.year;
      const yearFilter = year ? itemYear === parseInt(year) : true; // Check if item's year matches the selected year
  console.log('itemYear',item.year)
  console.log('yearFilter',year)
  console.log('yearFilter',yearFilter)

      const start = parseInt(startMonth, 10) || 1;
      const end = parseInt(endMonth, 10) || 12;
      const monthFilter = endMonth === '' ? month === start : month >= start && month <= end; // Filter by month
  
      return monthFilter && yearFilter; // Include both month and year filtering conditions
    });
    
    setFilteredData(filtered); // Update filteredData state
    
    // Calculate total amount and prepay amount sums from filtered data
    const totalAmountSum = filtered.reduce((acc, item) => acc + parseFloat(item.total_amount), 0);
    const prepayAmountSum = filtered.reduce((acc, item) => acc + parseFloat(item.prepay_amount), 0);
    setTotalAmountSum(totalAmountSum);
    setPrepayAmountSum(prepayAmountSum);
    setRestValue(totalAmountSum - prepayAmountSum);
  };
  
;/*
  const handleSearch = () => {
    // Filter outcomeData based on startMonth and endMonth
    const filtered = outcomeData.filter(item => {
      const month = item.month;
      const itemDate = new Date(item.year);

      const itemYear = itemDate.getFullYear();
       const yearFilter = year ? itemYear === parseInt(year) : true;

      const start = parseInt(startMonth, 10) || 1;
      const end = parseInt(endMonth, 10) || 12;
      if (endMonth === '') {
        // If endMonth is not specified, only consider startMonth
        return month === start && yearFilter;
            } else {
        // Otherwise, consider the range between startMonth and endMonth
        return month >= start && month <= end && yearFilter;      }
    });
    setFilteredData(filtered); // Update filteredData state
    const totalAmountSum = filtered.reduce((acc, item) => acc + parseFloat(item.total_amount), 0);
    const prepayAmountSum = filtered.reduce((acc, item) => acc + parseFloat(item.prepay_amount), 0);
    setTotalAmountSum(totalAmountSum);
    setPrepayAmountSum(prepayAmountSum);
    setRestValue(totalAmountSum - prepayAmountSum);
  }
  const handleSearch = () => {
    // Filter outcomeData based on startMonth
    console.log(" befor",filteredData)

    setFilteredData(outcomeData);
    console.log(" after",startMonth)

    const filtered = outcomeData.filter(item => 
      item.month == startMonth
    );
  
    // Update filteredData state
    setFilteredData(filtered);
  console.log("filtered",filtered)
    // Calculate total amount and prepay amount sums from filtered data
    const totalAmountSum = filtered.reduce((acc, item) => acc + parseFloat(item.total_amount), 0);
    const prepayAmountSum = filtered.reduce((acc, item) => acc + parseFloat(item.prepay_amount), 0);
    setTotalAmountSum(totalAmountSum);
    setPrepayAmountSum(prepayAmountSum);
    setRestValue(totalAmountSum - prepayAmountSum);
  };
  
  */

  const handleClearSearch = () => {
    setStartMonth(''); // Reset start month
    setEndMonth(''); // Reset end month
    setFilteredData(outcomeData); // Reset filtered data to original data
    const totalAmountSum = outcomeData.reduce((acc, item) => acc + parseFloat(item.total_amount), 0);
        const prepayAmountSum = outcomeData.reduce((acc, item) => acc + parseFloat(item.prepay_amount), 0);
        setTotalAmountSum(totalAmountSum);
        setPrepayAmountSum(prepayAmountSum);
        setRestValue(totalAmountSum - prepayAmountSum);
  };

  return (
    <div>
      <div className="row mb-3">
        <div className="col-md-3" style={{display :'flex'}}>
          <label htmlFor="startMonth" className="form-label ms-2 mt-1">من</label>
          <select className="default-select form-control"
            id="startMonth"
            value={startMonth}
            onChange={e => setStartMonth(e.target.value)}
          >
            <option value="">اختر</option> {/* Add an option to select all */}
            {arabicMonthNames.map((month, index) => (
              <option key={index} value={index + 1}>{month}</option>
            ))}
          </select>
        </div>
        <div className="col-md-3" style={{display :'flex'}}>
          <label htmlFor="endMonth" className="form-label ms-2 mt-1">ألى</label>
          <select className="default-select form-control"
            id="endMonth"
            value={endMonth}
            onChange={e => setEndMonth(e.target.value)}
          >
            <option value="">اختر</option> {/* Add an option to select all */}
            {arabicMonthNames.map((month, index) => (
              <option key={index} value={index + 1}>{month}</option>
            ))}
          </select>
        </div>
        <div className="col-md-3" style={{ display: 'flex' }}>
                  <label htmlFor="startMonth" className="form-label ms-2 mt-1">السنة</label>
                  <input className="form-control"
                    type="text"
                    value={year}
                    placeholder="اختر السنة"
                    onChange={e => setYear(e.target.value)}
                  />
                </div>
        <div className="col-md-3">
          <button className="btn btn-primary btn-sm me-1" onClick={handleSearch}>ابحت</button>
        
           <button className="btn btn-secondary btn-sm me-1" onClick={handleClearSearch}> الغاء</button>
        </div>
      </div>
      <div className="row mb-3" style={{display :'flex'}}>
      <div className="col-md-4" style={{display :'flex'}}>
          <label htmlFor="startMonth" style={{fontsize: '15px', fontWeight: 'bold'}}> اجمالي الدخل :</label>
       <div style={{color: 'blue'}}> <strong>{totalAmountSum}</strong></div>
        </div> 
        <div className="col-md-4" style={{display :'flex'}}>
          <label style={{fontsize: '15px', fontWeight: 'bold'}} htmlFor="startMonth"> اجمالي العرابين :</label>
          <div style={{color: 'red'}}><strong>{prepayAmountSum}</strong></div>
        </div>
        <div className="col-md-4" style={{display :'flex'}}>
          <label style={{fontsize: '15px', fontWeight: 'bold'}} htmlFor="startMonth"> صافي الدخل :</label>
          <div style={{color: 'green'}}><strong>{restValue}</strong>        </div>
      </div>
      </div>

      {/* Your table code */}
      <Table responsive striped bordered className="verticle-middle">
        <thead>
          <tr>
            <th>الشهر</th>
            <th>السنة</th>

            <th> اجمالي الدخل</th>
            <th>أجمالي العرابين</th>
          </tr>
        </thead>
        <tbody>
          {/* Render filteredData instead of outcomeData */}
          {filteredData.map((item, index) => (
  <tr key={index}>
    <td>{arabicMonthNames[item.month - 1]}</td>
    <td>{item.year}</td>

    <td>{item.total_amount}</td>
    <td>{item.prepay_amount}</td>
  </tr>
))}
          {/* Additional rows */}
          
        </tbody>
      </Table>
    </div>
  );
}

export default YourComponent;
