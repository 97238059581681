import {ContextProvider} from '../context/ContextProvider.js'
import axiosClient from "../axios-client.js";
import {useEffect} from "react";
import  { useContext  } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
/// React router dom
import {  Routes, Route, Outlet ,Navigate } from "react-router-dom";
import {useStateContext} from "../context/ContextProvider.js";
/// Css
import "../jsx/index.css";
import "../jsx/chart.css";
import "../jsx/step.css";

/// Layout
 import Nav from "../jsx/layouts/nav/index.js";
import Footer from "../jsx/layouts/Footer.js";
import ScrollToTop from "../jsx/layouts/ScrollToTop.js";




import { ThemeContext } from "../context/ThemeContext.js";
import { navtoggle } from "../store/actions/AuthActions.js";


  
 
  
  
  



  
    



 export default  function MainLayout(){
  const {user, token, setUser, setToken, permissions} = useStateContext();
 // useEffect(() => {
   // axiosClient.get('/user')
     // .then(({data}) => {
      //   setUser(data)
      //})
  //}, [])  
 
  const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');

  // Parse the JSON string back to an array
  const userPermissions = JSON.parse(userPermissionsJSON);
  
  // Print the user permissions
  console.log('USER_PERMISSIONS1:', userPermissions);
  
     // setPermissions(data.permissions)

    const { menuToggle , sidebariconHover} = useContext(ThemeContext);
   
     const sideMenu = useSelector(state => state.sideMenu);
    // const handleToogle = () => {
    //   dispatch(navtoggle());
    // };

   if (!token) {
    
  return < Navigate to="/login"/>
}
    return (
      <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle": ""} ${ sideMenu ? "menu-toggle" : ""}`}>  
          <Nav />
          <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>          
            <Outlet />   
          </div>
        <Footer />
      </div>
    )
  };




