import React, { useMemo, useEffect, useState } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { GlobalFilter } from '../jsx/components/table/FilteringTable/GlobalFilter';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import { Col, Row,Table } from 'react-bootstrap';
import pMinDelay from "p-min-delay";
import { IMAGES, SVGICON } from '../jsx/constant/theme';
import loadable from "@loadable/component";

import AddVisitorLine from '../jsx/components/Dashboard/elements/AddVisitorLine';

import '../jsx/components/table/FilteringTable/filtering.css';
import { COLUMNS } from '../jsx/components/table/FilteringTable/ColumnAsnafReport';
import MOCK_DATA from '../jsx/components/table/FilteringTable/MOCK_DATA_2.json';
const TotalDeposit = loadable(() =>
	pMinDelay(import("../jsx/components/Dashboard/elements/TotalDeposit"), 1000));
export const AsnafReport = () => {
  const columns = useMemo(() => COLUMNS, []);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data3, setData3] = useState([]);
  const [startMonth, setStartMonth] = useState(0);

  const chartCardBlog = [
	{
	  title: 'الادوات الاكنر إيجارا',
	  item: data.length > 0 ? data[0].TName : 'No Data', // Check if data array is not empty
	  svg: <i className="fa-solid fa-circle-arrow-up " style={{ color: '#452B90' }}></i>,
	  number: data.length > 0 ? data[0].totalQuantity : 0, // Provide default value if data array is empty
	  chartTheme: 'var(--primary)',
	  cardColor: 'primary'
	},
	//{
//	  title: 'اجمالي الدخل',
//	  svg: <i className="fa-solid fa-hand-holding-dollar "style={{ color: '#D99512' }}></i>,
//	  number: startMonth,
	//  chartTheme: '#D99512',
	//  cardColor: 'secondary'
	//},
	{
	  title: 'الادوات الاقل إيجارا',
	  item: data.length > 0 ? data[data.length - 1].TName : 'No Data', // Check if data array is not empty
	  svg: <i className="fa-solid fa-circle-arrow-down " style={{ color: '#58bad7' }}></i>,
	  number: data.length > 0 ? data[data.length - 1].totalQuantity : 0, // Provide default value if data array is empty
	  chartTheme: '#58bad7',
	  cardColor: 'info'
	}
  ];
  const headersTitle = [
    // HallID 	HallName 	HallAddress 	HallContactNumber 	TItemPrice 	UserID 	created_at 	updated_at 	PreName 	
      {label:'اسم الاداة', key:'TName'}, 
      {label:'اجمالي عدد القطع الني تم إيجارها', key:'totalQuantity'}, 
      {label:'سعر القطعة', key:'TItemPrice'}, 
      {label:' اجمالي الدخل من هدا الاداة', key:'totalprice'}, 
       
  ]
    const csvlink = {
      headers : headersTitle,
      data : data,
      filename: "csvfile.csv"
    }
  const printInvoice = () => {
    const printContents = document.getElementById('invoiceDiv').innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }; 
//<i class="fa-solid fa-circle-arrow-up" style="color: red;"></i>
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [itemName, setItemName] = useState('');
  const arabicMonthNames = [
	"يناير", "فبراير", "مارس", "إبريل", "مايو", "يونيو",
	"يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"
];
  const fetchProducts = async () => {
    axios.get('https://suhba.server.com.ly/back/api/order_items')
      .then(response => {
        setData1(response.data.tools);
        setData3(response.data.ToolsItem);
	
        const itemMap = {};
        response.data.tools.forEach(item => {
          if (itemMap[item.TItemID]) {
            itemMap[item.TItemID].totalQuantity += item.Quantity;
            itemMap[item.TItemID].totalprice += item.TItemPrice ? item.TItemPrice * item.Quantity : 0;
          } else {
            itemMap[item.TItemID] = {
              TItemID: item.TItemID,
              TName: item.TName,
              totalQuantity: item.Quantity,
              TItemPrice: item.TItemPrice,
              totalprice: item.TItemPrice ? item.TItemPrice * item.Quantity : 0
            };
          }
        });
        const totalQuantityByItem = Object.values(itemMap);
        totalQuantityByItem.sort((a, b) => b.totalQuantity - a.totalQuantity);
        setData(totalQuantityByItem);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
	 
  }

  useEffect(() => {
    fetchProducts();
  }, []);

 /*const handleSearch = () => {
    const filteredData = data1.filter(item => {
      const itemDate = new Date(item.created_at);
      const itemMonth = itemDate.getMonth() + 1;
      const itemYear = itemDate.getFullYear();
      const itemNameMatch = item.Name.includes(itemName); // Check if the item name includes the entered item name
      return itemYear === parseInt(year) && itemMonth === parseInt(month) && itemNameMatch;
    });*/
	const handleSearch = () => {
		const filteredData = data1.filter(item => {
		  const itemDate = new Date(item.created_at);
		  const itemMonth = itemDate.getMonth() + 1;
		  const itemYear = itemDate.getFullYear();
		  
		  // Check if the item name includes the entered item name
		 // const itemNameMatch = item.Name.toLowerCase().includes(itemName.toLowerCase());
		 const itemNameMatch = item.TItemID.toString().includes(itemName.toString());
		  // Check if month and year filters are provided
		  const monthFilter = month ? itemMonth === parseInt(month) : true;
		  const yearFilter = year ? itemYear === parseInt(year) : true;
	  
		  // Return true if all conditions match
		  return monthFilter && yearFilter && itemNameMatch;
		});
    const itemMap = {};

    filteredData.forEach(item => {
      if (itemMap[item.TItemID]) {
        itemMap[item.TItemID].totalQuantity += item.Quantity;
        itemMap[item.TItemID].totalprice += item.TItemPrice ? item.TItemPrice * item.Quantity : 0;
      } else {
        itemMap[item.TItemID] = {
          TItemID: item.TItemID,
          TName: item.TName,
          totalQuantity: item.Quantity,
          TItemPrice: item.TItemPrice,
          totalprice: item.TItemPrice ? item.TItemPrice * item.Quantity : 0
        };
      }
    });

    const totalQuantityByItem = Object.values(itemMap);
    totalQuantityByItem.sort((a, b) => b.totalQuantity - a.totalQuantity);
    setData(totalQuantityByItem);
    console.log("totalQuantityByItem",totalQuantityByItem)

  };
console.log("data",data)
  const handleClearSearch = () => {
    setMonth('');
    setYear('');
    setItemName('');
    fetchProducts();
  };

  return (
    <>
	  <div className="card-body py-0 custome-tooltip mb-0">
                            <div className="row mx-1 ">
                                {chartCardBlog.map((item, ind)=>(
                                    <div className="col-xl-6 col-sm-6 " key={ind}>
                                        <div className="card">
                                            <div className="card-header p-2 border-0">
                                                <div className="d-flex">
                                                    <div className={`icon-box rounded-circle bg-${item.cardColor}-light`}style={{color:'#D99512'}}>
                                                     {item.svg}
                                                    </div>
                                                    <div className="ms-2 add-visit">
                                                        <h6 className="mb-0 me-2">{item.title} : {item.item}</h6>
                                                        <span className='me-2'>{item.number}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body p-0 custome-tooltip">                                            
                                                <AddVisitorLine colorTheme ={item.chartTheme} />
                                            </div>
                                        </div>
                                    </div>
                                ))}                                
                            </div>                            
                        </div>
      
	  <div className="container-fluid pt-0" >
        <Row>
          <Col lg="12">
            <div className="card">
              <div className="card-header">
                <h4 className="heading mb-0">المعدات</h4>
                <div>
                  <Link                     onClick={printInvoice}
 to="#" className="btn btn-secondary btn-sm ms-1" data-bs-toggle="offcanvas">
                    <i className="fa-solid fa-print ms-1" /> طباعه
                  </Link>
                  <CSVLink {...csvlink} className="btn btn-secondary btn-sm ms-1" ><i className="fa-solid fa-file-excel ms-2" />تصدير كاملف اكسل </CSVLink>

                </div>
              </div>
              <div className="row" style={{ display: 'flex', marginTop: '20px', marginRight: '20px' }}>
                <div className="col-md-3" style={{ display: 'flex' }}>
                  <label htmlFor="startMonth" className="form-label ms-2 mt-1">الشهر</label>
                  <select className="default-select form-control"
                    id="startMonth"
                    value={month}
                    onChange={e => setMonth(e.target.value)}
                  >
                    <option value="">اختر الشهر</option>
                    {[...Array(12).keys()].map(month => (
                      <option key={month + 1} value={month + 1}>{arabicMonthNames[month]}</option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3" style={{ display: 'flex' }}>
                  <label htmlFor="startMonth" className="form-label ms-2 mt-1">السنة</label>
                  <input className="form-control"
                    type="text"
                    value={year}
                    placeholder="اختر السنة"
                    onChange={e => setYear(e.target.value)}
                  />
                </div>
                <div className="col-md-4" style={{ display: 'flex' }}>
                  <label htmlFor="itemName" className="form-label ms-2 mt-1" style={{whitespace: "nowrap"}}>الاداة</label>
				  <select className="default-select form-control" name="FoodTItemID" value={itemName}      
				                 onChange={e => setItemName(e.target.value)}
>
                                 <option  data-display="Select">اختر من القائمة</option>
                                 {
                                   data3.map((row)=>(
                                  <option key={row.TItemID} value={row.TItemID}>
                                            {row.TName}
                                    </option>
                                     ))}    
                                 </select> 
				 
                </div>
                <div className="col-md-2">
                  <button className="btn btn-primary btn-sm me-1" onClick={handleSearch}>ابحت</button>
                  <button className="btn btn-secondary btn-sm me-1" onClick={handleClearSearch}> الغاء</button>
                </div>
              </div>
              <div className="table-responsive m-3" id="invoiceDiv">
 				 <Table responsive className="primary-table-bordered table-hover">
                  <thead className="thead-primary">
                     <tr className='pe-2'>
                      <th>اسم الاداة</th>
                      <th>اجمالي عدد القطع الني تم إيجارها</th>
                      <th>سعر القطعة</th>
                      <th>اجمالي الدخل من هدا الاداة</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.TName}</td>
                        <td>{item.totalQuantity}</td>
                        <td>{item.TItemPrice}</td>
                        <td>{item.totalprice}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AsnafReport;
