import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import swal from 'sweetalert';
const InviteCustomer = forwardRef((props, ref) => {
  const [inviteModal, setInviteModal] = useState(true);
  const [IngCatName, setIngCatName] = useState('')
  const [IngName, setIngName] = useState('')
  const [IngCategoryID, setIngCategoryID] = useState('')
  const userdata1 = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata1);
    const userid = userData.id;
  const [data, setData] = useState([])

  const [dataingredients, setDataingredients] = useState([])

  const navigate = useNavigate();

  const showInviteModal = () => {

    setInviteModal(false)
    navigate('/Dashboard_1')

  }
  ///////////////////////////////////////
  const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');
  const userPermissions = JSON.parse(userPermissionsJSON);
  function getActionsForPage(pageName) {
    const pagePermissions = userPermissions.find(permission => permission.resource === pageName);
 console.log('actionsForPage',pagePermissions); 
   if (pagePermissions) {
       return pagePermissions.action;
   } else {
  navigate('/dashboard_1')
      return [];
   }
 }
 const pageName = 'Ingredients';
 const actionsForPage = getActionsForPage(pageName);
  //////////////////////////////////
  const [tableData, setTableData] = useState([]);
  const handleEdit = async (id, field, value) => {

    const updatedData = tableData.map((row) =>
      row.id === id ? { ...row, [field]: value } : row
    );
    setTableData(updatedData);
    const formData = new FormData();
    formData.append('_method', 'PATCH')
    formData.append('IngredientID', id);
    formData.append('IngName', value);
    formData.append('UserID', userid);


    // await axios.put(`https://suhba.server.com.ly/back/api/areas/${id}`, FormData) .catch(function (error)
    const error = await axios.put(`https://suhba.server.com.ly/back/api/ingredients/${id}`, {
      [field]: value,
    });
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log('Error', error.message);
    }
    console.log(error.config);

  }
  const handleEdit1 = async (id, field, value) => {

    const updatedData = tableData.map((row) =>
      row.id === id ? { ...row, [field]: value } : row
    );
    setTableData(updatedData);
    const formData = new FormData();
    formData.append('_method', 'PATCH')
    formData.append('IngredientID', id);
    formData.append('IngCategoryID', value);
    formData.append('UserID', userid);


    // await axios.put(`https://suhba.server.com.ly/back/api/areas/${id}`, FormData) .catch(function (error)
    const error = await axios.put(`https://suhba.server.com.ly/back/api/ingredients/${id}`, {
      [field]: value,
    });
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log('Error', error.message);
    }
    console.log(error.config);

  }
  // console.log(formData);


  ///////////////////////////////////////////////
  const getcities = () => {
    axios.get('https://suhba.server.com.ly/back/api/ingredient_categories')
      .then(({ data }) => {
        setData(data)
      })
  }
  const getingredients = () => {
    axios.get('https://suhba.server.com.ly/back/api/ingredients')
      .then(({ data }) => {
        setDataingredients(data)
      })
  }

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPage = 8;
  //const lastIndex = currentPage * recordsPage;
  // const firstIndex = lastIndex - recordsPage;   
  // const records = data.slice(firstIndex, lastIndex);
  //  const npage = Math.ceil(data.length / recordsPage)
  //    const number = [...Array(npage + 1).keys()].slice(1)
  const input = document.querySelector('input[name="city"]');
  if (input) {
    input.addEventListener('invalid', function (event) {
      if (event.target.validity.valueMissing) {
        event.target.setCustomValidity('الرجاء ادخال اسم نوع المكايل و مثال : ربطة , كيلوا ');
      }
    })

    input.addEventListener('change', function (event) {
      event.target.setCustomValidity('');
    })
  }



  useEffect(() => {
    getcities();
    getingredients();
    const myElement = document.getElementById('myElementId');
    if (myElement) {

      if (selectedItem == 1) {
        myElement.className = 'Complete';
      } else {
        myElement.className = 'Pending';
      }
    }

  }, [])
  const createEmployee = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    //  IngredientID 	IngName 	IngCategoryID 	QuantityInStock 	UserID 	
    formData.append('IngName', IngName);
    formData.append('IngCategoryID', IngCategoryID);
    formData.append('UserID', userid);
    formData.append('QuantityInStock', '1');
    await axios.post('https://suhba.server.com.ly/back/api/ingredients', formData)
      .then(res => {
        if (res.data.status === 200)
          swal("عذرا", res.data.message, "warning");
      })

    //  const myElement = document.getElementById('inputcity');

    //   myElement.value = '';
    setIngCatName('')
    getcities();
    getingredients();
  }
  const [message, setMessage] = useState(null)

  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = data.slice(firstIndex, lastIndex);
  const npage = Math.ceil(data.length / recordsPage)
  const number = [...Array(npage + 1).keys()].slice(1)

  const [statusPriority, setStatusPriority] = useState([]);

  const handleAction = async (id, field, value) => {
   
    const formData = new FormData();
    formData.append('_method', 'PATCH')
    formData.append('IngredientID', id);
    formData.append('QuantityInStock', value);
    formData.append('UserID', userid);
    console.log('formdata', formData)
    const error = await axios.put(`https://suhba.server.com.ly/back/api/ingredients/${id}`, {
      [field]: value,
    });
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log('Error', error.message);
    }
    console.log(error.config);
  }
  const [selectedItem, setSelectedItem] = useState();

  const handleSelect = (eventKey, rowIndex) => {
    const updatedData = [...dataingredients];
    updatedData[rowIndex].QuantityInStock = eventKey;
    setSelectedItem(eventKey)
    console.log('eventKey',rowIndex)

    console.log('eventKey',updatedData)

    setDataingredients(updatedData); console.log('row', rowIndex);
  };
  console.log('dataingredients', dataingredients)

  return (
    <>
      <Modal className="modal fade" id="exampleModal1" show={inviteModal} centered>
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">وحدات المكونات</h1>
            <button type="button" className="btn-close " style={{ marginLeft: 0 }} padding="" onClick={() => showInviteModal()}></button>
          </div>
          <div className="modal-body py-2 px-3 ">
            <div className="row">
              <div className="col-xl-12">
                <form onSubmit={createEmployee}>

                  <div className="row">
                    <div className="col-xl-12">

                      <div className="row">
                        <div className="col-xl-1">
                          <label className="form-label mt-3 ">الاسم</label>
                        </div>
                        <div className="col-xl-3" >
                          <input style={{ height: "2rem" }}
                            type="text"
                            required
                            id="inputcity"
                            name="city"
                            className="form-control mt-3"
                            defaultValue={IngCatName}
                            onChange={(e) => { setIngName(e.target.value) }} />
                        </div>
                        <div className="col-xl-1 ">
                          <label className="form-label mt-3 ">الوحدة</label>
                        </div>
                        <div className="col-xl-4 ">
                          <select className="default-select form-control mt-3 " name="IngCategoryID" defaultValue={IngCategoryID}
                            onChange={(e) => { setIngCategoryID(e.target.value) }}>
                            <option data-display="Select">اختر من القائمة</option>
                            {
                              data.map((row) => (
                                <option key={row.IngCategoryID} value={row.IngCategoryID}>
                                  {row.IngCatName}
                                </option>
                              ))}
                          </select>    </div>
                          <div className="col-xl-3  mt-3  ">
                    <button type="submit" className="btn btn-primary btn-sm ">+</button>
                  </div>
                      </div>
                    </div>

                  </div>
                </form>
                <div className="table-responsive active-projects task-table my-3 mx-2" style={{ height: "400px" }}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">المكون</th>
                        <th scope="col"> الوحدة</th>
                        <th scope="col">الحالة</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        dataingredients.length > 0 && (
                          dataingredients.map((row, ind) => (
                            <tr key={ind}>
                              <td
                                contentEditable
                                onBlur={(e) => handleEdit(row.IngredientID, 'IngName', e.target.textContent)}
                              >{row.IngName}</td>
                                <td             >
                               
                                <select className="default-select form-control mt-3 " name="IngCategoryID" defaultValue={IngCategoryID}
                            onChange={(e) => { setIngCategoryID(e.target.value) }}
                            onBlur={(e) => handleEdit1(row.IngredientID, 'IngCategoryID', e.target.value)}
>
                            <option data-display="Select" >{row.IngCatName}</option>
                            {
                              data.map((row) => (
                                <option key={row.IngCategoryID} value={row.IngCategoryID}>
                                  {row.IngCatName}
                                </option>
                              ))}
                          </select>
                              </td>
                              <td>
    <Dropdown className="task-dropdown-2" onSelect={(eventKey, event) => handleSelect(eventKey, ind)}>
        <div>
            {row.QuantityInStock == 1 ? (
                <Dropdown.Toggle id="myElementId" as="div" className="Complete">
                    {row.QuantityInStock ? `متوفر` : 'Select an option'}
                </Dropdown.Toggle>
            ) : (
                <Dropdown.Toggle id="myElementId" as="div" className="Pending">
                    {row.QuantityInStock ? `غير متوفر` : 'Select an option'}
                </Dropdown.Toggle>
            )}
        </div>
        <Dropdown.Menu className='task-drop-menu'>
            <Dropdown.Item eventKey="2" onClick={() => handleAction(row.IngredientID, 'QuantityInStock', '2')}>
                غير متوفر
            </Dropdown.Item>
            <Dropdown.Item eventKey="1" onClick={() => handleAction(row.IngredientID, 'QuantityInStock', '1')}>
                متوفر
            </Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
</td>

                            </tr>
                          ))
                        )
                      }

                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>

        </div>
      </Modal>
    </>
  )
})
export default InviteCustomer;