import React from 'react';

import InviteCustomer from '../jsx/constant/ExpenseStatusModal';

const ExpenseTypes = () => {
    return(
<>
<InviteCustomer/> 

</>





    )
//ref={invite}     
//Title="إضافة دورة"
}
export default ExpenseTypes;