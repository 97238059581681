import React, { useState, forwardRef, useEffect,useImperativeHandle  } from 'react';
import {Modal} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import axios from "axios";
 import {useNavigate, useParams} from "react-router-dom";
 import { ToastContainer, toast } from "react-toastify";
 import "react-toastify/dist/ReactToastify.css";

const InviteCustomer = forwardRef((props, ref) => {
   const navigate = useNavigate();
    const [inviteModal , setInviteModal] = useState(null);
      const showInviteModal = () => {   
        setInviteModal(false)
        setUser('')
        }
        const userdata1 = localStorage.getItem('USER_data');
        const userData = JSON.parse(userdata1);
          const userid = userData.id;
         //------------------------
const [user, setUser] = useState({
    HallName: '',
      HallAddress: '',
      HallContactNumber: '',
      ItemPrice: '',
      UserID: userid,
      PreName: '',
  })
         useEffect(() => {
           
                setUser(props.data)
             
            }, [props.data, setUser]);
            useImperativeHandle(ref, () => ({
              showInviteModal() {
                setUser(props.data)
       
                  setInviteModal(true)
              }
          
            }))    
 //--------------------------------------------------
 const [errors, setErrors] = useState('')
 useEffect(() => {
    const handleEnterKey = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const form = e.target.form;
        const fields = Array.from(form.elements).filter(el => (
          (el.tagName === 'INPUT' && !el.disabled) ||
          (el.tagName === 'TEXTAREA' && !el.disabled) ||
          (el.tagName === 'SELECT' && !el.disabled)
        ));      const currentFieldIndex = fields.findIndex(el => el === document.activeElement);
        const nextFieldIndex = currentFieldIndex + 1;
        if (nextFieldIndex < fields.length) {
          fields[nextFieldIndex].focus();
        }
      }
    };
    
    document.addEventListener('keydown', handleEnterKey);
    
    return () => {
      document.removeEventListener('keydown', handleEnterKey);
    };
  }, []);
  const notifyTopRight = () => {
    toast.success("✔️ تمت عملية التعديل بنجاح", {
      position: "top-right",
      autoClose: 850,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });
  }; 
 const onSubmit = ev => {
    ev.preventDefault()
    const myElement = document.getElementById('Hallid');
    { if (!user.HallContactNumber.match(/^(218)?09[123456][0-9]{7}$/)  ) {
   
        setErrors('رقم الهاتف  : 00 00 000 091 او 00 00 000 092')
        console.log(errors)
           }
        else{

      axios.put(`https://suhba.server.com.ly/back/api/wedding_halls/${user.HallID}`, user)
        .then(() => {
            setUser('')
            props.parentFunction()
            setErrors('')
            notifyTopRight()
            setInviteModal(false)
          })
        .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
          });
         
  }}}
console.log('uder',user)
const handleInputChange = (e) => {
  const value = e.target.value;
  if (value === '') {
      setUser((prevData) => ({
          ...prevData,
          PreName: 'لايوجد',
      }));
  }
};
 //---------------------------------------------
    return(
        <>
         <div>

         </div>
         <ToastContainer />

             <Modal className="modal fade" id="exampleModal1"   show={inviteModal}  centered> 
            
        
                <div className="modal-content">
                <div className="modal-header">
                        <h1 className="modal-title fs-5 " id="exampleModalLabel1">تعديل بيانات الصالة </h1>
                        <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={()=>showInviteModal()}></button>
                    </div>
                          <form onSubmit={onSubmit}>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-xl-12">
                      

                            <div className="row">
                            <div className="col-xl-12">
                        {errors? <div className=' alert alert-danger'>{errors}</div> : ""}
                        </div>
                                    <div className="col-xl-6 ">
                                        <label className="form-label ">الاسم الحالي<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <input type="text"className="form-control" name="HallName"  value={user.HallName} 
                                            onChange={ev => setUser({...user, HallName: ev.target.value})} 
                                            />

                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <label className="form-label ">الاسم السابق<span className="text-danger">*</span></label>
                                        <div className="input-group">
 
                                        <input type="text" className="form-control" name="PreName" value={user.PreName} 
                                                       onChange={ev => setUser({...user, PreName: ev.target.value}) } 
                                                       onBlur={handleInputChange}
                                                      

                                        />

                                         </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                <label className="form-label mt-3">العنوان<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name="HallAddress" value={user.HallAddress} 
                                onChange={ev => setUser({...user, HallAddress: ev.target.value})}
                                  required
                                />
                                </div>
                                <div className="row">
                                    <div className="col-xl-6 ">
                                        <label className="form-label mt-3">رقم الهاتف<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                        <input type="text" className="form-control" name="HallContactNumber" value={user.HallContactNumber}
                                        onChange={ev => setUser({...user, HallContactNumber: ev.target.value})}
                                        />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <label className="form-label mt-3">سعر التوصيل<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                        <input type="text" className="form-control"  name="ItemPrice" value={user.ItemPrice} 
                                        onChange={ev => setUser({...user, ItemPrice: ev.target.value})}
                                        pattern="\d+(\.\d{1,2})?"
                                                                                title="الرجاء إدخال ارقام فقط"
                                        required/>
                                        </div>
                                    </div>
                                </div>
                                <input type="text" hidden="true" name="UserID" value={userid} />
                                <input type="text"  id ="Hallid" hidden="true" name="HallID" value={props.HallID} />

                            </div>
                        </div>                                
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-primary" >
                       
                         حفظ التعديلات </button>
                        <button type="button" className="btn btn-danger light" onClick={()=>showInviteModal()}>إلغاء</button>

                    </div> 
                     </form>
                </div>               
            </Modal>
        </>
    )
})
export default InviteCustomer;