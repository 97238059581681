import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
  import axios from "axios";
  import Swal from 'sweetalert2';
  import { useNavigate, useParams } from "react-router-dom";
  import swal from 'sweetalert';

 

const FormValidation = () => {
  const navigate = useNavigate();
  const userdata1 = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata1);
    const userid = userData.id;
  const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');
  const userPermissions = JSON.parse(userPermissionsJSON);
  function getActionsForPage(pageName) {
    const pagePermissions = userPermissions.find(permission => permission.resource === pageName);
 console.log('actionsForPage',pagePermissions); 
   if (pagePermissions) {
       return pagePermissions.action;
   } else {
  navigate('/dashboard_1')
      return [];
   }
 }
 const pageName1 = 'FormValidation';
 const actionsForPage = getActionsForPage(pageName1);
  const [empname, setempname] = useState('')
  const [OffOn, setOffOn] = useState(true)
  const [OffOn1, setOffOn1] = useState(true)
  const [OffOn12, setOffOn12] = useState(true)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [pagename, setPagename] = useState('')
  const [calculatedRows, setCalculatedRows] = useState([]);
  const calculate1 = (value) => {
    // Check if there's already a row with the same pagename and permission
    const existingRow = calculatedRows.find(row => row.pagename === pagename && row.permission === value);

    // If there's an existing row with the same pagename and permission, don't add a new row
    if (existingRow) {
      return;
    }

    // If there's no existing row, create a new row and add it to the calculatedRows
    const newRow = {
      username: username,
      pagename: pagename,
      permission: value,
      RoleID: 1,
    };

    setCalculatedRows([...calculatedRows, newRow]);
  };
  const [emp, setEmp] = useState([]);

  const getEmp = async () => {
    await axios.get('https://suhba.server.com.ly/back/api/getEmp1').then(({ data }) => { setEmp(data) })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });

  }
  const deletePage = (pagename, index) => {
    // Check if the index is within the bounds of the array
    if (index >= 0 && index < calculatedRows.length) {
      calculatedRows.splice(index, 1);
      setCalculatedRows([...calculatedRows]);
      // Show success message using Sweet Alert
      Swal.fire({
        icon: 'success',
        title: 'تم حدف الصفحة',
       });
    } else {
      // Show error message using Sweet Alert if the index is out of bounds
      Swal.fire({
        icon: 'error',
        title: 'Invalid Index!',
        text: `The index ${index} is out of bounds for the array.`
      });
    }
  };
  
  const deletePage1 = (pagename) => {
    const rowIndex = calculatedRows.findIndex(row => row[0].pagename === pagename);
    calculatedRows.splice(rowIndex, 1);
    setCalculatedRows([...calculatedRows]);

  }
  const deletePermission = (pagename, permission) => {
    // Find the index of the row that contains the pagename and permission
    const rowIndex = calculatedRows.findIndex(row => row[0].pagename === pagename && row.some(item => item.permission === permission));
  
    // If the row exists, remove the permission from the array
    if (rowIndex !== -1) {
      calculatedRows[rowIndex] = calculatedRows[rowIndex].filter(item => item.permission !== permission);
      
      // If the row becomes empty after removing the permission, remove the entire row
      if (calculatedRows[rowIndex].length === 0) {
        calculatedRows.splice(rowIndex, 1);
      }
  
      // Update the state with the modified calculatedRows array
      setCalculatedRows([...calculatedRows]);
    }
  };
  
  const calculate = (permission,pagename ,username, password) => {
    if(pagename ==='اختر من القائمة' || permission ==='اختر من القائمة' || pagename=== '' || permission=== '' )
      {
  
        swal('الرجاء اختر اسم الصفحه واسم الاجراء من القائمة المنسدلة')
      }else{
      console.log('pagename',pagename)
    const existingIndex = calculatedRows.findIndex(row => row[0].pagename === pagename && row.some(item => item.permission === permission));

    // If an existing row is found, return
    if (existingIndex !== -1) {
      return;
    }

    // Create a new row object
    const newRow = {
      username, password, pagename, permission, empname,userid
    };

    // Find the index of the pagename in the calculatedRows array
    const index = calculatedRows.findIndex(rows => rows[0].pagename === pagename);

    // If the pagename already exists, push the permission to the existing array
    if (index !== -1) {
      calculatedRows[index].push(newRow);
    } else {
      // If the pagename doesn't exist, create a new array for it
      calculatedRows.push([newRow]);
    }

    // Update the state with the new calculatedRows array
    setCalculatedRows([...calculatedRows]);
  }
  };


  useEffect(() => {



    getEmp();



  }, [])
  const createintitem = async (e) => {
    e.preventDefault();
  
    // Check if calculatedRows is empty or doesn't contain any permissions
    if (calculatedRows.length === 0 || calculatedRows.every(row => row.length === 0)) {
      // Show Sweet Alert with an error message
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: 'يجب عليك ادخال اسم الصفحة و الاجراءات الخاصة بها'
      });
      return;
    }
  
    console.log('before post', calculatedRows);
  
    // If calculatedRows is valid, proceed with the POST request
    await axios.post('https://suhba.server.com.ly/back/api/userpermissions', { calculatedRows })
      .then(response => {
        Swal.fire({
          icon: 'success',
          title: 'تم!',
          text: 'تم إضافة صلاحيات الدخول بنجاح'
        });
        // Reset form fields
        setUsername('');
        setPassword('');
        setPagename('');
        setempname('');
        setOffOn1(true)
        setCalculatedRows([]);
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
    
  };
  const Pagenamearray = [
    { value: "FormValidation", name: "صلاحيات المستخدم" },
    { value: "EmployeeStatus", name: "حالات الموظف" },

    { value: "CompanyExpensesreport", name: "تقارير المصروفات للشركة" },
    { value: "Income", name: "الدخل" },
    { value: "CompanyPurchases", name: "مشتريات الشركة" },
    { value: "Ingredients", name: "المكونات" },
    { value: "First", name: "إحصائية دخل الشركة" },
    { value: "Atbak", name: "الأطباق" },
    { value: "Customers", name: "الزبائن" },
    { value: "Departments", name: "الأقسام" },
    { value: "ExpenseTypes", name: "أنواع المصروفات" },
    { value: "IntTypes", name: "أنواع المكونات" },
    { value: "ManageUsers", name: "بيانات المستخدمين" },
    { value: "Garantees", name: "ضمانات الطلبيات" },
   { value: "NotConfirmedOrder", name: "فاتورة مبدئية" },
    { value: "CompanyExpenses", name: "مصاريف الشركة" },
    { value: "Dashboard_1", name: "الشاشة الرئيسة" },
    { value: "ToolsReport", name: "تقرير الأدوات" },
    { value: "AsnafReport", name: "تقرير الأصناف" },
    { value: "Tools", name: "المعدات" },
    { value: "MainCompany", name: "الشركة الرئيسية" },
    { value: "WeddingHall", name: "قاعة الزفاف" },
    { value: "guaranteetype", name: "نوع الضمان" },
    { value: "ProcessStatus", name: "حالة العملية" },
    { value: "Orders", name: "الطلبات" },
    { value: "OrdersList", name: "كل الطلبات" },
    { value: "Al-asnaf", name: "الأصناف" },
    { value: "city", name: "المدينة" },
    { value: "Empolyee", name: "الموظفون" },
    { value: "StorageType", name: "نوع التخزين" }
    
];
const checkempty = (e) => {
  console.log('e.target.value',e.target.value)
  if(e.target.value === '')
    {
      setOffOn1(true);
      setPassword('')

    }else{
  setPassword(e.target.value)
  setOffOn1(false);
    }
}
const namefun = (e) => {
  setempname('');
  console.log('e.target.value',e)

if (e === 'اختر من القائمة')
  {    setOffOn12(true);
  }else{
emp.map((item) => {
  if (e == item.EmployeeID) {
    setUsername(`${item.FirstName}@suhba.server.com.ly`)
    setempname(e);

    setOffOn12(false);

  }
})}
}
const clearAllPermissions = () => {
  setCalculatedRows([])
  setPagename('')
}
  const [showPassword, setShowPassword] = useState(false);
  const addAllPermissions = () => {
    const permission = ['Add', 'Edit', 'Show', 'Delete', 'Print']; // Array of permissions

  

    // Iterate over each page name in the page names list
    for (const page of Pagenamearray) {
      // Iterate over each permission in the permission list
      for (const perm of permission) {
        // Call the calculate function to add the combination of page name and permission to the calculatedRows state
        calculate(perm, page.value,page.name, username, password);
      }
    }

    // Show success message using Sweet Alert
    Swal.fire({
      icon: 'success',
      title: 'تم!',
      text: 'تمت إضافة كافة الصلاحيات الى المستخدم '    });
  };
  console.log("calculatedRows",calculatedRows)
  const mapPageNameToArabic = (englishPageName) => {
    console.log('englishPageName',englishPageName)
    const pagenameMap = {
   "AllOrderDateLimits":"إدارة التواريخ" ,
   "EmployeeStatus":"حالات الموظف" ,

  "ManageUsers":   "بيانات المستخدمين" ,
     "Garantees": "ضمانات الطلبيات" ,
     "NotConfirmedOrder": "فاتورة مبدئية" ,
      "FormValidation": "صلاحيات المستخدم",
      "CompanyExpensesreport": "تقارير المصروفات للشركة",
      "Income": "الدخل",
      "CompanyPurchases": "مشتريات الشركة",
      "Ingredients": "المكونات",
      "First": "إحصائية دخل الشركة",
      "Atbak": "الأطباق",
      "Customers": "الزبائن",
      "Departments": "الأقسام",
      "ExpenseTypes": "أنواع المصروفات",
      "IntTypes": "أنواع المكونات",
      "CompanyExpenses": "مصاريف الشركة",
      "Dashboard_1": "الشاشة الرئيسة",
      "ToolReport": "تقرير الأدوات",
      "AsnafReport": "تقرير الأصناف",
      "Tools": "المعدات",
      "MainCompany": "الشركة الرئيسية",
      "WeddingHall": "قاعة الزفاف",
      "guaranteetype": "نوع الضمان",
      "ProcessStatus": "قفل / فتح تاريخ ",
      "Orders": "الطلبات",
      "OrdersList": "كل الطلبات",
      "Al-asnaf": "الأصناف",
      "city": "المدينة",
      "Empolyee": "الموظفون",
      "StorageType": "نوع التخزين"
    };

    return pagenameMap[englishPageName] || englishPageName;
  };
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">

          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">إدارة الصلاحيات للمستخدم</h4>
              </div>
              <div className="card-body">
                <div className="row">

                  <div className="col-lg-6">

                    <div className="basic-form">


                    <form onSubmit={createintitem}  autocomplete="off">
                        <div
                        >
                          <label className="text-label mt-2">اسم الموظف</label>

                          <div className="input-group">

                            <span className="input-group-text">
                              <i className="fa fa-user" />{" "}
                            </span>

                            <select className="default-select form-control" id="myinput" required name="DeliveryPersonID"
                              onChange={(e) => {namefun(e.target.value) }}
                              value={empname} >
                                
                              <option data-display="Select">اختر من القائمة</option>
                              {
                                emp.map((row) => (
                                  <option key={row.EmployeeID} value={row.EmployeeID}>
                                    {row.FirstName}
                                  </option>
                                ))}
                            </select>



                          </div>

                          <label className="text-label mt-2">اسم المستخدم</label>


                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fa fa-user" />{" "}
                            </span>
                            <input
                            disabled="true"
                              type='text'
                              className="form-control"
                              id="Username"
                              required
                              name="Username"
                               value={username}
                              placeholder=""
                            />
                          </div>

                          <label className="text-label mt-2">كلمة المرور *</label>
                          <div className="input-group transparent-append mb-2">

                            <span className="input-group-text">
                              {" "}
                              <i className="fa fa-lock" />{" "}
                            </span>

                            <input
                              type={`${showPassword ? "text" : "password"}`}
                              className="form-control"
                              id="val-password1"
                              name="password"
                              onChange={(e) =>checkempty(e) } 
                              disabled={OffOn12}
                              value={password}
                              required
                             />

                            <div
                              className="input-group-text "
                              onClick={() => setShowPassword(!showPassword)
                                
                               }
                            >

                              {" "}
                              {showPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}

                            </div>
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                            </div>
                          </div>
                        </div>



                        <button type="submit" className="btn btn-primary ">
                          حفظ الاعدادات
                        </button>
                      </form>

                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                    <div className="row">
   <div className="col-lg-12">
    <div className="row" style={{justifycontent: 'center'}}>
      <button        disabled={OffOn1}
className="col-lg-5 btn btn-primary btn-sm mb-2 ms-1 " onClick={addAllPermissions}>
        إضافة كل الصلاحيات
      </button>
      <button  disabled={OffOn1} className="col-lg-5 btn btn-secondary btn-sm mb-2 ms-1 " onClick={clearAllPermissions}>
        إزالة كل الصلاحيات
      </button>
   </div>
</div>

  <div className="form-group mb-3 col-lg-6">
    <label disabled={OffOn1} className="col-form-label" htmlFor="val-username">
      اسم الصفحة
      <span className="text-danger">*</span>
    </label>
    <select 
       onChange={(e) => { setPagename(e.target.value) 
        setOffOn(false);
      }}
      className="form-control"
      id="val-skill"
      name="val-skill"
      value={pagename}
      disabled={OffOn1}
    >
      <option data-display="Select">اختر من القائمة</option>
                              
                                 {Pagenamearray.map((item, index) => (
                                  <option key={index} value={item.value}>{item.name}</option>
                                ))}
    </select>
  </div>
  <div className="form-group mb-3 col-lg-6">
    <label  disabled={OffOn} className="col-form-label" htmlFor="val-username">
      الاجراءات
      <span className="text-danger">*</span>
    </label>
    <select
      onChange={(e) => { calculate(e.target.value,pagename, username, password) }}
      className="form-control"
      id="val-skill"
      name="val-skill"
      disabled={OffOn}

    >
      <option value="">اختر من القائمة</option>
      <option value="Add">أضافة</option>
      <option value="Edit">تعديل</option>
      <option value="Show">عرض</option>
      <option value="Delete">حدف</option>
      <option value="Print">طباعه</option>
    </select>
  </div>
</div>

                      <div className="table-responsive active-projects task-table my-2 mx-2" style={{ height: "200px" }}>
                         <div class="row">
                          {calculatedRows.map((pagenameArray, index) => (
                            <div key={index} className="col-lg-5 border m-2 p-2">
                 <p><strong>{mapPageNameToArabic(pagenameArray[0].pagename)}</strong>
                           
                         <i class="fa-regular fa-circle-xmark mx-1" style={{float:'left'}}
                         onClick={() => deletePage(pagenameArray.pagename,index)}>   </i></p>
                              <ul style={{
                                display: "flex",
                                flexwrap: "wrap"
                              }}>
                                {pagenameArray.map((row, idx) => (
                                   <li key={idx}>
                                  {row.permission}
                                   <i class="fa-solid fa-circle-xmark mx-1" onClick={() => deletePermission(row.pagename, row.permission)}>   </i>
                                   
                                 </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </div>             </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormValidation;
