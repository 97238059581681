import React from "react";

const Footer = () => {
	var d = new Date();
	return (
		<div className="footer out-footer">
			<div className="copyright">
				<p>الحقوق محفوضة © 					
					{" "}تم تطويره من قبل{" "}
					<a href="http://al-fekra.ly//" target="_blank"  rel="noreferrer">
						الفكرة الأولى 
					</a>{" "}
					{d.getFullYear()}
				</p>
			</div>
		</div>
	);
};

export default Footer;
