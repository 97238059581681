import React, { useState, useEffect } from "react";
import axios from "axios";
import { ProgressBar, Badge, Table } from "react-bootstrap";
import { Col, Row, Tab, Nav } from 'react-bootstrap';
import Empdata from './IncomeYhosmonth';
import Empdata1 from './Incomebyday';
import { useNavigate } from "react-router-dom";

const OutcomeTable = () => {
    const [outcomeData, setOutcomeData] = useState([]);
    const [totalAmountSum, setTotalAmountSum] = useState(0);
    const [prepayAmountSum, setPrepayAmountSum] = useState(0);
    const [restValue, setRestValue] = useState(0);
    const navigate = useNavigate();

    const arabicMonthNames = [
        "يناير", "فبراير", "مارس", "إبريل", "مايو", "يونيو",
        "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"
    ];
    const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');

    // Parse the JSON string back to an array
    const userPermissions = JSON.parse(userPermissionsJSON);
    // Function to search for permissions for a specific page
    function getActionsForPage(pageName) {
      // Find the permissions object for the given page
      const pagePermissions = userPermissions.find(permission => permission.resource === pageName);
    console.log('actionsForPage',pagePermissions); 
    
      // If permissions for the page are found, return the actions
      if (pagePermissions) {
          return pagePermissions.action;
      } else {
     navigate('/dashboard_1')
    
          return [];
      }
    }
    
    // Usage
    const pageName = 'Income';
    const actionsForPage = getActionsForPage(pageName);
    const [totalAmountByMonth, setTotalAmountByMonth] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://suhba.server.com.ly/back/api/getTotalAmountByMonth");
                const data = await response.json();
                setTotalAmountByMonth(data);
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth() + 1; // JavaScript months are zero-based
          
                const filteredData = data.filter(item => {
                    return item.year === currentYear && item.month === currentMonth;
                });
        
                const totalAmountSum1 = filteredData.reduce((acc, item) => acc + parseFloat(item.total_amount), 0);
                const prepayAmountSum1 = filteredData.reduce((acc, item) => acc + parseFloat(item.prepay_amount), 0);
                setTotalAmountSum(totalAmountSum1);
                setPrepayAmountSum(prepayAmountSum1);
                setRestValue(totalAmountSum1 - prepayAmountSum1);
                setTotalAmountByMonth(filteredData)
                console.log(data)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    /*useEffect(() => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // JavaScript months are zero-based
  
        const filteredData = totalAmountByMonth.filter(item => {
            return item.year === currentYear && item.month === currentMonth;
        });

        const totalAmountSum1 = filteredData.reduce((acc, item) => acc + parseFloat(item.total_amount), 0);
        const prepayAmountSum1 = filteredData.reduce((acc, item) => acc + parseFloat(item.prepay_amount), 0);
        setTotalAmountSum(totalAmountSum1);
        setPrepayAmountSum(prepayAmountSum1);
        setRestValue(totalAmountSum1 - prepayAmountSum1);
        setTotalAmountByMonth(filteredData)
    }, [totalAmountByMonth]);
*/
    const handleClearSearch = () => {
        setTotalAmountSum(0);
        setPrepayAmountSum(0);
        setRestValue(0);
    };
 
    return (
        <div className='container-fluid'>
            <Row>
                <Col lg="12">
                    <div className="card px-4">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">

                                <div className="tbl-caption">
                                    <h4 className="heading ms-3">دخل الشركة</h4>
                                </div>
                                <Tab.Container defaultActiveKey={'Profile'}>
                                    <div className=" tab-content">
                                        <Nav as="ul" className="nav-tabs">

                                            <Nav.Item as="li" key='Profile'>
                                                <Nav.Link eventKey={'Profile'}>
                                                    <i class="far fa-file-alt ms-2"></i>
                                                    تقارير الدخل لهدا الشهر

                                                </Nav.Link>
                                            </Nav.Item>

                                            <Nav.Item as="li" key='masrofat'>
                                                <Nav.Link eventKey={'masrofat'}>
                                                    <i class="fas fa-file-alt ms-2"></i>
                                                    تقارير الدخل السنوي

                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li" key='Profile1'>
                                                <Nav.Link eventKey={'Profile1'}>
                                                    <i class="far fa-file-alt ms-2"></i>
                                                    تقارير الدخل  اليومي

                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Pane eventKey={'masrofat'}>
                                            <div className="container-fluid"  >
                                                <Empdata />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={'Profile'}>
                                            <div className="container-fluid"  >
                                                <div className="table-responsive">
                                                    <div className="row mb-3" style={{ display: 'flex' }}>
                                                        <div className="col-md-4" style={{ display: 'flex' }}>
                                                            <label htmlFor="startMonth" style={{ fontsize: '15px', fontWeight: 'bold' }}> اجمالي الدخل :</label>
                                                            <div style={{ color: 'blue' }}> <strong>{totalAmountSum}</strong></div>
                                                        </div>
                                                        <div className="col-md-4" style={{ display: 'flex' }}>
                                                            <label style={{ fontsize: '15px', fontWeight: 'bold' }} htmlFor="startMonth"> اجمالي العرابين :</label>
                                                            <div style={{ color: 'red' }}><strong>{prepayAmountSum}</strong></div>
                                                        </div>
                                                        <div className="col-md-4" style={{ display: 'flex' }}>
                                                            <label style={{ fontsize: '15px', fontWeight: 'bold' }} htmlFor="startMonth"> صافي الدخل :</label>
                                                            <div style={{ color: 'green' }}><strong>{restValue}</strong>        </div>
                                                        </div>
                                                    </div>
                                                    <Table responsive striped bordered className="verticle-middle">
                                                        <thead>
                                                            <tr>
                                                                <th>الشهر</th>
                                                                <th>السنة</th>

                                                                <th> اجمالي الدخل</th>
                                                                <th>أجمالي العرابين</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {totalAmountByMonth.map((item, index) => (
  <tr key={index}>
    <td>{arabicMonthNames[item.month - 1]}</td>
    <td>{item.year}</td>

    <td>{item.total_amount}</td>
    <td>{item.prepay_amount}</td>
  </tr>
))}

                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={'Profile1'}>
                                            <div className="container-fluid"  >
                                                <Empdata1 />
                                            </div>
                                        </Tab.Pane>
                                    </div>
                                </Tab.Container>

                            </div>
                        </div> </div> 

                </Col>
            </Row>
        </div>
    );
};

export default OutcomeTable;
