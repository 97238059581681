import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";

import { IMAGES } from '../jsx/constant/theme';


const Company = () => {
  const userdata = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata);
    const userid = userData.id;
  const [errors, setErrors] = useState(0);
  const [newCompany, setNewCompany] = useState({
    CompanyID:'',
   CompanyName:'',
   	Address :'',
    ContactNumber:'',
    	Email:'',
     Website:'',
     	ImageName :'',
      Description:'',
     	UserID:userid,
  });
  

  useEffect(() => {
    fetchProducts();
    console.log(newCompany);

  }, [])
   
  console.log(newCompany);

  const fetchProducts = async () => {
    await axios.get(`https://suhba.server.com.ly/back/api/maincompanies`).then((data) => {
      if (data) {
        setNewCompany(data.data)
       }
      console.log(newCompany);

    })

      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }


  
     const onSubmit = ev => {
      ev.preventDefault()
      setErrors('')  
  
       if (!newCompany.ContactNumber.match(/^(218)?09[123456][0-9]{7}$/) ) {
     
  setErrors('رقم الهاتف  : 00 00 000 091 او 00 00 000 092')
  console.log(errors)
     }
  else{
   
        axios.put(`https://suhba.server.com.ly/back/api/maincompanies/${newCompany.CompanyID}`, newCompany)
          .then(() => {
            //  setCustomers('')
            // props.parentFunction()
     //   setErrors('')  
  
            //  props.show(false) 
          })
          .catch(function (error) {
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
            });
           
  
    }}
  const [selectOption , setSelectOption] = useState('Gender');
  const changeHandler = async (e ,id, field, value) => {
 
    console.log('value',value)
    try {
        const formData = new FormData();
        formData.append('_method', 'PATCH')

         formData.append('ImageName', value);
         

          await axios.put(`https://suhba.server.com.ly/back/api/maincompanies/${id}`, formData,);
        console.log('Image uploaded successfully');
      } catch (error) {
        console.error('Error uploading image:', error);
      }
}

  return (
    <>
      <form className="profile-form" onSubmit={onSubmit}  >

        <div className="container-fluid">
          <div className="row">

            <div className="col-xl-3 col-lg-4">
              <div className="clearfix">
                <div className="card card-bx profile-card author-profile m-b30">
                  <div className="card-body">
                    <div className="p-5">
                      <div className="author-profile">

                        <div className="author-media">

                          <img width="100px" src={`https://suhba.server.com.ly/back${newCompany.ImageName}`} />
                          <div className="upload-link" title="" data-toggle="tooltip" data-placement="right" data-original-title="update">
                          <input multiple className="update-flie" type="file" id="formFile" name='ImageName'defaultValue={newCompany.ImageName}
                      onChange={(e) => changeHandler(e,newCompany.CompanyID, 'ImageName',e.target.files[0])}/>
                         <i className="fa fa-camera"></i>

                          </div>
                        </div>
                        <div className="author-info">
                          <h6 className="title">صحبة طيبة</h6>
                          <span>معمل مأكولات</span>
                        </div>
                      </div>
                    </div>
                    <div className="info-list">
                      <ul>
                        <li><Link to={"/app-profile"}>مسؤل نطام</Link><span>36</span></li>
                        <li><Link to={"/uc-lightgallery"}>شيف</Link><span>3</span></li>
                        <li><Link to={"/app-profile"}>موظف</Link><span>1</span></li>
                        <li><Link to={"/app-profile"}>عامل توصيل</Link><span>1</span></li>

                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="card  card-bx m-b30">
                <div className="card-header">
                  <h6 className="title">بيانات الشركة</h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-6 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1">
                          <label htmlFor="exampleFormControlInput1" className="form-label"> الاسم <span className="text-danger">*</span></label>
                        </div><div className="col-xl-9 mb-1">
                          <input type="text" className="form-control" id="exampleFormControlInput1" placeholder=""
                            name="CompanyName" defaultValue={newCompany.CompanyName} 
                            onChange={ev => setNewCompany({...newCompany, CompanyName: ev.target.value})} 

                          />
                        </div> </div>



                    </div>
                    <div className="col-xl-6 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1">
                          <label htmlFor="exampleFormControlInput1" className="form-label">النشاط<span className="text-danger">*</span></label>
                        </div><div className="col-xl-9 mb-1">
                          <input type="text" className="form-control" id="exampleFormControlInput1" placeholder=""
                            name="Description" defaultValue={newCompany.Description}
                          />
                        </div> </div>



                    </div>




                    <div className="col-xl-6 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1">
                          <label htmlFor="exampleFormControlInput1" className="form-label"> رقم الهاتف <span className="text-danger">*</span></label>
                        </div><div className="col-xl-9 mb-1">
                          <input type="text" className="form-control" id="exampleFormControlInput1" placeholder=""
                            name="ContactNumber" defaultValue={newCompany.ContactNumber} 
                            onChange={ev => setNewCompany({...newCompany, ContactNumber: ev.target.value})} 

                          />
                        </div> </div>



                    </div>
                   
                    <div className="col-xl-6 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1">
                          <label htmlFor="exampleFormControlInput1" className="form-label"> العنوان <span className="text-danger">*</span></label>
                        </div><div className="col-xl-9 mb-1">
                          <input type="text" className="form-control" id="exampleFormControlInput1" placeholder=""
                            name="Address" defaultValue={newCompany.Address} 
                            onChange={ev => setNewCompany({...newCompany, Address: ev.target.value})} 

                          />
                        </div> </div>



                    </div>
                    <div className="col-xl-6 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1">
                          <label htmlFor="exampleFormControlInput1" className="form-label">الموقع الالكتروني<span className="text-danger">*</span></label>
                        </div><div className="col-xl-9 mb-1">
                          <input type="text" className="form-control" id="exampleFormControlInput1" placeholder=""
                            name="Website" defaultValue={newCompany.Website} 
                            onChange={ev => setNewCompany({...newCompany, Website: ev.target.value})} 

                          />
                        </div> </div>



                    </div>
                    <div className="col-xl-6 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1">
                          <label htmlFor="exampleFormControlInput1" className="form-label">البريد الالكتروني<span className="text-danger">*</span></label>
                        </div><div className="col-xl-9 mb-1">
                          <input type="Email" className="form-control" id="exampleFormControlInput1" placeholder=""
                            name="Email" defaultValue={newCompany.Email} 
                            onChange={ev => setNewCompany({...newCompany, Email: ev.target.value})} 

                          />
                        </div> </div>



                    </div>
                  </div>
                </div>
                <div className="card-footer pt-5">
                  <button type='submit' className="btn btn-primary">حفظ</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
export default Company;