
import React, { useState, forwardRef, useImperativeHandle  ,useEffect} from 'react';
import {Modal} from 'react-bootstrap';
 import { Dropdown } from 'react-bootstrap';
 import axios from "axios";
  import { useNavigate ,Link} from "react-router-dom";
  import swal from 'sweetalert';
import { setDefaultLocale } from 'react-datepicker';
const InviteCustomer = forwardRef((props, ref) => {
    const [inviteModal , setInviteModal] = useState(false);
    const [Name,setName] = useState()
    const [QuantityInStock,setQuantityInStock] = useState('')
    const [ItemPrice,setItemPrice]= useState('')
   // const [AreaName,setAreaName] = useState('')

    const [enable, setenable] = useState(false)
    const [status, setStatus] = useState();
     const [data, setData] = useState([])
     const [catigorydata, setCatigorydata] = useState([])
     const [storagedata, setStoragedata] = useState([])
     const [intdata, setIntdata] = useState([])
     const [updatedIds, setUpdatedIds] = useState([]);
const closemodel= ()=>{
     setFoodItemint('')
     setenable(false)

             setFoodIteming('')
  setInviteModal(false)
  setError('')
}
  //  const { AreaID } = useParams();
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    showInviteModal() {
      setFoodItemint(initialFoodItemState)
        setInviteModal(true)
    }

  }))      ;
  
        

    /////////////////////////////////////
    const userdata = localStorage.getItem('USER_data');
    const userData = JSON.parse(userdata);
    const userid = userData.id;
           
         
            // console.log(formData);
            const [foodItem, setFoodItem] = useState({
                Name: '',
                CategoryID: '',
                StorageID: '',
                ItemPrice: '',
                QuantityInStock: '1',
                UserID: userid,
                status:'متوفر'
              });
            
            ///////////////////////////////////////////////
      const getcities = () => {
        axios.get('https://suhba.server.com.ly/back/api/food_items')
         .then(({ data }) => {
            setData(data)
         })}

         const [currentPage , setCurrentPage] = useState(1);
         const recordsPage = 8;
         //const lastIndex = currentPage * recordsPage;
        // const firstIndex = lastIndex - recordsPage;   
        // const records = data.slice(firstIndex, lastIndex);
       //  const npage = Math.ceil(data.length / recordsPage)
     //    const number = [...Array(npage + 1).keys()].slice(1)
     const input = document.querySelector('input[name="city"]');
     if(input)
{
     input.addEventListener('invalid', function (event) {
       if (event.target.validity.valueMissing) {
         event.target.setCustomValidity('الرجاء ادخال اسم الاداة');
       } 
     })
     
     input.addEventListener('change', function (event) {
       event.target.setCustomValidity('');
     })}
        
    
       

      useEffect(() => {
        getcities();
        getint();
        getfooditem();
       // getfooditeming();

    }, [])
    console.log(foodItem)
  
  
    useEffect(() => {
      const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          const form = e.target.form;
          const fields = Array.from(form.elements).filter(el => (
            (el.tagName === 'INPUT' && !el.disabled) ||
            (el.tagName === 'TEXTAREA' && !el.disabled) ||
            (el.tagName === 'SELECT' && !el.disabled)
          ));      const currentFieldIndex = fields.findIndex(el => el === document.activeElement);
          const nextFieldIndex = currentFieldIndex + 1;
          if (nextFieldIndex < fields.length) {
            fields[nextFieldIndex].focus();
          }
        }
      };
      
      document.addEventListener('keydown', handleEnterKey);
      
      return () => {
        document.removeEventListener('keydown', handleEnterKey);
      };
    }, []);
     
//-------------------------------------------------------------------------------
          
          const [foodIteming, setFoodIteming] = useState([])
          const [foodItems, setFoodItems] = useState([])
          const initialFoodItemState = {

          FoodItemID:'',
            IngredientID:'',
          Quantity:'',
          ForHowMany:'',
          UserID: userid,
          };
          const [foodItemint, setFoodItemint] = useState(initialFoodItemState)

          const getfooditem = () => {
            axios.get('https://suhba.server.com.ly/back/api/getlast')
             .then(({ data }) => {
              setFoodItems(data.FoodItem)
             })}
             const getfooditeming = (id) => {
              console.log('name',id)
              setFoodIteming([])

              axios.get(`https://suhba.server.com.ly/back/api/getT/${id}`)
               .then(({ data }) => {
                setFoodIteming(data)
                
                 if(data.length >0)
               { setFoodItemint((prevData) => ({
                  ...prevData,
                  ['ForHowMany']: data[0].ForHowMany,
                }));
                setenable(true)
              }else{
                setFoodItemint((prevData) => ({
                  ...prevData,
                  ['ForHowMany']: '',
                }));
                setenable(false)

              }
               }) .catch(function (error) {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log('Error', error.message);
                }
                console.log(error.config);
              });}
              
 
          const getint = () => {
            axios.get('https://suhba.server.com.ly/back/api/getint')
             .then(({ data }) => {
              setIntdata(data.Ingredient)
             })
             .catch(function (error) {
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
            });
             
             }
             const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const isValidInput = (inputValue) => {
    // Regular expression to match valid numeric inputs including a single decimal point
    const validNumberPattern = /^\d*\.?\d*$/;
  
    // Allow an empty string
    if (inputValue === '') return true;
  
    // Test the pattern and ensure there's at most one decimal point
    const isValidFormat = validNumberPattern.test(inputValue);
    const decimalPointCount = (inputValue.match(/\./g) || []).length;
  
    return isValidFormat && decimalPointCount <= 1;
  };
  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    setError('');
  
    // Validate and update state based on the input field name
    switch (name) {
      case "ForHowMany":
        if (value === '' || /^\d+$/.test(value)) {
          setFoodItemint((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          setError('');
        } else {
          setError('الرجاء ادخال قيمة عددية صحيحة');
        }
        break;
  
      case "Quantity":
        if (isValidInput(value)) {
          setFoodItemint((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          setError('');
        } else {
          setError('الرجاء ادخال قيمة عددية');
        }
        break;
  
      case "IngredientID":
        setFoodItemint((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        break;
  
      case "FoodItemID":
        setName(value);
        console.log('name id', value);
        setFoodItemint((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        getfooditeming(value);
        break;
  
      default:
        break;
    }
  };
  
  
             const handleInputChange2oold = (e) => {
              const { name, value } = e.target;
              setError('');
              if ( name ==="ForHowMany")
              { const inputValue = e.target.value;
                console.log('name in',name);
                if (inputValue === '' || /^\d+$/.test(inputValue)) {
                  setFoodItemint((prevData) => ({
                    ...prevData,
                    [name]: value,
                  }));                  setError('');
                } else {
                  setError('الرجاء ادخال قيمة عددية صحيحة');
                }
                
              }
              if ( name ==="Quantity")
                { const inputValue = e.target.value;
                  console.log('name in',name);
                  if (inputValue === '' || /^\d+(\.\d+)?$/.test(inputValue)) {
                    setFoodItemint((prevData) => ({
                      ...prevData,
                      [name]: value,
                    }));                    setError('');
                  } else {
                    setError('الرجاء ادخال قيمة عددية  ');
                  }
                  
                }
 
                if (name ==="IngredientID")
{
  setFoodItemint((prevData) => ({
    ...prevData,
    [name]: value,
  }));               

}
              if (name ==="FoodItemID")
              {setName(value); console.log('name id',Name)
                setFoodItemint((prevData) => ({
                  ...prevData,
                  [name]: value,
                }));
              getfooditeming(value)}
            

            
            
            };
             const createintitem = async(e)=>{
              e.preventDefault();
                    await axios.post('https://suhba.server.com.ly/back/api/food_item_ingredients', foodItemint)
              .then(res=>{
                  if(res.data.status === 200)
                  swal("عذرا",res.data.message,"warning");
              } )  .catch(function (error) {
                  if (error.response) {
                     console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('Error', error.message);
                  }
                  console.log(error.config);
                });
                getfooditeming(Name);
                getcities();
            } 
            console.log('new',foodItemint);
            const handleDelete = async (id,id1) => {
              swal({
                title: "هل متأكد من الحدف",
                text: " في حال حدف الملف لايمكنك استعادة هدا الملف",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
                .then(willDelete => {
                  if (willDelete) {
          
                    axios.delete('https://suhba.server.com.ly/back/api/food_item_ingredients/' + id + '/' + id1)
          
                      .then(res => {
                        setFoodIteming(foodIteming.filter(resource => resource.IngredientID !== id1 )); // Assuming resources is your state containing the table data
          
                        swal({
                          title: "تم",
                          text: "تم الحدف بنجاح",
                          icon: "success",
                          timer: 2000,
                          button: false
                        })
                        navigate('#')
          
          
                      });
          
                  } else {
                    swal("تم الغاء عملية الحدف");
                  }
          
                });
          
            }; const handleAction = async (id, id1, field, value) => {
              await axios.put(`https://suhba.server.com.ly/back/api/food_item_ingredients/${id}/${id1}`, {
                [field]: value,
              }).then(() => {
                setUpdatedIds(prev => [...prev, id1]);
                setTimeout(() => setUpdatedIds(prev => prev.filter(x => x !== id1)), 1000);
              }).catch(handleError);
            };
            const handleError = (error) => {
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
              console.log(error.config);
            };
    return(
        <>
            <Modal className="modal fade" id="exampleModal1" show={inviteModal}  centered>                
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1"> مكونات الاصناف </h1>
                        <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={()=> closemodel()}></button>
                    </div>
                           
                      <div className="modal-body py-1 px-3">
                 <form onSubmit={createintitem}>
                         
                        <div className="row">
                            <div className="col-xl-12">
                      <div style={{paddingRight :'10px',color:'red'}}> {error}</div>
                            <div className="row">
                            <div className="col-xl-6 ">
                                <label className="form-label mt-3">اسم الصنف</label>
                                 <select required className="default-select form-control" name="FoodItemID" value={foodItemint.FoodItemID} onChange={handleInputChange2}>
                                 <option  data-display="Select">اختر من القائمة</option>
                                 {
                                   foodItems.map((row)=>(
                                  <option key={row.ItemID} value={row.ItemID}>
                                            {row.Name}
                                    </option>
                                     ))}    
                                 </select> 
                                   </div>
                                   <div className="col-xl-6">
                                <label className="form-label mt-3">دالك لعدد</label>
                                <input required disabled={enable} type="text" className="form-control" name="ForHowMany" value={foodItemint.ForHowMany} onChange={handleInputChange2}                      />
                                
                                 </div>
                  </div>
                            <div className="row">
                            <div className="col-xl-6 ">
                                <label className="form-label mt-3">المكون</label>
                             
                                <select required className="default-select form-control" name="IngredientID" value={foodItemint.IngredientID} onChange={handleInputChange2}>
                                 <option value=""   data-display="Select">اختر من القائمة</option>
                                 {
                                   intdata.map((row)=>(
                                  <option key={row.IngredientID} value={row.IngredientID}>
                                            {row.IngName}
                                    </option>
                                     ))}    
                                 </select> 
                                   </div>
                                 <div className="col-xl-6">
                                        <label className="form-label mt-3 ">الكمبة</label>
                                        <div className="input-group">
                                         	
                                        <input required type="text" className="form-control" name="Quantity"value={foodItemint.Quantity} onChange={handleInputChange2}                  />
                                            
                                         </div>
                                    </div>
                                
                                </div>
                              


                                 <input type="text" hidden="true" name="UserID" value={userid} />
                                <input type="text"  id ="Hallid" hidden="true" name="EexpenseID" value={props.ExpenseId} />

                            </div>
                        </div>                                
                    



                    <div className="col-xl-12 pt-2 ">
                        <button type="submit" className="btn btn-primary btn-sm btn-block"
 >
                       
                        أضف</button>
 
                    </div> 
                     </form>
                     <div className="table-responsive active-projects task-table  pt-2" style={{  height: "250px" }}>
                                        <table className="table">
                                    <thead>
                                        <tr className="center" >
                                            <th scope="col">اسم المكون</th>
                                            <th scope="col">الكمية</th>
                                            <th scope="col">الوحدة</th>
                                            <th scope="col">حدف</th>

                                         </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            foodIteming.length > 0 && (
                                              foodIteming.map((row,key)=>(
                                                <tr key={key} className={updatedIds.includes(row.IngredientID) ? 'flash-row' : ''}>                                                        <td 
                        
                       
                      >
                      {row.IngredientName}
                      
                                                            
                                                            
                                                            
                                                             </td>
                                                            
                                                           
                                                                        <td  contentEditable
                        onBlur={(e) => handleAction(row.FoodItemID,row.IngredientID, 'Quantity', e.target.textContent)}
                      >
                      {row.Quantity}
                     
                                                            
                                                            
                                                            
                                                             </td>   
                                                             <td 
                        
                      >
                      {row.IngCatName} 
                      
                                                            
                                                            
                                                            
                                                             </td>   
                                                             <td>
                                <Link  onClick={() => handleDelete(row.FoodItemID,row.IngredientID)}>
    <i className="fas fa-trash-alt"></i>
</Link>
                                </td> 
                                                    </tr> 
                                                ))
                                            )
                                        }
         
                                    </tbody>
                                </table>
                                                </div>
        



</div>




                           
                                                  
                  
                   
                </div>               
            </Modal>
        </>
    )
})
export default InviteCustomer;