import React,{useState, useContext, useEffect} from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import {useStateContext} from "../../../context/ContextProvider";
import { useNavigate } from 'react-router-dom'
import axios from "axios";

import { IMAGES, SVGICON } from "../../constant/theme";
import { ThemeContext } from "../../../context/ThemeContext";
import axiosClient from "../../../axios-client";

const NotificationBlog =({classChange}) =>{
	return(
		<>
			<li>
				<div className="timeline-panel">
					<div className="media me-2">
						<img alt="images" width={50} src={IMAGES.Avatar} />
					</div>
					<div className="media-body">
						<h6 className="mb-1">Dr sultads Send you Photo</h6>
						<small className="d-block">29 July 2022 - 02:26 PM</small>
					</div>
				</div>
			</li>
			<li>
				<div className="timeline-panel">
					<div className={`media me-2 ${classChange}`}>KG</div>
					<div className="media-body">
						<h6 className="mb-1">Resport created successfully</h6>
						<small className="d-block">29 July 2022 - 02:26 PM</small>
					</div>
				</div>
			</li>
			<li>
				<div className="timeline-panel">
					<div className={`media me-2 ${classChange}`}><i className="fa fa-home" /></div>
					<div className="media-body">
						<h6 className="mb-1">Reminder : Treatment Time!</h6>
						<small className="d-block">29 July 2022 - 02:26 PM</small>
					</div>
				</div>
			</li>
		</>
	)
}

const Header = ({ onNote }) => {
	const { token, setUser, setToken ,user} = useStateContext();

	const [headerFix, setheaderFix] = useState(false);
	useEffect(() => {
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});
	}, []); 
	
	const {background, changeBackground } = useContext(ThemeContext);
	const handleThemeMode = () => {
		if(background.value === 'dark'){
			changeBackground({ value: "light", label: "Light" });
		}else{
			changeBackground({ value: "dark", label: "Dark" });
		}
	}
	const navigate = useNavigate();
	const userdata = localStorage.getItem('USER_data');
	const userData = JSON.parse(userdata);
	const username = userData.name;
  
	const onLogout = ev => {
		console.log("token",token)
		setToken(null)
		localStorage.removeItem('USER_PERMISSIONS2');

		ev.preventDefault()
		axios.post('https://suhba.server.com.ly/back/api/logout') .then(() => {
			setUser({})
		   setToken(null)
		   navigate('/login');            
			console.log(" aftrer token",token)
		}).catch(function (error) {
		  if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
		  } else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request);
		  } else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', error.message);
		  }
		  console.log(error.config);
		});
	  }
  return ( 
    <div className={`header ${headerFix ? "is-fixed" : ""} p-0`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          	<div className="collapse navbar-collapse justify-content-between">
				<div className="header-left">					
				</div>
				<div className="header-right d-flex align-items-center">
					
					<ul className="navbar-nav ">	
							
						<Dropdown as="li" className="nav-item dropdown notification_dropdown">
							<Dropdown.Toggle className="nav-link i-false c-pointer" variant="" as="a">
								{SVGICON.BellIcon}						
							</Dropdown.Toggle>
							<Dropdown.Menu align="end" className="mt-2 dropdown-menu dropdown-menu-end">
								<div className="widget-media dz-scroll p-3 height380">
									<ul className="timeline">
										<NotificationBlog classChange='media-info'/>
										<NotificationBlog classChange='media-success' />
										<NotificationBlog classChange='media-danger' />
										<NotificationBlog classChange='media-info' />
									</ul>
									<div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
										<div className="ps__thumb-x" tabIndex={0} style={{ left: 0, width: 0 }}/>
									</div>
									<div className="ps__rail-y" style={{ top: 0, right: 0 }}>
										<div className="ps__thumb-y" tabIndex={0} style={{ top: 0, height: 0 }}/>
									</div>
								</div>
								<Link className="all-notification" to="#">
									See all notifications <i className="ti-arrow-right" />
								</Link>
							</Dropdown.Menu>
						</Dropdown>	
							
					
						<li className="nav-item dropdown notification_dropdown ps-3">
							<Dropdown className="header-profile2">
								<Dropdown.Toggle className="nav-link i-false" as="div">
									<div className="header-info2 d-flex align-items-center">
										<div className="header-media" style={{verticalalign: 'middle'}}>
										{SVGICON.UserSvg}										</div>										
										
									</div>
								</Dropdown.Toggle>
								<Dropdown.Menu align="start">
									<div className="card border-0 mb-0">
										<div className="card-header py-2">
											<div className="products">
											{SVGICON.UserSvg}												
												<div>
													<h6>{username}</h6>
												</div>	
											</div>
										</div>
										<div className="card-body px-0 py-2">
											 
 											<button className="dropdown-item ai-icon ms-1 logout-btn" onClick={onLogout}>
              {SVGICON.Logout} {" "}
							  <span className="ms-2">تسجيل خروج </span>              
            </button>
											
											
										</div>
										
									</div>
									
								</Dropdown.Menu>
							</Dropdown>
						</li>						
					</ul>
				</div>
			
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
