import React, { Fragment } from "react";
import    { useState ,useEffect,useRef,forwardRef ,useImperativeHandle} from "react";
import { useLocation,useNavigate } from 'react-router-dom';
import logo from '../../jsx/components/Reports/food.jpg';
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { Modal } from 'react-bootstrap';

const Invoice = forwardRef((props, ref) => {
 
  


  const [orderItems, setOrderItems] = useState([]);
  const [TotalAmount, setTotalAmount] = useState(0);
  const [Discount, setDiscount] = useState(0);
  const [Discount1, setDiscount1] = useState(0);
  const [report, setreport] = useState([])
  const userdata1 = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata1);
    const userid = userData.id;
  const [order, setOrder] = useState({
    //  OrderID:'',
    CustomerID: '',
    OrderDate: '',
    TotalAmount: '',
    DiscountAmount: '',
    Status: '',
    UserID: userid,
    // DeliveryID: '',
    DeliveryPersonID: '',
    DeliveryDate: '',
    DeliveryTime: '',
    DelPrice: '',
    Address: '',
    GuaranteeTypesID: '',

  });
  const [toolItems, settoolItems] = useState([]);

  useEffect(() => {
     setOrderItems(props.data2);
    settoolItems(props.data3)
    setOrder(props.data)
    setreport(props.newdata)
 
    
    console.log('props.data', props.newdata);

  }, [props.newdata, setreport]);

     
   // const intervalId = setInterval(() => {
    //   setOrderDate(new Date());
   //  }, 1000); // Update every 1000 milliseconds (1 second)
 
     // Cleanup function to clear the interval when the component unmounts
   //  return () => clearInterval(intervalId);
   const componentRef = useRef();


  const [inviteModal, setInviteModal] = useState(false);
  useImperativeHandle(ref, () => ({
    showInviteModal() {
      setInviteModal(true);
    }
  }));
  
  const generatePDF = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Userdata",
    onAfterPrint: () => setInviteModal(false)
  });
  console.log(orderItems)
  return (
    <Fragment>
        <Modal className="fade bd-example-modal-lg " size="lg" id="exampleModal1"  show={inviteModal} onHide={() => setInviteModal(false)} centered>
        <div className="modal-content dlab-scroll">
        
             
              <div className="modal-body" ref={componentRef}  dir="rtl">
              <div className="row">
              <div className="col-xl-12" > 
                <center><img src={logo} alt="Logo" className="logo" style={{ height: "100px", width: "150px", textAlign: "center" }} /></center>
                <div className="shop-info">
                  <h2 style={{ textAlign: "center" }}>صحبة طيبة</h2>
                  <h5 style={{ textAlign: "center" }}>تقرير المطبخ</h5>

                  <h5 style={{ textAlign: "center" }}>  طلبيات بتاريخ : {report.length>0 && report[0].deldate} </h5>

                </div>
              </div>
            </div>
                 
                <div className="table-responsive">
                {/*<table className="table table-bordered verticle-middle table-responsive-sm" style={{ marginBottom:'0px',borderCollapse: "unset",padding:"0px", borderWidth: '1 px' }}>
                <tbody>
                <tr style={{width:'100%'}}>
                    <td style={{ boxShadow: '0 1px 1px 0 black',border: '1px solid #ccc' }}>الزبون  :{order.CustomerName}</td>
                    <td style={{ boxShadow: '0 1px 1px 0 black' , border: '1px solid #ccc' }}></td>

                  </tr>
                  <tr style={{width:'100%'}}>
                    <td style={{ boxShadow: '0 1px 1px 0 black' , border: '1px solid #ccc' }}>التاريخ:{order.DeliveryDate}</td>
                    <td style={{ boxShadow: '0 1px 1px 0 black',border: '1px solid #ccc' }}>توقيت الطلبية :{order.DeliveryTime}</td>
                  </tr></tbody>
  </table>*/}
                  <div className="table table-bordered verticle-middle table-responsive-sm" style={{marginBottom:'0px',marginTop:"1px" ,padding:"0px"}}>
                     
     {report.map((item, ind) => (
        <ul className="center"  key={ind}>        
<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: 'larger', border: '1px solid black',backgroundColor:"#dcecf0" }}>   
         <li className="center" style={{color:"black"}}>اسم الصنف:  </li>

            <li className="left strong" style={{color:"blue"}}>{item.name}</li>
            <li className="center" style={{color:"black"}}> الكمية:  </li>

            <li className="right" style={{color:"green"}}>{item.quantity}</li>
</div>
<table className=" invoice-table mb-1" style={{color:"black"}}>
                <thead>
                  <tr>
                    <th> ت.ر</th>
                    <th>اسم الزبون</th>
                    <th>وقت التسليم</th>
                    <th>الكمية</th>

                  </tr>
                </thead>
           
               
                <tbody>
                  {item.details.map((det, index) => (
                    <tr key={index}>
                      <td style={{ width: "30px" }}>{index+1}</td>
                      <td style={{ width: "300px" }}>{det.CustomerName}</td>
                      <td style={{ width: "60px" }}>{det.deltime}</td>
                      <td style={{ width: "60px" }}>{det.quantity}</td>

                    </tr>
                  ))}
                </tbody>
              </table>
               
               
               
               
               
               
               
               
               
               
               
        </ul>
    ))}
                   </div>
                </div>
                <div className="row">
                  <div className="col-lg-10 col-sm-5 mt-0 pt-0"> </div>
                  <div className="col-lg-2 col-sm-5 mt-0 pt-0 ">
                    <table className="table " style={{borderleft: '#E6E6E6 solid 1px',
  marginleft: "0px"}}>
                     
                    </table>
                  </div>
                </div>
              </div>
              </div>
         <div className="modal-footer">
            <button  className="btn btn-primary m-2" onClick={generatePDF}>طباعه التقرير</button>
          </div>  
          </Modal>
  
    </Fragment>
  );
});

export default Invoice;
