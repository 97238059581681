import React from 'react';

import InviteCustomer from '../jsx/constant/DepartmentModal';

const Departments = () => {
    return(
<>
<InviteCustomer/> 

</>





    )
//ref={invite}     
//Title="إضافة دورة"
}
export default Departments;