import React, { useState, forwardRef, useImperativeHandle  ,useEffect} from 'react';
import {Modal} from 'react-bootstrap';
 import { Dropdown } from 'react-bootstrap';
 import axios from "axios";
  import { useNavigate ,useParams} from "react-router-dom";
  import swal from 'sweetalert';
const InviteCustomer = forwardRef((props, ref) => {
    const [inviteModal , setInviteModal] = useState(false);
    const [TypeName,setTypeName] = useState('')
    const [data, setData] = useState([])
    const [status,setstatus] = useState(0)
    const [limitOrder,setlimitOrder] = useState('')
    const [OffDate,setOffDate] = useState('')

  const navigate = useNavigate();
   useImperativeHandle(ref, () => ({
      showInviteModal() {
          setInviteModal(true)
      }
  
    }))      ;
    const showInviteModal = () => {   
        
            setInviteModal(false)
            setTypeName('')
            setOffDate('')
            setlimitOrder('')
            setstatus(null)
        }
   ///////////////////////////////////////
   const userdata1 = localStorage.getItem('USER_data');
   const userData = JSON.parse(userdata1);
     const userid = userData.id;
   //////////////////////////////////
   
            // console.log(formData);

            
            ///////////////////////////////////////////////
      const getcities = () => {
        axios.get('https://suhba.server.com.ly/back/api/process_types')
         .then(({ data }) => {
            setData(data)
         })}

         const [currentPage , setCurrentPage] = useState(1);
         const recordsPage = 8;
         //const lastIndex = currentPage * recordsPage;
        // const firstIndex = lastIndex - recordsPage;   
        // const records = data.slice(firstIndex, lastIndex);
       //  const npage = Math.ceil(data.length / recordsPage)
     //    const number = [...Array(npage + 1).keys()].slice(1)
     
     
        
      useEffect(() => {
        getcities();
        
       
    }, [])
    const createEmployee = async(e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('TypeName', TypeName);
        formData.append('UserID', userid);
        formData.append('status', status);
        formData.append('OffDate', OffDate);
        formData.append('limitOrder', limitOrder);
        console.log('formData',formData)
        await axios.post('https://suhba.server.com.ly/back/api/process_types', formData)
        .then(res=>{
            if(res.data.status === 200)
            swal("عذرا",res.data.message,"warning");
        } ) 
          
      //  const myElement = document.getElementById('inputcity');
      
        //   myElement.value = '';
        

                props.parentFunction()

                props.show(false)
                showInviteModal()
       // getcities();
    } 
    const [message, setMessage] = useState(null)
    const handleRadioChange = () => {
      setstatus(1)
      
    };
    const handleRadioChange2 = () => {
      setstatus(0)

    };
	
    useEffect(() => {
      const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          const form = e.target.form;
          const fields = Array.from(form.elements).filter(el => (
            (el.tagName === 'INPUT' && !el.disabled) ||
            (el.tagName === 'TEXTAREA' && !el.disabled) ||
            (el.tagName === 'SELECT' && !el.disabled)
          ));      const currentFieldIndex = fields.findIndex(el => el === document.activeElement);
          const nextFieldIndex = currentFieldIndex + 1;
          if (nextFieldIndex < fields.length) {
            fields[nextFieldIndex].focus();
          }
        }
      };
      
      document.addEventListener('keydown', handleEnterKey);
      
      return () => {
        document.removeEventListener('keydown', handleEnterKey);
      };
    }, []);
		
    return(
        <>
            <Modal className="modal fade" id="exampleModal1" show={inviteModal}  centered>                
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">إدارة التواريخ</h1>
                        <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={()=>showInviteModal()}></button>
                    </div>
                    <div className="modal-body py-2 px-3 ">
                        <div className="row">
                            <div className="col-xl-12">
                            <form  onSubmit={createEmployee}>

                                <div className="row">
                                    <div className="col-xl-12">
                                    <div className="row">
                                        
         
                                    </div>
                                    <div className="row">
                                    <div className="col-xl-2 ps-0">
                                        <label className="form-label mt-3  ">التاريخ</label> 
                                         </div>
                                        <div className="col-xl-4">
                                        <div className="input-group ">
                                            <input style={{  height: "2rem" }} 
                                            type="date" 
                                            required  
                                            id="inputcity"
                                              name="date"
                                            className="form-control mt-3" 
                                              value={OffDate}
                                             onChange={(e)=>{setOffDate(e.target.value)}}/>
                                        </div>   </div>
                                        <div className="col-xl-2 ps-0">
                                        <label className="form-label mt-3 ">السبب</label> 
                                         </div>
                                        <div className="col-xl-4">
                                        <div className="input-group ">
                                            <input style={{  height: "2rem" }} 
                                            type="text" 
                                            required  
                                            id="inputcity"
                                              name="TypeName"
                                            className="form-control mt-3" 
                                              value={TypeName}
                                             onChange={(e)=>{setTypeName(e.target.value)}}/>
                                        </div>   </div>
                                        <div className="col-xl-2 ps-0">
                                        <label className="form-label mt-3  ">الحد الاعلى</label> 
                                         </div>
                                        <div className="col-xl-4">
                                        <div className="input-group ">
                                            <input style={{  height: "2rem" }} 
                                            type="text" 
                                            
                                            id="inputcity"
                                              name="city"
                                            className="form-control mt-3" 
                                              value={limitOrder}
                                             onChange={(e)=>{setlimitOrder(e.target.value)}}/>
                                        </div>   </div>
                                        <div className="col-xl-6">

                                        <fieldset className="form-group">
                              <div className="row mt-3">

                                <div className="col-sm-6 p-0">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="gridRadios"
                                      value="option1"
                                      onChange={handleRadioChange}
                                      
                                    />
                                    <label className="form-check-label">
                                      قفل اليوم
                                    </label>
                                  </div> </div>
                                <div className="col-sm-6 p-0">

                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="gridRadios"
                                      value="option2"

                                      onChange={handleRadioChange2} />

                                    <label className="form-check-label">
                                      الغاء القفل
                                    </label>
                                  </div>

                                </div>
                                
                              </div>
                            </fieldset>
                            </div>
                                          <div className="modal-footer mt-3 " style={{ paddingTop:"0.375rem"}}>
                                              <button  id="toastr-success-top-left" className="btn btn-primary mt-2 me-2 btn-icon-xs   btn-block">حفظ</button>
                                        </div>
                                       </div>  
                                    </div>
                                    
                                </div>

                                </form>
                           
                            </div>
                        </div>                                
                    </div>
                    
                </div>               
            </Modal>
        </>
    )
})
export default InviteCustomer;