import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table } from "react-bootstrap";

function YourComponent() {
  const [data, setData] = useState([]);
  const [todaydata, settodayData] = useState('');

  /*useEffect(() => {
    // Fetch data from the API
    axios.get("https://suhba.server.com.ly/back/api/getTotalAmountbyday")
      .then(response => {
        const itemMap = {};
        response.data.forEach(item => {
          if (itemMap[item.deposit_date]) {
            if(item.deposits === 'عربون') {
              itemMap[item.deposit_date].totalarboon += parseFloat(item.total_amount);
            } else {
              itemMap[item.deposit_date].total = parseFloat(item.total_amount) ;
            }
          } else {
            itemMap[item.deposit_date] = {
              Itemdate: item.deposit_date,
              total: item.deposits === 'عربون' ? 0 : parseFloat(item.total_amount) ,
              totalarboon: item.deposits === 'عربون' ? parseFloat(item.total_amount) : 0,
            };
          }
        });
        setData(Object.values(itemMap)); // Convert object values to array
      })
      .catch(error => {
        console.error("Error fetching outcome data:", error);
      });
  }, []);
  useEffect(() => {
    axios.get("https://suhba.server.com.ly/back/api/getTotalAmountbyday")
      .then(response => {
        const totalByDay = response.data.reduce((accumulator, item) => {
          const { deposit_date, deposits, total_amount } = item;
          const isArboon = deposits === 'عربون';
          const existingDay = accumulator.find(entry => entry.Itemdate === deposit_date);
          
          if (existingDay) {
            if (isArboon) {
              existingDay.totalarboon += parseFloat(total_amount);
            } else {
              existingDay.total += parseFloat(total_amount);
            }
          } else {
            accumulator.push({
              Itemdate: deposit_date,
              total: isArboon ? 0 : parseFloat(total_amount),
              totalarboon: isArboon ? parseFloat(total_amount) : 0,
            });
          }
          return accumulator;
          
        }, []);
        
        setData(totalByDay);
      })
      .catch(error => {
        console.error("Error fetching outcome data:", error);
      });
  }, []);
  */
  useEffect(() => {
    const today = new Date().toISOString().split('T')[0]; // Get current date in "YYYY-MM-DD" format
    
    axios.get("https://suhba.server.com.ly/back/api/getTotalAmountbyday")
      .then(response => {
        const totalByDay = response.data.reduce((accumulator, item) => {
          const { deposit_date, deposits, total_amount } = item;
          const isArboon = deposits === 'عربون';
          const existingDay = accumulator.find(entry => entry.Itemdate === deposit_date);
          
          if (existingDay) {
            if (isArboon) {
              existingDay.totalarboon += parseFloat(total_amount);
            } else {
              existingDay.total += parseFloat(total_amount);
            }
          } else {
            accumulator.push({
              Itemdate: deposit_date,
              total: isArboon ? 0 : parseFloat(total_amount),
              totalarboon: isArboon ? parseFloat(total_amount) : 0,
            });
          }
          return accumulator;
          
        }, []);
        
        setData(totalByDay);
        
        // Filter data for today's date
        const todayData = totalByDay.filter(item => item.Itemdate === today);
        settodayData(todayData)
        console.log("Data for today:", todayData);
      })
      .catch(error => {
        console.error("Error fetching outcome data:", error);
      });
  }, []);
  
  console.log(data)

  return (
    <div> 
      <div className="row mb-3" style={{display :'flex'}}>
      <div className="col-md-4" style={{display :'flex'}}>
          <label htmlFor="startMonth" style={{fontsize: '15px', fontWeight: 'bold'}}> اجمالي الدخل لليوم :</label>
       <div style={{color: 'blue'}}> 
       <strong>{todaydata.length > 0 ? todaydata[0].total : 0}</strong>  
        </div>  </div>
        <div className="col-md-4" style={{display :'flex'}}>
          <label style={{fontsize: '15px', fontWeight: 'bold'}} htmlFor="startMonth"> اجمالي العرابين لليوم :</label>
          <div style={{color: 'red'}}>
          <strong>{todaydata.length > 0 ? todaydata[0].totalarboon : 0}</strong>            
        </div> </div>
        <div className="col-md-4" style={{display :'flex'}}>
          <label style={{fontsize: '15px', fontWeight: 'bold'}} htmlFor="startMonth">  صافي الدخل لليوم :</label>
          <div style={{color: 'green'}}>
          <strong>{todaydata.length > 0 ? todaydata[0].total - todaydata[0].totalarboon : 0}
          </strong>              
          
            </div> 
      </div>
      </div>
      <Table responsive striped bordered className="verticle-middle">

      
        <thead>
          <tr>
            <th>التاريخ</th>
            <th>اجمالي  الدخل</th>
            <th>اجمالي العرابين</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.Itemdate}</td>
               <td>{item.total + item.totalarboon}</td>

               <td>{item.totalarboon}</td>
            </tr>
          ))}
        </tbody>
        </Table>
    </div>
  );
}

export default YourComponent;
