import {Link} from "react-router-dom";
 import {createRef} from "react";
import {useStateContext} from "../context/ContextProvider.js";
import { useState } from "react";
import axios from "axios"; 

export default function Login() {
  const emailRef = createRef()
  const passwordRef = createRef()
  const { setUser, setToken,setPermissions,getPermissions } = useStateContext()
  const [message, setMessage] = useState(null)
  const [per, setper] = useState([])

  const onSubmit = ev => {
    ev.preventDefault()

    const payload = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    }
    console.log(payload)

    axios.post('https://suhba.server.com.ly/back/api/login', payload)
      .then(({data}) => {
        setUser(data.user)
        setPermissions(data.permissions)
        const permissionsJSON = JSON.stringify(data.permissions);

        // Store permissions in local storage
        localStorage.setItem('USER_PERMISSIONS1', permissionsJSON);
        console.log(data.user)
       // getPermissions();
      })
      .catch((err) => {
        const response = err.response;
        if (response && response.status === 422) {
          setMessage(response.data.message)
        }
      })
  }
  const buttons = [
    { label: 'show', resource: 'Income', action: 'Show' },
    { label: 'add', resource: 'Al-Asnaf', action: 'Add' },
    { label: 'Delete', resource: 'Al-Asnaf', action: 'Delete' }

  ];
  const permissions = useStateContext();

   
/*  return (
    <div className="login-signup-form animated fadeInDown">
      <div className="form">
        <form onSubmit={onSubmit}>
          <h1 className="title">Login into your account</h1>

          {message &&
            <div className="alert">
              <p>{message}</p>
            </div>
          }

          <input ref={emailRef} type="text" placeholder="Email"/>
          <input ref={passwordRef} type="password" placeholder="Password"/>
          <button className="btn btn-block">Login</button>
          <p className="message">Not registered? <Link to="/signup">Create an account</Link></p>
        </form>
        <form>
      {buttons.map(button => (
    // Check if permissions is not null or undefined and if user has permission for the action on the resource
    permissions && permissions.length > 0 && permissions.some(p => p.resource === button.resource && p.action.includes(button.action)) && (
        <button key={button.label} type="submit">{button.label}</button>
    )
))}

    </form>
      </div>
    </div>
  )*/
  return (
    <div className="login-signup-form animated fadeInDown">
      <div className="form">
        <form onSubmit={onSubmit}>
          <h1 className="title">Login into your account</h1>

          {message &&
            <div className="alert">
              <p>{message}</p>
            </div>
          }

          <input ref={emailRef} type="email" placeholder="Email"/>
          <input ref={passwordRef} type="password" placeholder="Password"/>
          <button className="btn btn-block">Login</button>
          <p className="message">Not registered? <Link to="/signup">Create an account</Link></p>
        </form>
      </div>
    </div>
  )
}

