import React from 'react';

import InviteCustomer from '../jsx/constant/GuaranteeTypeModal';

const GuaranteeType = () => {
    return(
<>
<InviteCustomer/> 

</>





    )
//ref={invite}     
//Title="إضافة دورة"
}
export default GuaranteeType;