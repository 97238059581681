import React from "react";
import loadable from "@loadable/component";
// import { Link } from 'react-router-dom';
import { Row, Col, Card } from "react-bootstrap";

import pMinDelay from "p-min-delay";

import PageTitle from "../../jsx/layouts/PageTitle";

const ApexBar2 = loadable(() => pMinDelay(import("./First copy 3"), 1000));
const ApexBar3 = loadable(() => pMinDelay(import("./First copy 2"), 1000));
const ApexBar4 = loadable(() => pMinDelay(import("./First copy 4"), 1000));

const ApexPie = loadable(() => pMinDelay(import("./First"), 1000));

function ApexChart() {
  return ( 
      <div className="container-fluid">
        <Row>
          <Col xl={6} lg={6}>
            <Card>
              <Card.Header>
                <h4 className="card-title">إحصائية دخل الشركة</h4>
              </Card.Header>
              <Card.Body>
                <ApexBar2 />
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6} lg={6}>
            <Card>
              <Card.Header>
                <h4 className="card-title">إحصائية مصروفات الشركة</h4>
              </Card.Header>
              <Card.Body>
                <ApexBar4 />
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6} lg={6}>
            <Card>
              <Card.Header>
                <h4 className="card-title">إحصائية دخل ومصروفات الشركة</h4>
              </Card.Header>
              <Card.Body>
                <ApexBar3 />
              </Card.Body>
            </Card>
          </Col>

          
        
          
         {/* <Col xl={6} lg={6}>
            <Card>
              <Card.Header>
                <h4 className="card-title">أكثر خمس اصناف مبيعا</h4>
              </Card.Header>
              <Card.Body className="mt-5">
                <ApexPie />
              </Card.Body>
            </Card>
          </Col>
         */}
        </Row>
      </div>
  );
}

export default ApexChart;
