import React, { useState, forwardRef, useImperativeHandle  ,useEffect} from 'react';
import {Fade, Modal} from 'react-bootstrap';
 import { Dropdown } from 'react-bootstrap';
 import axios from "axios";
  import { useNavigate ,useParams} from "react-router-dom";
  import swal from 'sweetalert';
  import {useStateContext} from "../../context/ContextProvider"

const InviteCustomer = forwardRef((props, ref) => {
    const [inviteModal , setInviteModal] = useState(false);
    const [FoodName,setFoodName] = useState('')
    const [data, setData] = useState([])
   const navigate = useNavigate();
   useImperativeHandle(ref, () => ({
    showInviteModal() {
        setInviteModal(true)
    }

  }))      ;
  
    ///////////////////////////////////////
  
   //////////////////////////////////
    const [tableData, setTableData] = useState([]);
    ///////////////handleEdit///////////////////
    const handleEdit = async (idd, field, value) => {
                const updatedData = tableData.map((row) =>
              row.id === idd ? { ...row, [field]: value } : row
             );
             setTableData(updatedData);
             const formData = new FormData();
             formData.append('_method', 'PATCH')
            formData.append('FoodCategoryID', idd);
             formData.append('FoodName', value);
             formData.append('UserID', userid);
            const error = await axios.put(`https://suhba.server.com.ly/back/api/food_categories/${idd}`, {
            [field]: value,  });
                if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('Error', error.message);
                }
                console.log(error.config);
                
             } 
            // console.log(formData);

            
            /////////////////////getcities//////////////////////////
      const getcities = () => {
        axios.get('https://suhba.server.com.ly/back/api/food_categories')
         .then(({ data }) => {
            setData(data)
         })}

         const [currentPage , setCurrentPage] = useState(1);
         const recordsPage = 8;
         const input = document.querySelector('input[name="city"]');
     if(input)
      {
     input.addEventListener('invalid', function (event) {
       if (event.target.validity.valueMissing) {
         event.target.setCustomValidity('الرجاء ادخال اسم  المجموعة ');
       } 
     })
     
     input.addEventListener('change', function (event) {
       event.target.setCustomValidity('');
     })}
        
     
        
      useEffect(() => {
        getcities();
        
        const myElement = document.getElementById('myElementId');
        if(myElement){

        if (selectedItem  === 'متوفر') {
          myElement.className = 'Complete';
        }else{
            myElement.className = 'Pending';
        }}               

    }, [])
    //fa-bowl-rice
    const createEmployee = async(e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('FoodName', FoodName);
        formData.append('UserID', userid);
        formData.append('status', "متوفر");
                formData.append('Description', "fa-bowl-rice");

        await axios.post('https://suhba.server.com.ly/back/api/food_categories', formData)
        .then(res=>{
            if(res.data.status === 200)
            swal("عذرا",res.data.message,"warning");
        } ) 
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
          
      //  const myElement = document.getElementById('inputcity');
      
        //   myElement.value = '';
          console.log(FoodName)
           setFoodName('')
        getcities();
    } 
   
    const [message, setMessage] = useState(null)

    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;   
    const records = data.slice(firstIndex, lastIndex);
    const npage = Math.ceil(data.length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)
 	
    const [statusPriority, setStatusPriority] = useState([]);
    const userdata = localStorage.getItem('USER_data');
    const userData = JSON.parse(userdata);
    const userid = userData.id;
           
    const handleAction =  async(id, field, value)	=> {
        		let temp = statusPriority.map((data) => {
			if (id === data.id) {
				return { ...data, status: value };
			}
			return data;
		});
		setStatusPriority(temp);
        const formData = new FormData();
        formData.append('_method', 'PATCH')
       formData.append('FoodCategoryID', id);
        formData.append('status', value);
        formData.append('UserID', userid);
        console.log('formdata',formData)
    const error = await axios.put(`https://suhba.server.com.ly/back/api/food_categories/${id}`, {
       [field]: value,  });
           if (error.response) {
             console.log(error.response.data);
             console.log(error.response.status);
             console.log(error.response.headers);
           } else if (error.request) {
             console.log(error.request);
           } else {
             console.log('Error', error.message);
           }
           console.log(error.config);
          } 
          const [selectedItem, setSelectedItem] = useState();

          const handleSelect = (eventKey, rowIndex) => {
            const updatedData = [...data];
            updatedData[rowIndex].status = eventKey;
            setSelectedItem(eventKey) 
            console.log(eventKey)

                       setData(updatedData);console.log('row',rowIndex);
          };
    return(
        <>
            <Modal className="modal fade" id="exampleModal1" show={inviteModal}  centered>                
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">أسماء مجموعات الاصناف </h1>
                        <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={()=>setInviteModal(false)}></button>
                    </div>
                    <div className="modal-body py-2 px-3 ">
                        <div className="row">
                            <div className="col-xl-12">
                            <form  onSubmit={createEmployee}>

                                <div className="row">
                                    <div className="col-xl-12">
                                    <div className="row">
                                        
         
                                    </div>
                                    <div className="row">
                                    <div className="col-xl-3">
                                        <label className="form-label mt-3 col-xl-3">الاسم</label> 
                                         </div>
                                        <div className="col-xl-6">
                                        <div className="input-group ">
                                            <input style={{  height: "2rem" }} 
                                            type="text" 
                                            required  
                                            id="inputcity"
                                              name="city"
                                            className="form-control mt-3" 
                                              value={FoodName}
                                             onChange={(e)=>{setFoodName(e.target.value)}}/>
                                        </div>   </div>

                                         
                                          <div className="col-xl-3 pe-0" style={{ paddingTop:"0.375rem"}}>
                                              <button  id="toastr-success-top-left" className="btn btn-primary mt-2 me-2 btn-icon-xs">+</button>
                                        </div>
                                       </div>  
                                    </div>
                                    
                                </div>

                                </form>
                                <div className="table-responsive active-projects task-table my-2 mx-2" style={{  height: "400px" }}>
                                <table className="table">
                            <thead>
                                <tr> 
                                    <th scope="col">اسم المجموعة</th>
                                    <th scope="col">الحالة</th>
                                 </tr>
                            </thead>
                            <tbody>
                                {
                                    data.length > 0 && (
                                        data.map((row,key)=>(
                                            <tr key={key}> 
                                                
                                                <td  
                contentEditable
                 onBlur={(e) => handleEdit(row.FoodCategoryID, 'FoodName', e.target.textContent)}
              >
              {row.FoodName}
              
                                                    
                                                    
                                                    
                                                     </td>
                                                     <td>     
                                                     <Dropdown  className="task-dropdown-2" onSelect={(eventKey, event) => handleSelect(eventKey, key)}>
                                                                            <div>
                                                        { row.status ==="متوفر" ?(
                                                       <Dropdown.Toggle  id="myElementId" as="div" className="Complete">
                                                       {row.status ? ` ${row.status}` : 'Select an option'}</Dropdown.Toggle>

                                                               ) : (

                                                                <Dropdown.Toggle id="myElementId" as="div" className="Pending">
                                                                {row.status ? ` ${row.status}`: 'Select an option'}</Dropdown.Toggle>

                                                            ) }
                                                            </div>
																
                                                                
                                                                <Dropdown.Menu className='task-drop-menu'>
																	<Dropdown.Item eventKey="غير متوفر" onClick={()=>handleAction(row.FoodCategoryID,'status','غير متوفر')}>غير متوفر</Dropdown.Item>
																	<Dropdown.Item eventKey="متوفر" onClick={()=>handleAction(row.FoodCategoryID,'status','متوفر')}>متوفر</Dropdown.Item>
 
																</Dropdown.Menu>
															</Dropdown>
                                                            		</td>
                                            </tr> 
                                        ))
                                    )
                                }
 
                            </tbody>
                        </table>
                                        </div>

                            </div>
                        </div>                                
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger light" onClick={()=>setInviteModal(false)}>إنهاء</button>
                     </div>
                </div>               
            </Modal>
        </>
    )
})
export default InviteCustomer;