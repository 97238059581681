import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link,  useNavigate } from 'react-router-dom'
import { loadingToggleAction,loginAction,
} from '../../store/actions/AuthActions';
 import axiosClient from "../../axios-client";
import {createRef} from "react";
import {useStateContext} from "../../context/ContextProvider";
import { useState } from "react";
import swal from 'sweetalert';
import axios from "axios";

import rainbow from '../../images/fekra22.png';
import rainbow1 from '../../images/food-Recovered.png';

/*function Login (props) {
    const [heartActive, setHeartActive] = useState(true);
    
    const navigate = useNavigate();
    const [email, setemail] = useState('demo@example.com');
    let errorsObj = { email: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setpassword] = useState('123456');
    const dispatch = useDispatch();

    function onLogin(e) {
        
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (email === '') {
            errorObj.email = 'email is Required';
            error = true;
        }
        if (password === '') {
            errorObj.password = 'password is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return ;
        }       
        console.log(email, password, navigate)
        dispatch(loadingToggleAction(true));
        dispatch(loginAction(email, password, navigate));
    }*/
    export default  function Login() {
        const navigate = useNavigate();
        const emailRef = createRef()
        const passwordRef = createRef()
        const { setUser, setToken,setPermissions,getPermissions ,setId } = useStateContext()
        const [message, setMessage] = useState(null)
      //====================================
 
     let errorsObj = { emailRef: '', passwordRef: '' };
    const [errors, setErrors] = useState(errorsObj);
    //=============================
    const onSubmit = ev => {
        ev.preventDefault()
    
        const payload = {
          email: emailRef.current.value,
          password: passwordRef.current.value,
        }
        console.log(payload)
    
        axios.post('https://suhba.server.com.ly/back/api/login', payload)
          .then(({data}) => {
            setUser(data.user)
            setPermissions(data.permissions)
            setToken(data.token);
            setId(data.user.id)
            const permissionsJSON = JSON.stringify(data.permissions);
            const userdata = JSON.stringify(data.user);
            localStorage.setItem('USER_data', userdata);

            // Store permissions in local storage
            localStorage.setItem('USER_PERMISSIONS2', permissionsJSON);
            console.log(data.user)
           // getPermissions();
           navigate('/Dashboard_1'); 

          })
          .catch((err) => {
            const response = err.response;
            if (response && response.status === 422) {
              setMessage(response.data.message)
            }
          })
      }
    const onSubmit1 = ev => {
        ev.preventDefault()
    
        const payload = {
          email: emailRef.current.value,
          password: passwordRef.current.value,
        }
        let error = false;
        const errorObj = { ...errorsObj };
        if (payload.email === '') {
            errorObj.email = 'email is Required';
            error = true;
        }
        if (payload.password === '') {
            errorObj.password = 'password is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) { 
                        return ;
        }                 

        axiosClient.post('/login', payload)
          .then(({data}) => {
            setUser(data.user)
            setToken(data.token);
            navigate('/Dashboard_1'); 

          })
          .catch((error) => {
            const response = error.response;
            if (response ) {
              setMessage(response.data.message)
           console.log(response.data);    }})  
      }

    
       
      return (        
        <div className="page-wraper">           
            <div className="login-account">
                <div className="row h-100">
                   
                    <div className="col-lg-6 col-md-7 col-sm-12 mx-auto align-self-center">
                        <div className="login-form">
                             
                             <div className="author-media">
                          <center><img width="300px" src={`${rainbow1}`} /></center>
                          
                        </div>
                            <h6 className="login-title"><span>تسجيل الدخول </span></h6>
                                
							
                                   {message &&
                                    <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                                     <p>{message}</p>
                                       </div>  
                                    }
                               
                                  
                            <form  onSubmit={onSubmit}>                             
                                <div className="mb-4" style={{direction:'rtl'}}>                                                              
                                    <label className="mb-1 text-dark">البريد الالكتروني</label>
                                    <input ref={emailRef} type="email"  className="form-control form-control-lg" placeholder="الرجاء ادخال البريد الالكتروني"/>
									{errors.email && <div className="text-danger fs-12">{errors.email}</div>}

                                     
                                </div>
                                <div className="mb-4" style={{direction:'rtl'}}>                          
                                    <label className="mb-1 text-dark">كلمة المرور</label>
                                    <input className="form-control form-control-lg" ref={passwordRef} type="password" placeholder="الرجاء ادخال كلمة المرور"/>                              
									{errors.password && <div className="text-danger fs-12">{errors.password}</div>}

                                </div>
                                <div className="text-center mb-4">
                                    <button type="submit" className="btn btn-primary btn-block"style={{fontSize:'18px'}}>دخول</button>
                                </div>
                                                             
                            </form>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>            
    )



    }
    
   

