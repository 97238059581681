import React, { useState, forwardRef, useEffect,useImperativeHandle  } from 'react';
import {Modal} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import axios from "axios";
 import {useNavigate, useParams} from "react-router-dom";

const InviteCustomer = forwardRef((props, ref) => {
   const navigate = useNavigate();
    const [data1 , setData1] = useState([]);
      const showInviteModal = () => {   
        setshowhide(true)

         props.show(false)   
        setUser('')
        }
        const [showhide ,setshowhide] = useState(true)
 const [user, setUser] = useState([])
 useEffect(() => {
  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const form = e.target.form;
      const fields = Array.from(form.elements).filter(el => (
        (el.tagName === 'INPUT' && !el.disabled) ||
        (el.tagName === 'TEXTAREA' && !el.disabled) ||
        (el.tagName === 'SELECT' && !el.disabled)
      ));      const currentFieldIndex = fields.findIndex(el => el === document.activeElement);
      const nextFieldIndex = currentFieldIndex + 1;
      if (nextFieldIndex < fields.length) {
        fields[nextFieldIndex].focus();
      }
    }
  };
  
  document.addEventListener('keydown', handleEnterKey);
  
  return () => {
    document.removeEventListener('keydown', handleEnterKey);
  };
}, []);
const [errors, setErrors] = useState('')

   //------------------------
// const [user, setUser] = useState({
//          Amount: '',
//         ExpenseDate: '',
//         ExpenseTypeID: '',
//         EmployeeID: '',
//         CompanyID: '',
//         UserID: '1',
//         FirstName : '',
//   })
console.log('user',showhide);

         useEffect(() => {
            getexpensis();

                setUser(props.data)
             if(user.EmployeeID == null)
             {
              setshowhide(true)
             }else{
              setshowhide(false)

             }
            }, [props.data, setUser]);
            console.log('user',showhide);

 //--------------------------------------------------
 
 const onSubmit = ev => {
    ev.preventDefault()
    console.log('userid',user.ExpenseID);

      axios.put(`https://suhba.server.com.ly/back/api/expenses/${user.ExpenseID}`, user)
        .then(() => {
            setUser('')
            props.parentFunction()

            props.show(false) 
        })
        .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
          });
         
  }
console.log('uder',user)

  const getexpensis = () => {
    axios.get('https://suhba.server.com.ly/back/api/fillexpeses')
     .then(({ data }) => {
        setData1(data.ExpenseType)
     })
     .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
     
     } 
     const handleInputChange1 = (e) => {
      setErrors('')
      const { name, value } = e.target;
    
         if (/^\d*$/.test(value)) {
          setUser({...user, Amount: value})
    
        }else {
          setErrors("هده الخانه لاتقبل الا الاعداد الصحيحة")
    
      
      
      }
    
    };
 //---------------------------------------------
    return(
        <>
         <div>

         </div>
             <Modal className="modal fade" id="exampleModal1"   show={props.inviteModal}  centered> 
            
        
                <div className="modal-content">
                <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">تعديل بياتات المصروفات</h1>
                        <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={()=>showInviteModal()}></button>
                    </div>
                          <form onSubmit={onSubmit}>
                          <div className="modal-body">
                        <div className="row">
                            <div className="col-xl-12">
                      

                            <div className="row">
                            <div className="col-xl-12">
                            <label hidden={showhide} className="form-label "> اسم الموظف<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <input hidden={showhide} type="text"className="form-control" disabled="true" name="EmployeeID" defaultValue={user.FirstName} 
                                             
                                            />
                                             

                                        </div>
</div>
                                    <div className="col-xl-6">
                                        <label className="form-label ">تاريخ الصرف<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <input type="date"className="form-control" name="ExpenseDate" defaultValue={user.ExpenseDate} 
                                            onChange={ev => setUser({...user, ExpenseDate: ev.target.value})} 
                                            />
                                             

                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <label className="form-label ">القيمة<span className="text-danger">*</span></label>
                                        <div className="input-group">
 
                                        <input type="text" className="form-control" name="Amount" defaultValue={user.Amount}
                                        onChange={handleInputChange1} />
                                            
                                         </div>
                                         {errors ? <div className=' alert alert-danger'>{errors}</div> : ""}

                                    </div>
                                </div>
                                <div className="col-xl-12">
                                <label className="form-label mt-3">دالك لــ<span className="text-danger">*</span></label>
                             
                                <select className="default-select form-control"
                              name="ExpenseTypeID"
                                 value={user.ExpenseTypeID}
                            onChange={(ev) => setUser({ ...user, ExpenseTypeID: ev.target.value })}
>
                           <option value="" data-display="Select">
                             {user.ExpenseTypeID}
                                             </option>
                        {data1.length > 0 &&
                    data1.map((row) => (
                     <option key={row.ExpenseTypeID} value={row.ExpenseTypeID}>
                   {row.TypeName}
               </option>
              ))}
               </select>
                                
                                
                                
                                
                                
                                
                                 </div>


                                 <input type="text" hidden="true" name="UserID" value="1" />
                                <input type="text"  id ="Hallid" hidden="true" name="EexpenseID" defaultValue={props.ExpenseId} />

                            </div>
                        </div>                                
                    </div>



                    <div className="modal-footer">
                        <button type="submit" className="btn btn-primary" >
                       
                        حفظ</button>
                        <button type="button" className="btn btn-danger light" onClick={()=>showInviteModal()}>إلغاء</button>

                    </div> 
                     </form>
                </div>               
            </Modal>
        </>
    )
})
export default InviteCustomer;