import React,{ useRef,useMemo,forwardRef } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import MOCK_DATA from '../jsx/components/table/FilteringTable/MOCK_DATA_2.json';
import { COLUMNS } from '../jsx/components/table/FilteringTable/ColumnsGarantee';
 import { Link,useNavigate } from "react-router-dom";
 import axios from "axios";
 import swal from 'sweetalert'; 
import '../jsx/components/table/FilteringTable/filtering.css';
import { Col, Row ,Dropdown} from 'react-bootstrap';
import InviteCustomer1 from '../../src/components/SanadSaref';
import InviteCustomer from '../jsx/constant/EditWeddingHalls';
import { CSVLink } from 'react-csv';
import InviteCustomer31 from '../../src/components/Sanadqabd';

import {useEffect, useState} from "react";

  const WeddingHall = forwardRef((props,ref) => {
	const columns = useMemo( () => COLUMNS, [] )
   const [data, setData] = useState([])
   const navigate = useNavigate();
   const [data1, setData1] = useState([])

   const [ID, setID] = useState(0)
   const [inviteModal , setInviteModal] = useState(false);
   const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');
 const userPermissions = JSON.parse(userPermissionsJSON);
 function getActionsForPage(pageName) {
   const pagePermissions = userPermissions.find(permission => permission.resource === pageName);
console.log('actionsForPage',pagePermissions); 
  if (pagePermissions) {
      return pagePermissions.action;
  } else {
 navigate('/dashboard_1')
     return [];
  }
}
const pageName = 'Garantees';
const actionsForPage = getActionsForPage(pageName);

// Usage

  useEffect(() => {
    //getActionsForPage(pageName)
       fetchProducts();
  }, [])
const show =(value)=>
{
setInviteModal(value)

}

const [report, setReport] = useState([]);

  const fetchProducts = async () => {
      await axios.get('https://suhba.server.com.ly/back/api/deposits').then(({ data }) => {setData(data)}) 

      /*catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });*/
  }
  const userdata1 = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata1);
    const userid = userData.id;
  const headersTitle = [
  // HallID 	HallName 	HallAddress 	HallContactNumber 	ItemPrice 	UserID 	created_at 	updated_at 	PreName 	
    {label:'HallName', key:'HallName'}, 
    {label:'HallAddress', key:'HallAddress'}, 
    {label:'HallContactNumber', key:'HallContactNumber'}, 
    {label:'ItemPrice', key:'ItemPrice'}, 
	{label:'PreName', key:'PreName'},
	 
]
  const csvlink = {
    headers : headersTitle,
    data : data,
    filename: "csvfile.csv"
  }
 const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0}
	}, useFilters, useGlobalFilter, usePagination)
	
  const userdata = useRef();
  const invite = useRef();
  const invite1 = useRef();
	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance
    const {globalFilter, pageIndex} = state	
    //const navigate = useNavigate();
    const [hallData, setHallData] = useState({
      HallID: null,
    HallName: '',
    HallAddress: '',
    HallContactNumber: '',
    ItemPrice: '',
    UserID: userid,
    PreName: '',
  });
  const EditData556 = async (id) => {
    // console.log('userid',user.ExpenseID);
    data.map((item) => {
      if (id === item.OrderID) {
        setData1(item)
     console.log('itemitemitemitemitem',item) 
      
      }})

   axios.get(`https://suhba.server.com.ly/back/api/getreceiptexpenses/${id}/${userid}`)  
                     .then(({data}) => {
            //props.parentFunction()
            if (data.Receiptexpenses)
           { setReport(data.Receiptexpenses)
           
            invite1.current.showInviteModal()
console.log('gggggg',data.Receiptexpenses)               
           }else{
            swal("لا يوجد إيصال استلام الضمان");
            props.show(false) 
           }
})
        .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
          });
         
  }
  const invite31 = useRef();

  const EditData55 = async (id) => {
    // console.log('userid',user.ExpenseID);
    data.map((item) => {
      if (id === item.OrderID) {
        setData1(item)}})
    axios.get(`https://suhba.server.com.ly/back/api/getdipositisal/${id}`)  
                     .then(({data}) => {
            //props.parentFunction()
            if (data.Receiptexpenses)
           { setReport(data.Receiptexpenses)
  
            invite31.current.showInviteModal()
            console.log('gggggg',data.Receiptexpenses)               
           }else{
            swal("لا يوجد إيصال استلام الضمان");
            props.show(false) 
           }
})
        .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
          });
         
  }
 console.log('ID',ID)
 console.log('hal',hallData);
 
    const deleteUser = async (e,id)=> {
        e.preventDefault();
        swal({
          title: "هل متأكد من الحدف",
          text: " في حال حدف الملف لايمكنك استعادة هدا الملف",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then(willDelete => {
          if (willDelete) {
    
            axios.delete('https://suhba.server.com.ly/back/api/products/' + id)
            
              .then(res => {
                swal({
                  title: "تم",
                  text: "تم الحدف بنجاح",
                  icon: "success",
                  timer: 2000,
                  button: false
                })
                navigate('#')
            
               
            });
        
          } else {
            swal("تم الغاء عملية الحدف");
          }
        
        });
    
    };
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
          </g>
        </svg>
    )
    return(
<>
<div className='container-fluid'>
				<Row>
					<Col lg="12">
						<div className="card">
            <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">   

                 <div className="tbl-caption">
                 <h4 className="heading ms-3">الضمان</h4>
               
                        </div>  
                        </div>   </div> 
                       

                        
                        		 
								<div className="table-responsive">
									<table {...getTableProps()} className="table dataTable display table-hover">
										<thead>
										{headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Check if the column accessor is 'age' and render accordingly
                        column.id !== 'OrderID' ? (
                          <th {...column.getHeaderProps()}>
                            {column.render('Header')}
                            {column.canFilter ? column.render('Filter') : null}
                          </th>
                        ) : null
                      ))}
                        <th style={{ paddingBottom: "4.5rem" }}>إيصال قبض  </th>
                       <th style={{ paddingBottom: "4.5rem" }}>إيصال استلام </th>

                     </tr>
										
										))}
										</thead> 
                    <tbody {...getTableBodyProps()}>
  {page.map((row) => {
    prepareRow(row);
    return (
      <tr {...row.getRowProps()}>
        {row.cells.map((cell) => (
          // Check if the column accessor is 'age' and render accordingly
          cell.column.id !== 'OrderID' ? (
            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
          ) : null
        ))}
    

  

   

    <td> <Link   dir="rtl" className=""
                                    to={"#"}
                                    onClick={(e) => EditData55(row.original.OrderID)}
                                   > <i class="fa-solid fa-file-lines fa-2x"></i>
                                  </Link>   </td>
  <td> <Link   dir="rtl" className=""
                                    to={"#"}
                                    onClick={(e) => EditData556(row.original.OrderID)}
                                   > <i class="fa-solid fa-file-lines fa-2x"></i>
                                  </Link>   </td>
                 
                                                </tr>
                                            )
                                        })}
                                    </tbody>
									</table>



									<div className="d-flex justify-content-between mx-2">
										<span>
											الصفحه{' '}
											<strong>
												{pageIndex + 1} من {pageOptions.length}
											</strong>{''}
										</span>
										<span className="table-index ">
											ادهب الى : {' '}
											<input type="number" 
												className="ml-2"
												defaultValue={pageIndex + 1} 
												onChange = {e => { 
													const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
													gotoPage(pageNumber)
												} } 
											/>
										</span>
									</div>
                                    <div className="text-center mb-3">  
                                        <div className="filter-pagination  mt-3">
                                            <button className="previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                                {/* {'<'} */}
                                                <i className='fa-solid fa-angle-right' />
                                            </button>
                                            
                                            <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                                السابق
                                            </button>
                                            <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                                التالي
                                            </button>
                                            <button className="next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                                {/* {'>'} */}
                                                <i className='fa-solid fa-angle-left' />
                                            </button>
                                        </div>
                                    </div>
								</div>
						 
						</div>
					</Col>
				</Row>
			</div>
   
      <InviteCustomer1
             ref={invite1}
        data={data1}
        data2={report}
      //  parentFunction={fetchProducts}
         //show={show1}
         />
       <InviteCustomer31
                ref={invite31} data={data1}
                data2={report}
                />
</>


    );
    });
export default WeddingHall;