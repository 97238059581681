//import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';



function dateFormat(value){
	let objectDate = new Date(value);
	let day = ("0" + (objectDate.getDate() + 1)).slice(-2);
	let month = ("0" + (objectDate.getMonth() + 1)).slice(-2);
	let year = objectDate.getFullYear();
	
	return  day + "/" + month + "/" + year;	 
	
}



export const COLUMNS = [
	{
		Header : 'EmployeeID',
		Footer : 'EmployeeID',
		accessor: 'EmployeeID',
		Filter: ColumnFilter,
		//disableFilters: true,

	},
	{
		Header : 'اسم الموظف',
		Footer : 'First Name',
		accessor: 'FirstName',
		Filter: ColumnFilter,
	},
	{
		Header : 'رقم الهاتف',
		Footer : 'Last Name',
		accessor: 'PhoneNumber',
		Filter: ColumnFilter,
	},
	{
		Header : 'الصفة',
		Footer : 'Last Name',
		accessor: 'DepartmentName',
		Filter: ColumnFilter,
	},
	{
		Header : 'الراتب',
		Footer : 'Email Id',
		accessor: 'Salary',
		Filter: ColumnFilter,
	},
	
	{
		Header : 'تاريخ الشهادة الصحية',
		Footer : 'Country',
		accessor: 'imageDate',
		Filter: ColumnFilter,
	},
	{
		Header : 'تاريخ انتهاء العقد',
		Footer : 'Country',
		accessor: 'ToHireDate',
		Filter: ColumnFilter,
	},
	{
		Header : 'الحالة',
		Footer : 'Country',
		accessor: 'StatusName',
		Filter: ColumnFilter,
	},
]

export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Name',
		Footer : 'Name',
		columns: [
			{
				Header : 'First Name',
				Footer : 'First Name',
				accessor: 'first_name'
			},
			{
				Header : 'Last Name',
				Footer : 'Last Name',
				accessor: 'last_name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Date of  Birth',
				Footer : 'Date of  Birth',
				accessor: 'date_of_birth'
			},
			{
				Header : 'Country',
				Footer : 'Country',
				accessor: 'country',
			},
			{
				Header : 'Phone',
				Footer : 'Phone',
				accessor: 'phone'
			},
		]
	},
]