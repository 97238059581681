import React from 'react';
import  { useEffect,useState, forwardRef, useImperativeHandle  } from 'react';
import DatePicker from "react-datepicker";
import {Link, useNavigate} from 'react-router-dom';
import{useRef} from 'react';
import InviteCustomer from '../jsx/constant/ModalList';

 
import {Tab, Nav, Dropdown} from 'react-bootstrap';
import { IMAGES } from '../jsx/constant/theme';

  import  { CurrentTimePicker } from '../jsx/components/Forms/Pickers/TimePicker';
  import MOCK_DATA from '../jsx/components/table/FilteringTable/MOCK_DATA_2.json';
  import { COLUMNS } from '../jsx/components/table/FilteringTable/Columns';
  import { GlobalFilter } from '../jsx/components/table/FilteringTable/GlobalFilter'; 
  import { useMemo } from 'react';
  import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
  import '../jsx/components/table/FilteringTable/filtering.css';
  import {         Col, Row } from 'react-bootstrap';
  import DateRangePicker from "react-bootstrap-daterangepicker";
  import "bootstrap-daterangepicker/daterangepicker.css";
  import axios from "axios";
  import swal from 'sweetalert';
  import Empdata from './EmpData';
  import CompanyExpenses from './AllEmpExpences';

  import EmployeeOffcanvas from '../jsx/constant/EmployeeOffcanvas';
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  


    

const Employee = forwardRef((props, ref) => {

    const [data, setData] = useState([])
    const [data2, setData2] = useState([])
    const [process, setprocess] = useState(null)

    const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');
    const userPermissions = JSON.parse(userPermissionsJSON);
    function getActionsForPage(pageName) {
      const pagePermissions = userPermissions.find(permission => permission.resource === pageName);
   console.log('actionsForPage',pagePermissions); 
     if (pagePermissions) {
         return pagePermissions.action;
     } else {
    navigate('/dashboard_1')
        return [];
     }
   }
   const pageName = 'Empolyee';
   const actionsForPage = getActionsForPage(pageName);
    useEffect(() => {
        fetchProducts();
    }, [])

    const fetchProducts = async () => {
        await axios.get('https://suhba.server.com.ly/back/api/CompEmployee').then(({ data }) => {setData(data)}) 

        /*catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
          });*/
    }
    const EditData = async (e,id,editshow)=> {
      console.log("id",id);
      data.map((item) => {
        if (id == item.EmployeeID) {
          setData2(item)}})
        if(editshow==="edit")
        {
          setprocess(false)
        }else{
          setprocess(true)
        }
        
      userdata.current.showInviteModal(true)
     // invite.current.showEmployeModal()
    }

 

    console.log(data.data)
    console.log("data",data)

    const columns = useMemo( () => COLUMNS, [] )
     
    const tableInstance = useTable({
        columns,
        data,   
        initialState : {pageIndex : 0}
    }, useFilters, useGlobalFilter, usePagination)
    const navigate = useNavigate();

    const { 
        getTableProps, 
        getTableBodyProps, 
        headerGroups, 
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance
    
    
    const {globalFilter, pageIndex} = state 
    const userdata = useRef();
const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
)

    const notifyTopRight = () => {
      toast.success("✔️ تمت عملية الحفظ بنجاح", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
    }; 
    const notifyInfo = () => {
        toast.info("❗ سيتم الغاء عملية ادحال البيانات ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      };
    const [startDate, setStartDate] = useState(new Date());
   const radiocheckBox = [
    {title:'  مرتب', check: 'primary'}, {title:'   يومي', check: 'secondary'}, 
   ];
   const radiocheckBox2 = [
    {title:'  يعمل', check: 'primary'}, {title:'   في إجازة', check: 'secondary'}, {title:'   انهاء العمل', check: 'secondary'},{title:' مفصول', check: 'secondary'},
   ];
   const nav = useNavigate();
    const handleSubmit=(e)=>{
        e.preventDefault();
        nav("#");
    };
    const invite = useRef();
    const deleteUser = async (e,id)=> {
        e.preventDefault();
        swal({
          title: "هل متأكد من الحدف",
          text: " في حال حدف الملف لايمكنك استعادة هدا الملف",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then(willDelete => {
          if (willDelete) {
    
            axios.delete('https://suhba.server.com.ly/back/api/products/' + id)
            
              .then(res => {
                swal({
                  title: "تم",
                  text: "تم الحدف بنجاح",
                  icon: "success",
                  timer: 2000,
                  button: false
                })
                navigate('#')
            
               
            });
        
          } else {
            swal("تم الغاء عملية الحدف");
          }
        
        });
    
    };
        const [activeLink ,setActiveLink] = useState(0);
        const [activeTab, setActiveTab] = useState('Home');
        const     activeTabfun =(pagetap)=>{
          setActiveTab(pagetap)
          navigate('/Empolyee')

        } 
    return (
        <>
            <ToastContainer />
        <div className="card">
            <div className="card-header border-0 pb-0 flex-wrap">
                <h4 className="heading mb-0">الموظف</h4>  

            </div>
            <div className="card-body px-3 pb-0">
                               <Tab.Container defaultActiveKey={activeTab}>
                                <div className=" tab-content">
                                <Nav as="ul" className="nav-tabs">
                                {actionsForPage.includes('create') && (
                                    <Nav.Item as="li" key='Home'>
                                      <Nav.Link eventKey={'Home'}>
                                        <i class="fas fa-user-plus ms-2"></i>
                                       بيانات الموظف
                                      </Nav.Link>
                                    </Nav.Item>
)}
 {actionsForPage.includes('export') && (
                                    <Nav.Item as="li" key='Profile'>
                                      <Nav.Link eventKey={'Profile'} onClick={fetchProducts}>
                                         <i class="far fa-file-alt ms-2"></i>
                                       تقارير الموظف
                                      </Nav.Link>
                                    </Nav.Item>)}
                               
                                <Nav.Item as="li" key='masrofat'>
                                      <Nav.Link eventKey={'masrofat'}>
                                      <i class="fas fa-file-invoice-dollar ms-2"></i>    
                                        مصروفات الموظف
                                      </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                {actionsForPage.includes('create') && (
                                <Tab.Pane eventKey={'Home'}>                                
                     <div className="container-fluid"  >
                     <Empdata 
                     activeTab={activeTabfun}/>
                       
                    </div>
                          </Tab.Pane>
)}
                          <Tab.Pane eventKey={'Profile'}>
                        <div className="container-fluid"  >
                        
                        <div className="table-responsive">
                                     <table {...getTableProps()} className="table dataTable display table-hover">
                                        <thead>
                                        {headerGroups.map(headerGroup => (
                                                 <tr {...headerGroup.getHeaderGroupProps()}>
                                                 {headerGroup.headers.map((column) => (
                                                   // Check if the column accessor is 'age' and render accordingly
                                                   column.id !== 'EmployeeID' ? (
                                                     <th {...column.getHeaderProps()}>
                                                       {column.render('Header')}
                                                       {column.canFilter ? column.render('Filter') : null}
                                                     </th>
                                                   ) : null
                                                 ))}
                                               </tr>
                                        ))}
                                        </thead> 
                                        <tbody {...getTableBodyProps()} className="" >
                                        
                                            {page.map((row) => {
                                                prepareRow(row)
                                                return (
                                                  <tr {...row.getRowProps()}>
                                                    {row.cells.map((cell) => (
                                                      // Check if the column accessor is 'age' and render accordingly
                                                      cell.column.id !== 'EmployeeID' ? (
                                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                      ) : null
                                                    ))}
                                                
                                                         <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            className="light sharp i-false" 
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu dir="rtl" className="dropdown-menu-right">
                          {actionsForPage.includes('read') && (
                            <Dropdown.Item onClick={(e) =>EditData(e,row.original.EmployeeID,"show")}>                               
                            <Link to className=""
                           
                            >عرض
                                </Link>
                                </Dropdown.Item>  )}  
                                
                            {actionsForPage.includes('edit') && (
                            <Dropdown.Item onClick={(e) =>EditData(e,row.original.EmployeeID,"edit")}>                               
                                                            
                             <Link to className=""
                               
                               >تعديل
                                </Link>
                            </Dropdown.Item>)}

                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="d-flex justify-content-between">
                                        <span>
                                            Page{' '}
                                            <strong>
                                                {pageIndex + 1} of {pageOptions.length}
                                            </strong>{''}
                                        </span>
                                        <span className="table-index">
                                            Go to page : {' '}
                                            <input type="number" 
                                                className="ml-2"
                                                defaultValue={pageIndex + 1} 
                                                onChange = {e => { 
                                                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
                                                    gotoPage(pageNumber)
                                                } } 
                                            />
                                        </span>
                                    </div>
                                    <div className="text-center mb-3">  
                                        <div className="filter-pagination  mt-3">
                                            <button className="previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                                {/* {'<'} */}
                                                <i className='fa-solid fa-angle-right' />
                                            </button>
                                            
                                            <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                                السابق
                                            </button>
                                            <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                                التالي
                                            </button>
                                            <button className="next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                                {/* {'>'} */}
                                                <i className='fa-solid fa-angle-left' />
                                            </button>
                                        </div>
                                    </div>
                                    </div></div>

                        </Tab.Pane>
                        <Tab.Pane eventKey={'masrofat'}>
                        <CompanyExpenses />


                        </Tab.Pane>
                        </div>
 
                              </Tab.Container>
             </div>
        


            </div>   
            <EmployeeOffcanvas 
                ref={userdata}
                Title="تعديل بيانات الموظف"   
                data={data2}
                fun={fetchProducts}
                isEditable={process}
                /> 
                <InviteCustomer
                ref={invite}     
                Title="إضافة دورة"
            /> 
                </>
)});

export default Employee;

