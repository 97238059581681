import React,{ useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import MOCK_DATA from '../jsx/components/table/FilteringTable/MOCK_DATA_2.json';
import { COLUMNS } from '../jsx/components/table/FilteringTable/ColumnsTools';
import { GlobalFilter } from '../jsx/components/table/FilteringTable/GlobalFilter'; 
import { Link } from "react-router-dom";
import { CSVLink } from 'react-csv';

import '../jsx/components/table/FilteringTable/filtering.css';
import { Col, Row } from 'react-bootstrap';


export const ToolsReport = () => {
	const columns = useMemo( () => COLUMNS, [] )
	const data = useMemo( () => MOCK_DATA, [] )
	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0}
	}, useFilters, useGlobalFilter, usePagination)
	
	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance
    const {globalFilter, pageIndex} = state	

	



    return(
<>
<div className='container-fluid'>
				<Row>
					<Col lg="12">
						<div className="card">

                        <div className="card-header">
							                          <h4 className="heading mb-0">المعدات</h4>
                            <div>
                                 <Link to={"#"} className="btn btn-secondary btn-sm ms-1" data-bs-toggle="offcanvas"
                                 > <i class="fa-solid fa-print ms-1"></i>طباعه</Link> 
                           <Link to={"#"} className="btn btn-secondary btn-sm ms-1" data-bs-toggle="offcanvas">
                                
                          
                            <i className="fa-solid fa-file-excel" /> تصدير كاملف اكسل </Link>                             

                            </div>
                        </div>  

                        
                        		 
								<div className="table-responsive">
									<table {...getTableProps()} className="table dataTable display table-hover">
										<thead>
										{headerGroups.map(headerGroup => (
												<tr {...headerGroup.getHeaderGroupProps()}>
													{headerGroup.headers.map(column => (
														<th {...column.getHeaderProps()}>
															{column.render('Header')}
															{column.canFilter ? column.render('Filter') : null}
														</th>
													))}
												</tr>
										))}
										</thead> 
										<tbody {...getTableBodyProps()} className="" >
										
											{page.map((row) => {
												prepareRow(row)
												return(
													<tr {...row.getRowProps()}>
														{row.cells.map((cell) => {
															return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
														})}
													</tr>
												)
											})}
										</tbody>
									</table>
									<div className="d-flex justify-content-between mx-2">
										<span>
											الصفحه{' '}
											<strong>
												{pageIndex + 1} من {pageOptions.length}
											</strong>{''}
										</span>
										<span className="table-index ">
											ادهب الى : {' '}
											<input type="number" 
												className="ml-2"
												defaultValue={pageIndex + 1} 
												onChange = {e => { 
													const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
													gotoPage(pageNumber)
												} } 
											/>
										</span>
									</div>
                                    <div className="text-center mb-3">  
                                        <div className="filter-pagination  mt-3">
                                            <button className="previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                                {/* {'<'} */}
                                                <i className='fa-solid fa-angle-right' />
                                            </button>
                                            
                                            <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                                السابق
                                            </button>
                                            <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                                التالي
                                            </button>
                                            <button className="next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                                {/* {'>'} */}
                                                <i className='fa-solid fa-angle-left' />
                                            </button>
                                        </div>
                                    </div>
								</div>
						 
						</div>
					</Col>
				</Row>
			</div>

</>


    );
}
export default ToolsReport;