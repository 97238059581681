import React, { useState, forwardRef, useEffect, useImperativeHandle } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const InviteCustomer = forwardRef((props, ref) => {
    const navigate = useNavigate();
    //const [inviteModal, setInviteModal] = useState(null);
    const showInviteModal = () => {
        props.show(false)
        setUser('')
    }
    const userdata1 = localStorage.getItem('USER_data');
    const userData = JSON.parse(userdata1);
    const userid = userData.id;
    //------------------------
    const [user, setUser] = useState({
        ProcessTypeID:'',
        OffDate: '',
        TypeName: '',
        status: '',
        limitOrder: '',
    })
    useEffect(() => {

        setUser(props.data)

    }, [props.data, setUser]);

    //--------------------------------------------------
    const [errors, setErrors] = useState('')
    useEffect(() => {
        const handleEnterKey = (e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            const form = e.target.form;
            const fields = Array.from(form.elements).filter(el => (
              (el.tagName === 'INPUT' && !el.disabled) ||
              (el.tagName === 'TEXTAREA' && !el.disabled) ||
              (el.tagName === 'SELECT' && !el.disabled)
            ));      const currentFieldIndex = fields.findIndex(el => el === document.activeElement);
            const nextFieldIndex = currentFieldIndex + 1;
            if (nextFieldIndex < fields.length) {
              fields[nextFieldIndex].focus();
            }
          }
        };
        
        document.addEventListener('keydown', handleEnterKey);
        
        return () => {
          document.removeEventListener('keydown', handleEnterKey);
        };
      }, []);
       
    const onSubmit = ev => {
        ev.preventDefault()

        axios.put(`https://suhba.server.com.ly/back/api/process_types/${user.ProcessTypeID}`, user)
            .then(() => {
                setUser('')
                props.parentFunction()
                setErrors('')

                props.show(false)
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });

    }
    console.log('uder', user)

    //---------------------------------------------
    return (
        <>
            <div>

            </div>
            <Modal className="modal fade" id="exampleModal1" show={props.inviteModal} centered>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">إدارة التواريخ</h1>
                        <button type="button" className="btn-close " style={{ marginLeft: 0 }} padding="" onClick={() => showInviteModal()}></button>
                    </div>
                    <div className="modal-body py-2 px-3 ">
                        <div className="row">
                            <div className="col-xl-12">
                                <form onSubmit={onSubmit}>

                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="row">


                                            </div>
                                            <div className="row">
                                                <div className="col-xl-2 ps-0">
                                                    <label className="form-label mt-3  ">التاريخ</label>
                                                </div>
                                                <div className="col-xl-4">
                                                    <div className="input-group ">
                                                        <input style={{ height: "2rem" }}
                                                            type="date"
                                                            required
                                                            id="inputcity"
                                                            name="date"
                                                            className="form-control mt-3"
                                                            value={user.OffDate}
                                                            onChange={ev => setUser({ ...user, OffDate: ev.target.value })}
                                                        />
                                                    </div>   </div>
                                                <div className="col-xl-2 ps-0">
                                                    <label className="form-label mt-3 ">السبب</label>
                                                </div>
                                                <div className="col-xl-4">
                                                    <div className="input-group ">
                                                        <input style={{ height: "2rem" }}
                                                            type="text"
                                                            required
                                                            id="inputcity"
                                                            name="TypeName"
                                                            className="form-control mt-3"
                                                            value={user.TypeName}
                                                            onChange={ev => setUser({ ...user, TypeName: ev.target.value })}
                                                        />
                                                    </div>   </div>
                                                <div className="col-xl-2 ps-0">
                                                    <label className="form-label mt-3  ">الحد الاعلى</label>
                                                </div>
                                                <div className="col-xl-4">
                                                    <div className="input-group ">
                                                        <input style={{ height: "2rem" }}
                                                            type="text"
                                                            required
                                                            id="inputcity"
                                                            name="city"
                                                            className="form-control mt-3"
                                                            value={user.limitOrder}
                                                            onChange={ev => setUser({ ...user, limitOrder: ev.target.value })}
                                                        />
                                                    </div>   </div>
                                                <div className="col-xl-6">

                                                    <fieldset className="form-group">
                                                        <div className="row mt-3">
                                                            <div className="col-sm-6 p-0">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="gridRadios"
                                                                        value="option1"
                                                                        onChange={ev => setUser({ ...user, status: 1 })}
                                                                        checked={user.status === 1} // If status is 1, option1 is checked
                                                                    />
                                                                    <label className="form-check-label">
                                                                        قفل اليوم
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 p-0">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="gridRadios"
                                                                        value="option2"
                                                                        onChange={ev => setUser({ ...user, status: 0 })}
                                                                        checked={user.status === 0} // If status is 0, option2 is checked
                                                                    />
                                                                    <label className="form-check-label">
                                                                        الغاء القفل
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>

                                                </div>
                                                <div className="modal-footer mt-3 " style={{ paddingTop: "0.375rem" }}>
                                                    <button id="toastr-success-top-left" className="btn btn-primary mt-2 me-2 btn-icon-xs   btn-block">حفظ</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>

                </div>
            </Modal>
        </>
    )
})
export default InviteCustomer;