import {createContext, useContext, useState} from "react";
import { useEffect } from "react";
const StateContext = createContext({
  currentUser: null,
  token: null,
  notification: null,
  setUser: () => {},
  setToken: () => {},
  setNotification: () => {},
 // getPermissions: () => {} // Add getPermissions to the default context value
})

export const ContextProvider = ({children}) => {
  const [user, setUser] = useState({});
  const [id, setId] = useState({});
  const [permissions, setPermissions] = useState([]); // Change Permissions to permissions
  const [token, _setToken] = useState(localStorage.getItem('ACCESS_TOKEN'));
  const [notification, _setNotification] = useState('');

  
  const setToken = (token) => {
    _setToken(token)
    if (token) {
      localStorage.setItem('ACCESS_TOKEN', token);
    } else {
      localStorage.removeItem('ACCESS_TOKEN');
    }
  }

  const setNotification = message => {
    _setNotification(message);

    setTimeout(() => {
      _setNotification('')
    }, 5000)
  }
 

  // Parse the JSON string back to an array
  

 useEffect(() => {
 const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');

const userPermissions = JSON.parse(userPermissionsJSON);

setPermissions(userPermissions); // Changed from setPermissions(permissions) 
  }, []); // Empty dependency array ensures this effect runs only once, on mount


  return (
    <StateContext.Provider value={{
      user,
      setUser,
      id,
      setId,
      token,
      setToken,
      notification,
      setNotification,
      permissions,
      setPermissions,
       
      
    }}>
      {children}
    </StateContext.Provider>
  );
}

export const useStateContext = () => useContext(StateContext);
