import React,{ useRef,useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import MOCK_DATA from '../jsx/components/table/FilteringTable/MOCK_DATA_2.json';
import { COLUMNS } from '../jsx/components/table/FilteringTable/ColumnsComExpenses';
 import { Link,useNavigate } from "react-router-dom";
 import axios from "axios";
 import swal from 'sweetalert'; 
import '../jsx/components/table/FilteringTable/filtering.css';
import { Col, Row ,Table} from 'react-bootstrap';
import InviteCustomer1 from '../jsx/constant/CompanyExpensesModal';
import InviteCustomer from '../jsx/constant/EditCompanyExpenses';
//import InviteCustomer2 from '../jsx/constant/AsnafExpenses';
  import { CSVLink } from 'react-csv';

import  { useEffect,useState     } from 'react';


export const CompanyExpenses = () => {
	const columns = useMemo( () => COLUMNS, [] )
	//const data = useMemo( () => MOCK_DATA, [] )
  const [data4, setData4] = useState([])
  const [data3, setData3] = useState([])

  const [data, setData] = useState([])
  const [data2, setData2] = useState([])
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [itemName, setItemName] = useState('');
  const [ID, setID] = useState(0)
  const [inviteModal , setInviteModal] = useState(false);
	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0}
	}, useFilters, useGlobalFilter, usePagination)
	
	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance
    const {globalFilter, pageIndex} = state	
    const navigate = useNavigate();

    const userdata = useRef();
    const invite1 = useRef();
    const invite = useRef();
    const invite2 = useRef();

    useEffect(() => {
      fetchProducts();
      getexpensis()
  }, [])

  const fetchProducts = async () => {
      await axios.get('https://suhba.server.com.ly/back/api/expenses').then(({ data }) => {
        
     setData3(data)
      const itemMap = {};
      data.forEach(item => {
        if (itemMap[item.ExpenseTypeID]) {
            itemMap[item.ExpenseTypeID].totalQuantity = parseFloat(itemMap[item.ExpenseTypeID].totalQuantity) + parseFloat(item.Amount);
        } else {
            itemMap[item.ExpenseTypeID] = {
                ItemID: item.ExpenseTypeID,
                Name: item.TypeName,
                totalQuantity: parseFloat(item.Amount),
            };
        }
    });
      const totalQuantityByItem = Object.values(itemMap);
      totalQuantityByItem.sort((a, b) => b.totalQuantity - a.totalQuantity);
      setData(totalQuantityByItem);
    
      
     
    }) 
  }
  const printInvoice = () => {
    const printContents = document.getElementById('invoiceDiv').innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }; 
    const arabicMonthNames = [
      "يناير", "فبراير", "مارس", "إبريل", "مايو", "يونيو",
      "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"
    ];
    const handleSearch = () => {
       const filteredData = data3.filter(item => {
        const itemDate = new Date(item.ExpenseDate);
        const itemMonth = itemDate.getMonth() + 1;
        const itemYear = itemDate.getFullYear();
         const monthFilter = month ? itemMonth === parseInt(month) : true;
        const yearFilter = year ? itemYear === parseInt(year) : true;
        const itemNameMatch = item.TypeName.toString().includes(itemName.toString());
        return monthFilter && yearFilter && itemNameMatch;
      });  const itemMap = {};
      filteredData.forEach(item => {
        if (itemMap[item.ExpenseTypeID]) {
            itemMap[item.ExpenseTypeID].totalQuantity = parseFloat(itemMap[item.ExpenseTypeID].totalQuantity) + parseFloat(item.Amount);
        } else {
            itemMap[item.ExpenseTypeID] = {
                ItemID: item.ExpenseTypeID,
                Name: item.TypeName,
                totalQuantity: parseFloat(item.Amount),
            };
        }
    });
    const totalQuantityByItem = Object.values(itemMap);
    totalQuantityByItem.sort((a, b) => b.totalQuantity - a.totalQuantity);
    setData(totalQuantityByItem);
    
      

    }
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
          </g>
        </svg>
    )
    const headersTitle = [
      // HallID 	HallName 	HallAddress 	HallContactNumber 	ItemPrice 	UserID 	created_at 	updated_at 	PreName 	
        {label:'اسم البند', key:'Name'}, 
        {label:'اجمالي المبلغ', key:'totalQuantity'}, 
       
         
    ]
      const csvlink = {
        headers : headersTitle,
        data : data,
        filename: "csvfile.csv"
      }
    const EditData = async (e,id)=> {
      console.log(id);

      e.preventDefault();
      await axios.get(`https://suhba.server.com.ly/back/api/expenses/${id}`).then(({ data }) => 
      {setData2(data.data)
        console.log('data',data2);
        console.log('data.darta',data2.data);
       } )
       .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
      setID(id);setInviteModal(true)
     // invite.current.showEmployeModal()
    }
    const show =(value)=>
{
setInviteModal(value)

}
const getexpensis = () => {
  axios.get('https://suhba.server.com.ly/back/api/fillexpeses')
   .then(({ data }) => {
      setData4(data.ExpenseType)
   })
   .catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  });
   
   }
const handleClearSearch = () => {
  setMonth('');
  setYear('');
  setItemName('');
  fetchProducts();
};
    return(
<>
<div className='container-fluid'>
				<Row>
					<Col lg="12">
						<div className="card">
            <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">   

                 <div className="tbl-caption">
                 <h4 className="heading mb-0">مصروفات الشركة</h4>
                 <div>
                        
                                  
                                 <Link to={"#"} className="btn btn-secondary btn-sm m-1"  onClick={printInvoice} data-bs-toggle="offcanvas"
                                 ><i class="fa-solid fa-print ms-1"></i>طباعه</Link> 
          <CSVLink {...csvlink} className="btn btn-secondary btn-sm ms-1" ><i className="fa-solid fa-file-excel ms-2" />تصدير كاملف اكسل </CSVLink>
                           

                            </div>
                        </div>  
                        </div>   </div> 
                        <div className="row" style={{ display: 'flex', marginTop: '20px', marginRight: '20px' }}>
                <div className="col-md-3" style={{ display: 'flex' }}>
                  <label htmlFor="startMonth" className="form-label ms-2 mt-1">الشهر</label>
                  <select className="default-select form-control"
                    id="startMonth"
                    value={month}
                    onChange={e => setMonth(e.target.value)}
                  >
                    <option value="">اختر الشهر</option>
                    {[...Array(12).keys()].map(month => (
                      <option key={month + 1} value={month + 1}>{arabicMonthNames[month]}</option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3" style={{ display: 'flex' }}>
                  <label htmlFor="startMonth" className="form-label ms-2 mt-1">السنة</label>
                  <input className="form-control"
                    type="text"
                    value={year}
                    placeholder="اختر السنة"
                    onChange={e => setYear(e.target.value)}
                  />
                </div> <div className="col-md-4" style={{ display: 'flex' }}>
                  <label htmlFor="itemName" className="form-label ms-2 mt-1" style={{whitespace: "nowrap"}}>الصنف</label>
                  <select className="default-select form-control" name="ExpenseTypeID" value={itemName} onChange={e => setItemName(e.target.value)}>
                                 <option  data-display="Select">اختر من القائمة</option>
                                 {
                                   data4.map((row)=>(
                                
                                 <option key={row.ExpenseTypeID} value={row.TypeName}>
                                            {row.TypeName}
                                    </option>
                                     ))}
                                 </select> 
				 
                </div>
                <div className="col-md-2">
                  <button className="btn btn-primary btn-sm me-1" onClick={handleSearch}>ابحت</button>
                  <button className="btn btn-secondary btn-sm me-1" onClick={handleClearSearch}> الغاء</button>
                </div>   
                </div>    

                        		 
								<div className="table-responsive m-3" id="invoiceDiv">
                <Table responsive  className="primary-table-bordered table-hover">
                  <thead className="thead-primary">
                     <tr className='pe-2'>
                      <th>اسم البند</th>
                      <th> اجمالي المبلغ المدفوع</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.Name}</td>
                        <td>{item.totalQuantity}</td>
                        
                      </tr>
                    ))}
                  </tbody>
                </Table>
</div>

									<div className="d-flex justify-content-between mx-2">
										<span>
											الصفحه{' '}
											<strong>
												{pageIndex + 1} من {pageOptions.length}
											</strong>{''}
										</span>
										<span className="table-index ">
											ادهب الى : {' '}
											<input type="number" 
												className="ml-2"
												defaultValue={pageIndex + 1} 
												onChange = {e => { 
													const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
													gotoPage(pageNumber)
												} } 
											/>
										</span>
									</div>
                                    <div className="text-center mb-3">  
                                        <div className="filter-pagination  mt-3">
                                            <button className="previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                                {/* {'<'} */}
                                                <i className='fa-solid fa-angle-right' />
                                            </button>
                                            
                                            <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                                السابق
                                            </button>
                                            <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                                التالي
                                            </button>
                                            <button className="next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                                {/* {'>'} */}
                                                <i className='fa-solid fa-angle-left' />
                                            </button>
                                        </div>
                                    </div>
								
						 
						</div>
					</Col>
				</Row>
			</div>
            <InviteCustomer1
                ref={invite1}     
                parentFunction={fetchProducts}  
            /> 
             
             <InviteCustomer
                
                data={data2}
                parentFunction={fetchProducts}  
                inviteModal={inviteModal}
                show={show}
            /> 
</>


    );
}
export default CompanyExpenses;