

import React, { useState, forwardRef, useImperativeHandle  ,useRef,useEffect} from 'react';
import {Modal} from 'react-bootstrap';
 import { Dropdown } from 'react-bootstrap';
 import axios from "axios";
  import { useNavigate ,Link} from "react-router-dom";
  import swal from 'sweetalert';
  import InviteCustomer3 from '../../jsx/constant/ModalList2 copy 2';

const InviteCustomer = forwardRef((props, ref) => {
    const [inviteModal , setInviteModal] = useState(false);
    const [Name,setName] = useState()
    const [QuantityInStock,setQuantityInStock] = useState('')
    const [ItemPrice,setItemPrice]= useState('')
   // const [AreaName,setAreaName] = useState('')
   const [newtable, setNewtable] = useState([]);
   const userdata1 = localStorage.getItem('USER_data');
   const userData = JSON.parse(userdata1);
     const userid = userData.id;
   const invite3 = useRef();

    const [hideshow, sethideshow] = useState(false)
    const [status, setStatus] = useState();
     const [data, setData] = useState([])
     const [catigorydata, setCatigorydata] = useState([])
     const [storagedata, setStoragedata] = useState([])
     const [intdata, setIntdata] = useState([])

  //  const { AreaID } = useParams();
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    showInviteModal() {
        setInviteModal(true)
    }

  }))      ;
  const hideInviteModal =()=> {
    sethideshow(false)
    setInviteModal(false)
}
        

    /////////////////////////////////////
    const [color, setColor] = useState('blue')

             const [tableData, setTableData] = useState([]);
             const [expences, setexpences] = useState({});

           const handleEdit = async (id, field, value) => {
   setColor('red')
             const updatedData = tableData.map((row) =>
              row.id === id ? { ...row, [field]: value } : row
             );
             setTableData(updatedData);
              //const formData = new FormData();
                    // ItemID   Name  CategoryID  StorageID   ItemPrice   QuantityInStock   UserID 

           //  formData.append('_method', 'PATCH')
           // formData.append('ItemID', id);
            // formData.append('Name', value);
            // formData.append('UserID', "1");
           const error = await axios.put(`https://suhba.server.com.ly/back/api/food_items/${id}`, {
            [field]: value,  })
            .catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  });
                
             } 
            // console.log(formData);
            const [foodItem, setFoodItem] = useState({
                Name: '',
                CategoryID: '',
                StorageID: '',
                ItemPrice: '',
                QuantityInStock: '1',
                UserID: userid,
                status:'متوفر'
              });
            
            ///////////////////////////////////////////////
      const getcities = () => {
        axios.get('https://suhba.server.com.ly/back/api/food_items')
         .then(({ data }) => {
            setData(data)
         })}

         const [currentPage , setCurrentPage] = useState(1);
         const recordsPage = 8;
         //const lastIndex = currentPage * recordsPage;
        // const firstIndex = lastIndex - recordsPage;   
        // const records = data.slice(firstIndex, lastIndex);
       //  const npage = Math.ceil(data.length / recordsPage)
     //    const number = [...Array(npage + 1).keys()].slice(1)
     const input = document.querySelector('input[name="city"]');
     if(input)
{
     input.addEventListener('invalid', function (event) {
       if (event.target.validity.valueMissing) {
         event.target.setCustomValidity('الرجاء ادخال اسم الاداة');
       } 
     })
     
     input.addEventListener('change', function (event) {
       event.target.setCustomValidity('');
     })}
        
    
       

      useEffect(() => {
        getcities();
        getint();
        getfooditem();
       // getfooditeming();

    }, [])
    //console.log(foodItem)
  
    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;   
    const records = data.slice(firstIndex, lastIndex);
    const npage = Math.ceil(data.length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)
    //------------------------------------------------------------------------
    const handleInputChange = () => {
        setInviteModal()
      };
    const [statusPriority, setStatusPriority] = useState([]);
  
    const handleAction =  async(id, field, value) => {
            let temp = statusPriority.map((data) => {
      if (id === data.id) {
        return { ...data, status: value };
      }
      return data;
    });
    setStatusPriority(temp);
        const formData = new FormData();
        formData.append('_method', 'PATCH')
       formData.append('ItemID', id);
        formData.append('status', value);
        formData.append('UserID', userid);
       // console.log(formData)
       // console.log(id);
    const error = await axios.put(`https://suhba.server.com.ly/back/api/food_items/${id}`, {
       [field]: value,  });
           if (error.response) {
             console.log(error.response.data);
             console.log(error.response.status);
             console.log(error.response.headers);
           } else if (error.request) {
             console.log(error.request);
           } else {
             console.log('Error', error.message);
           }
           console.log(error.config);
          } 
          const [selectedItem, setSelectedItem] = useState();
//-------------------------------------------------------------------------------
          const handleSelect = (eventKey, rowIndex) => {
            const updatedData = [...data];
            updatedData[rowIndex].status = eventKey;
            setSelectedItem(eventKey) 
            //console.log(eventKey)

                       setData(updatedData);//console.log(rowIndex);
          };
          const [foodIteming, setFoodIteming] = useState([])
          const [foodItems, setFoodItems] = useState([])
            const [foodItemint, setFoodItemint] = useState({
            FoodItemID:'',
            IngredientID:'',
          Quantity:'',
          ForHowMany:'',
          UserID: userid,
          });
          const getfooditem = () => {
            axios.get('https://suhba.server.com.ly/back/api/getlast')
             .then(({ data }) => {
              setFoodItems(data.FoodItem)
             })}
             const getfooditeming = (id) => {
             // console.log('name',id)
              axios.get(`https://suhba.server.com.ly/back/api/getT/${id}`)
               .then(({ data }) => {
                setFoodIteming(data)
                console.log('data',data)
               }) .catch(function (error) {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log('Error', error.message);
                }
                console.log(error.config);
              });
            
            }
              console.log('get data',foodIteming)

          const getint = () => {
            axios.get('https://suhba.server.com.ly/back/api/getint')
             .then(({ data }) => {
              setIntdata(data.Ingredient)
             })
             .catch(function (error) {
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
            });
             
             }
             const [amount, setAmount] = useState(0);

             const handleInputChange2 = (e) => {
              const { name, value } = e.target;
              setFoodItemint((prevData) => ({
                ...prevData,
                [name]: value,
              }));
              console.log('name',name)

              if (name ==="FoodItemID")
              {
               console.log('value',value)
              getfooditeming(value)
            }
              else
              console.log('setAmount',value)

                 {setAmount(value)} 
             
              
              
            };  

             const createintitem = async(e)=>{
              e.preventDefault();
              console.log('befotr prent',calculatedRows)

                    await axios.post('https://suhba.server.com.ly/back/api/asnaf_expensesses', calculatedRows)
              .then(res=>{
                 setexpences(res.lastOrder)
                 sethideshow(true)
                 ;
              } )  .catch(function (error) {
                  if (error.response) {
                     console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('Error', error.message);
                  }
                  console.log(error.config);
                });
                //getfooditeming(Name); 
                               props.parentFunction()

                setFoodItem('')
              setCalculatedRows('')
                setInviteModal(false)
              } 

           // console.log('new',foodItemint);
         /*  const calculate = () => {
            let q = 0;
            let ing = '';
            let fhm = 0;
            let cal = 0;
            //setNewtable('')
            for (let i = 0; i < foodIteming.length; i++) {
              q = foodIteming[i].Quantity;
              ing = foodIteming[i].IngredientName;
              fhm = foodIteming[i].ForHowMany;
              cal = amount / fhm * q;
              console.log(foodIteming[i].IngredientName)
              // Assuming newtable is an array in state
            newtable.push({ ing, cal });
            //push(<tr key={i}> <td>{ing}</td> <td>{cal}</td> </tr>);
              //setNewtable(prevTable => [...prevTable, { ing, cal }]);
            }
         return newtable
             };*/               const [calculatedRows, setCalculatedRows] = useState([]);

             const calculate = () => {
              const updatedRows = [...calculatedRows];
            
              foodIteming.forEach((entry) => { 
                const existingRow = updatedRows.find((row) => row.IngName === entry.IngredientName);
            console.log('existingRow',foodIteming)
                if (existingRow) {
                  existingRow.Amount += (amount / entry.ForHowMany) * entry.Quantity;
                } else {
                  updatedRows.push({
                    IngredientID:entry.IngredientID,
                    IngName: entry.IngredientName,
                    Amount: (amount / entry.ForHowMany) * entry.Quantity,
                    IngCategoryID:entry.IngCategoryID,
                    UserID:userid,
                    IngCatName:entry.IngCatName
                  });
                }
              });
            
              setCalculatedRows(updatedRows);
            };
            
            console.log('calculatedRows',calculatedRows)
            return(
        <>
            <Modal className="modal fade" id="exampleModal1" show={inviteModal}  centered>                
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1"> مشتريات الطلبيات </h1>
                       
                        <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={hideInviteModal}></button>
                    </div>
                           
                      <div className="modal-body py-1 px-3">
                 <form onSubmit={createintitem}>
                         
                        <div className="row">
                            <div className="col-xl-12">
                      
                            <div className="row">
                            <div className="col-xl-6 ">
                                <label className="form-label mt-3">اسم الصنف</label>
                                 <select className="default-select form-control" name="FoodItemID" defaultValuevalue={foodItemint.FoodItemID} onChange={handleInputChange2}>
                                 <option  data-display="Select">اختر من القائمة</option>
                                 {
                                   foodItems.map((row)=>(
                                  <option key={row.ItemID} value={row.ItemID}>
                                            {row.Name}
                                    </option>
                                     ))}    
                                 </select> 
                                   </div>
                                   <div className="col-xl-6">
                                <label className="form-label mt-3">دالك لعدد</label>
                                <input type="number" className="form-control" name="ForHowMany" defaultValuevalue={foodItemint.ForHowMany} onChange={handleInputChange2}                      />
                                
                                 </div>
                  </div>
                           

                                 <input type="text" hidden="true" name="UserID" value={userid} />
                                <input type="text"  id ="Hallid" hidden="true" name="EexpenseID" defaultValue={props.ExpenseId} />

                            </div>
                        </div>                                
                    


                        <div className="col-xl-12 pt-2 pe-3">
                         
 <Link className="btn btn-primary btn-sm btn-block"
                                onClick={(e) =>calculate()}
                            > اضف الى القائمة 
                            </Link>
                    </div> 
                     <div className="table-responsive active-projects task-table my-2 me-3" style={{  height: "250px" }}>
                                        <table className="table">
                                    <thead>
                                        <tr className="center" >
                                            <th scope="col">اسم المكون</th>
                                            <th scope="col">الكمية</th>
                                            <th scope="col">الوحدة</th>

                                         </tr>
                                    </thead>
                                    <tbody>
                                   
          {     calculatedRows.length > 0 &&     calculatedRows.map((row, index) => (
            <tr key={index}>
              <td>{row.IngName}</td>
              <td>{row.Amount}</td>
              <td> {row.IngCatName}</td>
            </tr>
          ))}
         
                                    </tbody>
                                </table>
                                
</div>
        
<div className="col-xl-12 pt-2  ">
                                <button hidden={hideshow} type="submit" className="btn btn-primary btn-sm btn-block"
 >
                       
                        حفظ</button>
 
                               
                                                                                                </div>
                                                                                                </form>

</div>



                           
                                                  
                  
                   
                </div>               
            </Modal>
           
        </>
    )
})
export default InviteCustomer;

