import React,{ useRef,useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import MOCK_DATA from '../jsx/components/table/FilteringTable/MOCK_DATA_2.json';
import { COLUMNS } from '../jsx/components/table/FilteringTable/Columncustomer';
 import { Link,useNavigate } from "react-router-dom";
 import axios from "axios";
 import swal from 'sweetalert'; 
import '../jsx/components/table/FilteringTable/filtering.css';
import { Col, Row ,Dropdown} from 'react-bootstrap';
import InviteCustomer1 from '../jsx/constant/AddCustomer';
import InviteCustomer from '../jsx/constant/EditCustomer';
import InviteCustomer2 from '../jsx/constant/Showcustomer';
import {useStateContext} from "../context/ContextProvider";

import  { useEffect,useState     } from 'react';


export const CompanyExpenses = () => {
  const navigate = useNavigate();

	const columns = useMemo( () => COLUMNS, [] )
	//const data = useMemo( () => MOCK_DATA, [] )
  const [data, setData] = useState([])
  const [data2, setData2] = useState([])
  const {user,id, token, setUser,  permissions} = useStateContext();
  function getActionsForPage(pageName) {
    // Find the permissions object for the given page
    const pagePermissions = permissions.find(permission => permission.resource === pageName);
  console.log('actionsForPage',permissions); 
  
    // If permissions for the page are found, return the actions
    if (pagePermissions) {
        return pagePermissions.action;
    } else {
   navigate('/dashboard_1')
  
        return [];
    }
  }
  
  // Usage
  const pageName = 'Customers';
  const actionsForPage = getActionsForPage(pageName);
  const [ID, setID] = useState(0)
  const [coustname, setCoustname] = useState('')

  const [inviteModal1 , setInviteModal1] = useState(false);

  const [inviteModal , setInviteModal] = useState(false);
	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0}
	}, useFilters, useGlobalFilter, usePagination)
	
	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance
    const {globalFilter, pageIndex} = state	

    const userdata = useRef();
    const invite1 = useRef();
    const invite = useRef();
    const invite2 = useRef();

  
  const fetchProducts = async () => {
      await axios.get('https://suhba.server.com.ly/back/api/customers').then(({ data }) => {setData(data)}) 
  } 
  
  useEffect(() => {
      fetchProducts();
  }, [])

    const deleteUser = async (e,id)=> {
        e.preventDefault();
        swal({
          title: "هل متأكد من الحدف",
          text: " في حال حدف الملف لايمكنك استعادة هدا الملف",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then(willDelete => {
          if (willDelete) {
    
            axios.delete(`https://suhba.server.com.ly/back/api/customers/${id}`)
            
              .then(res => {
                fetchProducts();
                swal({
                  title: "تم",
                  text: "تم الحدف بنجاح",
                  icon: "success",
                  timer: 2000,
                  button: false
                })
                navigate('#')
            
               
            });
        
          } else {
            swal("تم الغاء عملية الحدف");
          }
        
        });
    
    };
    
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
          </g>
        </svg>
    )

    function Neworder(e,idcoust) {
 
      data.forEach((item) => {
        if (idcoust === item.CustomerID) {
         // setID(idcoust);
          //setCoustname(item.CustomerName);
          const queryString = new URLSearchParams({
            id: idcoust,
            cname: item.CustomerName,
            //total:total,
          }).toString();



          window.location.href = `/Orders?${queryString}`;
         }
      });
    }
   // const queryString = new URLSearchParams({
     // id: "22",
   //   cname: "coustname",
      //total:total,
   // }).toString();
    function EditData(e,id,EOrS) {
   
      data.map((item) => {
       if (id == item.CustomerID) {
        setData2(item)   
        console.log('in EditData',item)

        }
      })
      console.log(EOrS)
   if (EOrS === "show")
   { // setInviteModal1(true)
    invite2.current.showInviteModal();
  }else{
        // setInviteModal(true)
        invite.current.showInviteModal();

}
   
  
  } 
   
    const show =(value)=>
{
setInviteModal(value)

} 
  const show1 =(value)=>
{
setInviteModal1(value)

}
    return(
<>
<div className='container-fluid'>
				<Row>
					<Col lg="12">
            
						<div className="card">
            <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">   

                 <div className="tbl-caption">
                 <h4 className="heading ms-3">الزبائن</h4>
                 <div>  {actionsForPage.includes('create') && (
                        < Link to={"#"} className="btn btn-primary btn-sm ms-1" data-bs-toggle="offcanvas"
                                 onClick={() => invite1.current.showInviteModal()}
                               > + إضافة زبون </Link>  )}
                                 {/*  {actionsForPage.includes('export') && (
                               <Link to={"#"} className="btn btn-secondary btn-sm ms-1" data-bs-toggle="offcanvas"
                                 ><i class="fa-solid fa-print ms-1"></i>طباعه</Link> )} 
                                   {actionsForPage.includes('export') && (
                                 <Link to={"#"} className="btn btn-secondary btn-sm ms-1" data-bs-toggle="offcanvas">
                                   <i className="fa-solid fa-file-excel" /> تصدير كاملف اكسل </Link> )} */}                           

                            </div> 
                        </div>  
                        </div>   </div> 
                         

                        
                        		 
								<div className="table-responsive">
									<table {...getTableProps()} className="table dataTable display table-hover">
										<thead>
										{headerGroups.map(headerGroup => (
												<tr {...headerGroup.getHeaderGroupProps()}>
												      {headerGroup.headers.map((column) => (
                        // Check if the column accessor is 'age' and render accordingly
                        column.id !== 'CustomerID' ? (
                          <th {...column.getHeaderProps()}>
                            {column.render('Header')}
                            {column.canFilter ? column.render('Filter') : null}
                          </th>
                        ) : null
                      ))}             <th style={{ paddingBottom: "4.5rem" }}>طلبية</th>

												</tr>
										))}

										</thead> 
                                        <tbody {...getTableBodyProps()} className="" >
                                        
                                        {page.map((row) => {
                                            prepareRow(row)
                                            return(
                                                <tr {...row.getRowProps()}>

                                                    {row.cells.map((cell) => (
          // Check if the column accessor is 'age' and render accordingly
          cell.column.id !== 'CustomerID' ? (
            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
          ) :null
        ))}
<td>
<Link  style={{ color: 'green' }} to='#'  onClick={(e) =>Neworder(e,row.original.CustomerID)}
>طببة جديدة <i class="fa-solid fa-cart-plus px-3"></i></Link>
 
</td>

                                                     <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        className="light sharp i-false" 
                      >
                           {svg1}
                       </Dropdown.Toggle>
                      <Dropdown.Menu dir="rtl" className="dropdown-menu-right">
                      {actionsForPage.includes('read') && (
                        <Dropdown.Item                         onClick={(e) => EditData(e,row.original.CustomerID,"show")}
                        >
                        <Link to className=""
                        >عرض
                            </Link>
                            </Dropdown.Item>  )}  
                           
                        {actionsForPage.includes('edit') && (
                        <Dropdown.Item                                onClick={(e) =>EditData(e,row.original.CustomerID)}
                        >                               
                                                        
                         <Link to className=""
                               onClick={(e) =>EditData(e,row.original.CustomerID)}
                               >تعديل
                            </Link>
                        </Dropdown.Item>)}

                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
									</table>



									<div className="d-flex justify-content-between mx-2">
										<span>
											الصفحه{' '}
											<strong>
												{pageIndex + 1} من {pageOptions.length}
											</strong>{''}
										</span>
										<span className="table-index ">
											ادهب الى : {' '}
											<input type="number" 
												className="ml-2"
												defaultValue={pageIndex + 1} 
												onChange = {e => { 
													const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
													gotoPage(pageNumber)
												} } 
											/>
										</span>
									</div>
                                    <div className="text-center mb-3">  
                                        <div className="filter-pagination  mt-3">
                                            <button className="previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                                {/* {'<'} */}
                                                <i className='fa-solid fa-angle-right' />
                                            </button>
                                            
                                            <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                                السابق
                                            </button>
                                            <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                                التالي
                                            </button>
                                            <button className="next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                                {/* {'>'} */}
                                                <i className='fa-solid fa-angle-left' />
                                            </button>
                                        </div>
                                    </div>
								</div>
						 
						</div>
					</Col>
				</Row>
			</div>
            <InviteCustomer1
                ref={invite1}     
                parentFunction={fetchProducts}  
            /> 
             <InviteCustomer2
                             ref={invite2}     

                data={data2}
                parentFunction={fetchProducts}  
                inviteModal={inviteModal1}
                show={show1} 
            /> 
             <InviteCustomer
                                ref={invite}     

                data={data2}
                parentFunction={fetchProducts}  
                inviteModal={inviteModal}
                show={show}
            /> 
</>


    );
}
export default CompanyExpenses;