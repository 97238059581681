import React from 'react';

import InviteCustomer from '../jsx/constant/IngredientsModal';

const Ingredients = () => {
    return(
<>
<InviteCustomer/> 

</>





    )
//ref={invite}     
//Title="إضافة دورة"
}
export default Ingredients;