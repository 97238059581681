import React, { useState, Fragment, useEffect } from "react";
import { Badge } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { CurrentTimePicker } from '../jsx/components/Forms/Pickers/TimePicker';
import "bootstrap-daterangepicker/daterangepicker.css";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import { Last } from "react-bootstrap/esm/PageItem";

import swal from 'sweetalert';

const OrderCheckout = () => {
  const [listItem, setlistItem] = React.useState([])
  const navigate = useNavigate();
  const [errors, setErrors] = useState('')

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const receivedDataArray = JSON.parse(searchParams.get('data')) || [];
  const receivedItemId = searchParams.get('id') || '';
   const receivedname= searchParams.get('cname') || '';
   const [finaltotal, setfinaltotal] = useState(0)

  // const receivedData = location.state?.data || [];
  // console.log(receivedData)  
  console.log('receivedDataArray',receivedDataArray)
  //  DeliveryID 	OrderID 	DeliveryPersonID 	DeliveryDate 	DelPrice 	Address 	Status 	UserID 
  const [OrderDate, setOrderDate] = useState(new Date());
  const [Discount, setDiscount] = useState('');

  // Format the date to 'YYYY-MM-DD'
  const userdata = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata);
    const userid = userData.id;
  const [order, setOrder] = useState({
    //  OrderID:'',
    CustomerID: receivedItemId,
    OrderDate: '',
    TotalAmount: finaltotal,
    DiscountAmount: '',
    Status: 'قيد الانتظار',
    UserID: userid,
    // DeliveryID: '',
    DeliveryPersonID: '',
    DeliveryDate: '',
    DeliveryTime: '',
    DelPrice: '',
    Address: '',
    GuaranteeTypesID: '',
    Paystatus:'',
    Prepay:'',
  DecoratePrice:'',
  });

  const [lastorder, setlastorder] = useState(0)
  const [lastdip, setlastdip] = useState(0)


  const [orderItem1, setOrderItem1] = useState()
  const [orderItems, setOrderItems] = useState([]);
  const [total1, setTotal1] = useState(0)
  const [total, setTotal] = useState(0)
  const [address, setAddress] = useState([])
  const [delprice, setdelprice] = useState(0)
  const [dprice, setdprice] = useState(0)
  const [number, setnumber] = useState(0)
  const [checkdata, setcheckdata] = useState([]);


  useEffect(() => {
    checkfunc();
    const disableBack = (e) => {
      e.preventDefault();
      window.history.forward();
    };

    // Disable the back button
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', disableBack);

    getchalls();
    getcity();
    getGuaranteeType();
    getEmp();
   getLastorder();
    const newOrderItems = receivedDataArray.map((item, index) => ({
      ItemID: item.ItemID,
      OrderID: lastorder,
      ItemName: item.ItemName,
      TItemID: item.TItemID,
      UserID: userid,
      Quantity: item.Quantity,
      ItemPrice: item.ItemPrice,
      total: item.aa,
      AtbakID: item.AtbakID,
      name:receivedname,
      FoodCategoryID:item.FoodCategoryID
      //TotalAmount:finaltotal,
    }));
    setOrderItems(newOrderItems);
    const totalAA = newOrderItems.reduce((total, item) => total + item.total, 0);
    setTotal1(totalAA);
    const length = orderItems.length
    setOrderItem1(length)


    // const intervalId = setInterval(() => {
    //   setOrderDate(new Date());
    //  }, 1000); // Update every 1000 milliseconds (1 second)

    // Cleanup function to clear the interval when the component unmounts
    //  return () => clearInterval(intervalId);
    return () => {
      // Clean up the event listener
      window.removeEventListener('popstate', disableBack);
    };
  }, [lastorder], [orderItems])
  //const [cusnmae, setcusnmae] = useState('')
  
  const getLastorder = () => {
    try {
      axios.get('https://suhba.server.com.ly/back/api/getLastorder')
        .then(({ data }) => {
          setlastorder(data.Lastorder.OrderID);
          //setcusnmae(data.custname.CustomerName);

          console.log("la", lastorder)
        })
    } catch (error) {
      console.error('Error fetching last customer:', error);
    }
  }
  const createintitem = async (e) => {
    e.preventDefault();
    console.log('order items pefor store', orderItems)
    
    console.log('order pefor store', order)

   
    //getfooditeming(Name);
  //  getLastorder();
    console.log('orderItems pefor store', orderItems)

   
    const SecqueryString = new URLSearchParams({
      data: JSON.stringify(orderItems),
      id: receivedItemId.toString(),
      name:receivedname,
      RefNmber:lastdip,
      DiscountAmount: Discount.toString(),
      lastorder:lastorder,
      TotalAmount:finaltotal,
    }).toString();
    console.log("SecqueryString", SecqueryString)

    navigate(`/NotConfirmedInvoice?${SecqueryString}`)
  }

  console.log("la", lastorder)

 /* const SecqueryString = new URLSearchParams({
    data: JSON.stringify(receivedDataArray),
    id: receivedItemId.toString(),
    DiscountAmount: Discount.toString(),
    lastorder:lastorder,
  }).toString();*/
  const [isRadioChecked5, setIsRadioChecked5] = useState(false);
  const InputChange1 = (e) => {
    const { value } = e.target;
    if (value != '')
   { setIsRadioChecked5(true);}
  else{
    const inputElement5 = document.getElementById('myinput5');
    inputElement5.value = '';
    setdprice(0)
      
      const sum = total1 +0;  
      setfinaltotal(sum)
      setOrder((prevData) => ({
        ...prevData,
       TotalAmount : sum,
      }));
     
    setIsRadioChecked5(false);}
  
    setnumber(value)
   }

   const checkfunc = async () => {
    await axios.get('https://suhba.server.com.ly/back/api/checkfunc').then(({ data }) => {
      
    setcheckdata(data) 
    console.log(data);})

   
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }
  const handleInputChange1 = (e) => {

    const { name, value } = e.target; 
       console.log("name date:", name)

    if (name === 'DeliveryDate') {

    const filteredArrayA = checkdata.find(item => item.DeliveryDate === value);

    // Extract FoodCategoryID values from the filtered array A
    if(filteredArrayA){
      const foodCategoryIDsA = orderItems.find(item => item.FoodCategoryID);
      if(filteredArrayA){

 
     if( (parseInt(foodCategoryIDsA.Quantity) +	parseInt(filteredArrayA.TotalQuantity)) > 400)
      {  swal("لقد تجاوزت الحد المسموح  لطلبيات لهدا التاريح");
      }}}}
      

    
 
    
    setOrder((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log('val', value)
    console.log('na', [name])


    if (name === 'DecoratePrice') {
      setdprice(number * value)
      const integerNumber = parseInt(value, 10);

      const sum = finaltotal + (number * integerNumber);
      setfinaltotal(sum)
      setOrder((prevData) => ({
        ...prevData,
       TotalAmount : sum,
      }));
     
    }

    if (name === 'DelPrice') {
      setdelprice(value)
      const integerNumber = parseInt(value, 10);
      if (total == 0) {
        const sum = total1 + integerNumber;
        setfinaltotal(sum)
        setOrder((prevData) => ({
          ...prevData,
       TotalAmount : sum,
        }));  
         
      }

    }




    if (name === 'HallID') {
      const matchingHall = halls.find((item) => {
        console.log('value:', value);
        console.log('item.HallID:', item.HallID);

        return value == item.HallID;
      });

      if (matchingHall) {
        setOrder((prevData) => ({
          ...prevData,
          Address: matchingHall.HallAddress,
        }));
        setAddress(matchingHall.HallAddress);
        setdelprice(parseInt(matchingHall.ItemPrice, 10))

        const integerNumber = parseInt(matchingHall.ItemPrice, 10);
        if (total == 0) {
          const sum = total1 + integerNumber;
          setfinaltotal(sum)
          setOrder((prevData) => ({
            ...prevData,
         TotalAmount : sum,
          }));
       
        }
        else {

          const sum = total + integerNumber;
          setfinaltotal(sum)
          setOrder((prevData) => ({
            ...prevData,
         TotalAmount : sum,
          }));
       
        }




        console.log('item', total);
      }



    }
  }
  const handleInputChange2 = (e) => {
    const input = e.target.value;

    // Check if the input is a number or an empty string
    if (/^\d*$/.test(input)) {
      setDiscount(input);
      const totalAA = orderItems.reduce((total, item) => total + item.total, 0);
      setTotal1(totalAA);
      if (finaltotal == 0) {
        setTotal(totalAA - input);
      }
      else {
        setTotal(totalAA - input);

        setfinaltotal(finaltotal - input)
        setOrder((prevData) => ({
          ...prevData,
          TotalAmount: finaltotal - input ,
        }));
     
      }
      console.log('t', total1)
      setErrors('')

    } else {
      setErrors("هده الخانه لاتقبل الا الاعداد الصحيحة")

    }
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrder((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  // Assuming you have an array of items you want to add
  console.log(total1)
  // Use a loop to add items to orderItems
  console.log('order new',order)



  const [city, setCity] = useState([]);
  const [emp, setEmp] = useState([]);
  const [guaranteeType, setGuaranteeType] = useState([]);
  const [halls, setHalls] = useState([]);
  const getcity = async () => {
    await axios.get('https://suhba.server.com.ly/back/api/getcity').then(({ data }) => { setCity(data.area) })
  }
  const getEmp = async () => {
    await axios.get('https://suhba.server.com.ly/back/api/getEmp').then(({ data }) => { setEmp(data) })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });

  }
  const [isRadioChecked1, setIsRadioChecked1] = useState(true);
  const [isRadioChecked2, setIsRadioChecked2] = useState(true);
  const [isRadioChecked4, setIsRadioChecked4] = useState(true);
  const [isRadioChecked8, setIsRadioChecked8] = useState(true);

  const [isRadioChecked, setIsRadioChecked] = useState(true);
  const handleInputChange4 = () => {
    setIsRadioChecked4(!isRadioChecked4);


  }
  const handleInputChange8 = () => {
    setIsRadioChecked8(!isRadioChecked8);

  }
  const handleRadioChange1 = () => {
    setIsRadioChecked1(false);
    const inputElement3 = document.getElementById('myinput3');
    inputElement3.value = '';
    setIsRadioChecked2(true);
    setIsRadioChecked(false);
    setAddress('')
    const inputElement1 = document.getElementById('myinput1');
    inputElement1.value = '';
    ///const inputElement = document.getElementById('myInput');
    // inputElement.value = '';
  };
  const handleRadioChange = () => {
    const inputElement = document.getElementById('myinput');
    inputElement.value = '';
    const inputElement1 = document.getElementById('myinput1');
    inputElement1.value = '';
    const inputElement2 = document.getElementById('myinput2');
    inputElement2.value = '';

    setIsRadioChecked(true);
    setIsRadioChecked2(true);
    setIsRadioChecked1(true)
  };
  const handleRadioChange2 = () => {
    const inputElement2 = document.getElementById('myinput2');
    inputElement2.value = '';
    setIsRadioChecked(false);
    setIsRadioChecked2(false);
    setIsRadioChecked1(true)
  };
  const queryString = new URLSearchParams({
    data: receivedDataArray,
    id: receivedItemId,
    //total:total,

  }).toString();
  const getGuaranteeType = async () => {
    await axios.get('https://suhba.server.com.ly/back/api/getGuaranteeType ').then(({ data }) => { setGuaranteeType(data.GuaranteeType) })
  }
  const getchalls = async () => {
    await axios.get('https://suhba.server.com.ly/back/api/getchalls ').then(({ data }) => { setHalls(data.HallName) })
  }
  console.log('order order', orderItems)
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4 order-md-2 mb-4">
                    <h4 className="d-flex justify-content-between align-items-center mb-3">
                      <span className="text-muted">محتويات السلة</span>
                      <Badge as="a" href="" bg="primary badge-circle">
                        <i class="fa fa-shopping-basket me-2"></i> {orderItem1}
                      </Badge>
                      <span className="badge badge-primary badge-pill">

                      </span>
                      {/*<Link to={`/Orders?${SecqueryString}`} className="btn btn-primary">السابق</Link>*/}

                    </h4>
                    <div className="card">

                      <div className="card-body p-0">
                        <div className="table-responsive active-projects dlab-scroll" style={{ height: "200px" }}>
                          <table id="projects-tbl4" className="table">
                            <thead>
                              <tr>
                                <th>اسم الصنف</th>
                                <th>الكمية</th>
                                <th>السعر</th>
                                <th>الغاء</th>
                              </tr>
                            </thead>
                            <tbody>
                              {receivedDataArray.map((item, ind) => (
                                <tr key={ind} hover>
                                  <td>{item.ItemName}
                                  </td>


                                  <td>{item.Quantity}</td>
                                  <td>{item.aa}د</td>
                                  <td>{item.aa}د</td>

                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        <div class="input-group" style={{ direction: "ltr" }}>
                          <input type="text" style={{ direction: "rtl" }}
                            value={Discount}
                            onChange={handleInputChange2}
                            name="DiscountAmount"
                            required
                            class="form-control" ></input>

                          <button type="" class="input-group-text">التخفيض</button>
                        </div>
                        {errors ? <div className=' alert alert-danger'>{errors}</div> : ""}

                        <table className="table table-clear">
                          <tbody>
                            <tr>
                              <td className="left">
                                <strong>الحسابى الكلي</strong>
                              </td>
                              <td className="right">{total1}د</td>
                            </tr>
                            <tr>
                              <td className="left">
                                <strong>الحسابى بعد التخفيض{Discount}د</strong>
                              </td>
                              <td className="right">{total}د</td>
                            </tr>
                            <tr>
                              <td className="left">
                                <strong>خدمة توصيل</strong>
                              </td>
                              <td className="right">{delprice}د</td>
                            </tr>
                            <tr>
                              <td className="left">
                                <strong>خدمة التزين  </strong>
                              </td>
                              <td className="right">{dprice}د</td>
                            </tr>
                            <tr>
                              <td className="left">
                                <strong>الحسابى النهائي</strong>
                              </td>
                              <td className="right">
                                <strong>{finaltotal}د</strong>
                                <br />
                              </td>
                            </tr>
                          </tbody>
                        </table>


                      </div>
                    </div>

                  </div>
                  <div className="col-md-8 order-md-1">
                    <h4 className="mb-3">طلبية جديدة</h4>
                    <form className="needs-validation" noValidate="" onSubmit={createintitem}>
                      <div style={{ height: "400px" }}>

                       
                        <div className="row">
                          <div className="col-xl-12 ">

                            <fieldset className="form-group">
                              <div className="row ">

                                <div className="col-sm-4">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="gridRadios"
                                      value="option1"
                                      onChange={handleRadioChange}
                                      defaultChecked
                                    />
                                    <label className="form-check-label">
                                      الاستلام من المحل
                                    </label>
                                  </div> </div>
                                <div className="col-sm-4">

                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="gridRadios"
                                      value="option2"

                                      onChange={handleRadioChange2} />

                                    <label className="form-check-label">
                                      الاستلام من الصالة
                                    </label>
                                  </div>

                                </div>
                                <div className="col-sm-4">



                                  <div className="">
                                    <div className="row">
                                      <div className="col-xl-7 mb-4">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="gridRadios"
                                          value="option2"

                                          onChange={handleRadioChange1} />

                                        <label className="form-check-label">
                                          الى العنوان </label></div>
                                      <div className="col-xl-5 mb-4">

                                        <input type="text" hidden={isRadioChecked1} id="myinput3" className="form-control" name="DelPrice" defaultValuee={order.DelPrice} onChange={handleInputChange1} />
                                      </div></div>
                                  </div>

                                </div>
                              </div>
                            </fieldset>
                          </div>

                        </div>
                        <div className="row">

                          <div className=" col-xl-12 mb-4">
                            <div className="row">
                              <div className="col-xl-2 mb-1">

                                <label className="form-label " htmlFor="address">العنوان</label>
                              </div>
                              <div className="col-xl-4 mb-1">

                                <input type="text" disabled={isRadioChecked1} className="form-control" id="myinput2" name="Address" defaultValuee={order.Address} onChange={handleInputChange1} />
                              </div>
                              <div className="col-xl-2 mb-1">
                                <label className="form-label ">المندوب</label>

                              </div>
                              <div className="col-xl-4 mb-1">

                                <select className="default-select form-control" id="myinput" disabled={isRadioChecked} name="DeliveryPersonID" defaultValuee={order.EmployeeID} onChange={handleInputChange1}>
                                  <option data-display="Select">اختر من القائمة</option>
                                  {
                                    emp.map((row) => (
                                      <option key={row.EmployeeID} value={row.EmployeeID}>
                                        {row.FirstName}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>


                          </div>

                        </div>





                        <div className="row">
                          <div className="col-xl-12 mb-4">
                            <div className="row">

                              <div className="col-xl-2 mb-1">
                                <label className="form-label ">قاعة الافراح</label>

                              </div>
                              <div className="col-xl-4 mb-1">

                                <select className="default-select form-control " disabled={isRadioChecked2}
                                  onChange={handleInputChange1} id="myinput1" name="HallID" >
                                  <option data-display="Select">اختر من القائمة</option>
                                  {
                                    halls.map((row) => (
                                      <option key={row.HallID} value={row.HallID}>
                                        {row.HallName}
                                      </option>
                                    ))}
                                </select>   </div>
                              <div className="col-xl-2">
                                <label htmlFor="state">عنوانها</label>
                              </div>
                              <div className="col-xl-4 mb-1">

                                <label className="form-label "> {address} </label>


                              </div>
                            </div>
                          </div>
                        </div>
                    
                  
                      <fieldset className="form-group">
                      <div className="row ">
                       
                       
                        <div className="col-sm-6">



<div className="">
<div className="row">
    <div className="col-xl-4 mb-4">
  <input
    className="form-check-input"
    type="checkbox"
    name="gridRadios"
    value="option2"
 
  onChange={handleInputChange4}                            />

  <label className="form-check-label me-2">
  تزين الصحن </label></div>
<div className="col-xl-8 mb-4">
<div className="row">

<div className="col-xl-2 mb-1">

<label hidden={isRadioChecked4} className="form-check-label">
لعدد</label></div>
<div className="col-xl-4 mb-1">

<input type="text"
required hidden={isRadioChecked4} id="myinput4" className="form-control" name="DecoratePrice" defaultValuee={order.DecoratePrice} onChange={InputChange1}  />
</div>
<div className="col-xl-2 mb-1">

<label hidden={isRadioChecked4} disabled={!isRadioChecked4} className="form-check-label">
بسعر</label></div>
<div className="col-xl-4 mb-1">

<input required type="text"disabled={!isRadioChecked5}  hidden={isRadioChecked4} id="myinput5" className="form-control" name="DecoratePrice" defaultValuee={order.DecoratePrice} onChange={handleInputChange1}  />
</div>
</div></div>

</div>
</div>

</div>
                      </div>
                    </fieldset>
                    <div className="row">

                    <div className="col-xl-12 mb-4">
                              <div className="row">
          <div className="col-xl-2 mb-1">
        <label htmlFor="exampleFormControlInput99" className="form-label" >العربون <span className="text-danger">*</span></label>                                    
        </div><div className="col-xl-4 mb-1">
        <input
                                       type="text"
                                       className="form-control ms-3 " style={{float: "right"}}
                                       id="same-address"    Name="Prepay"

                                       defaultValuee={order.Prepay} onChange={handleInputChange1}
                                    />
        </div>
        <div className="col-xl-2 mb-1">
        <label htmlFor="exampleFormControlInput99" className="form-label" >ملاحظات <span className="text-danger">*</span></label>                                    
        </div><div className="col-xl-4 mb-1">
        <textarea className="form-control ms-3 " id="exampleFormControlTextarea1" rows="3"
          defaultValuee={order.Paystatus} onChange={handleInputChange1}Name="Paystatus"

                                        ></textarea>
       
     
        </div>
        
        
        </div>
        </div>
  </div>    </div>
                      <hr className="mb-4" />


                      <Link
                        className="btn btn-primary btn-lg btn-block"
                        to="#" onClick={createintitem}>

                        التالي
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderCheckout;
