import React from 'react';
import ReactDOM from 'react-dom/client';
 import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import  ThemeContext  from "./context/ThemeContext"; 
   import router from './router.js'
import { RouterProvider } from 'react-router-dom'
   import {ContextProvider} from './context/ContextProvider.js'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode> 
    <ContextProvider>
        <ThemeContext >          
    <Provider store = {store} >
  <RouterProvider router={router} />

      <BrowserRouter basename=''>
         
       

        
      </BrowserRouter>
    </Provider>        
    </ThemeContext>
 </ContextProvider>    
  </React.StrictMode>
);



reportWebVitals();
