
        import React, { useState, forwardRef, useImperativeHandle  ,useEffect} from 'react';
        import {Modal} from 'react-bootstrap';
         import { Dropdown } from 'react-bootstrap';
         import axios from "axios";
          import { useNavigate ,Link} from "react-router-dom";
          import swal from 'sweetalert';
        const InviteCustomer = forwardRef((props, ref) => {
            const [inviteModal , setInviteModal] = useState(false);
            const [TabakName,setTabakName] = useState('')
            const [Quantity,setQuantity] = useState('')
            const [TabakPrice,setTabakPrice]= useState('')
           const [TabakDate,setTabakDate] = useState('')
           const [OriginalPrice,setOriginalPrice]= useState(0)
           const [error, setError] = useState('');

//---AtbakID';
const [issaved, setissaved] = useState(false)

            const [UserID, setUserID] = useState('')
            const [ItemID, setItemID] = useState();
             const [data, setData] = useState([])
             const [FoodItem, setFoodItem] = useState([])

        console.log(props)
          useImperativeHandle(ref, () => ({
            showInviteModal() {
                setInviteModal(true)
            }}));
        
           const closeInviteModal = ()=> {
              setTotal(0)
            setOriginalPrice(0)
            setTabakPrice('');
           setTabakName('')
            setQuantity('') 
            setlistItem('')  
            setMessage('')  
  setInviteModal(false)
}
useEffect(() => {
  setOriginalPrice(0)
  console.log('total',total);
  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const form = e.target.form;
      const fields = Array.from(form.elements).filter(el => (
        (el.tagName === 'INPUT' && !el.disabled) ||
        (el.tagName === 'TEXTAREA' && !el.disabled) ||
        (el.tagName === 'SELECT' && !el.disabled)
      ));      const currentFieldIndex = fields.findIndex(el => el === document.activeElement);
      const nextFieldIndex = currentFieldIndex + 1;
      if (nextFieldIndex < fields.length) {
        fields[nextFieldIndex].focus();
      }
    }
  };
  
  document.addEventListener('keydown', handleEnterKey);
  
  return () => {
    document.removeEventListener('keydown', handleEnterKey);
  };
}, []);
            
        
                

            /////////////////////////////////////
            const [color, setColor] = useState('blue')
        
                     const [tableData, setTableData] = useState([]);
                   
                
                     const [lastorder, setlastorder] = useState(0)

                    
                    // console.log(formData);
                    const getitems = () => {
                      axios.get('https://suhba.server.com.ly/back/api/getfood')
                       .then(({ data }) => {
                          setFoodItem(data.FoodItem)
                       })}
                    
                    ///////////////////////////////////////////////
             
        
                 const [currentPage , setCurrentPage] = useState(1);
                 const recordsPage = 8;
                 //const lastIndex = currentPage * recordsPage;
                // const firstIndex = lastIndex - recordsPage;   
                // const records = data.slice(firstIndex, lastIndex);
               //  const npage = Math.ceil(data.length / recordsPage)
             //    const number = [...Array(npage + 1).keys()].slice(1)
             const input = document.querySelector('input[name="city"]');
             if(input)
        {
             input.addEventListener('invalid', function (event) {
               if (event.target.validity.valueMissing) {
                 event.target.setCustomValidity('الرجاء ادخال اسم الطبق');
               } 
             })
             
             input.addEventListener('change', function (event) {
               event.target.setCustomValidity('');
             })}
             const handleDelete = (index) => {
              // Create a copy of the list
              const updatedList = [...listItem];
              
              // Remove the item at the specified index
              updatedList.splice(index, 1);
            //  const qunAsNumber = parseInt(item.ItemPrice, 10) || 0
            const totalAA = updatedList.reduce((total, item) => total + parseInt(item.ItemPrice), 0);
              setTotal(totalAA);
              setOriginalPrice(totalAA)
              console.log(totalAA)
              setlistItem(updatedList);
            };
             const listClickHandler = (itemid) => {
                   
               FoodItem.map((item) => {
                if (itemid == item.ItemID) {
                //  console.log(item.ItemID)
                
                setlistItem((prevListItem) => {
                  const updatedList = [...prevListItem, {
                    ItemName: item.Name,
                    ItemID: itemid,
                    ItemPrice: item.ItemPrice,
                    UserID: userid
                  }];
                  return updatedList;
                });
                        console.log(listItem)
              const qunAsNumber = parseInt(item.ItemPrice, 10) || 0; // Convert qun to a number, defaulting to 0 if conversion fails
                  setTotal(total+qunAsNumber)
                  const totalAA = listItem.reduce((total, item) => total + parseInt(item.ItemPrice), 0);

console.log("totalAA",totalAA)

                 }
               })

       
 }    

      
              useEffect(() => {
                getitems();
              

            },[])
            const userdata1 = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata1);
    const userid = userData.id;
            const getfet = () => {

            const newOrderItems = listItem.map((item, index) => ({
              AtbakID:lastorder,
            ItemID :item.ItemID,
               UserID:userid,
              
               }));
               setTableData(newOrderItems);
              console.log('newOrderItems',newOrderItems) 
              }
            const getLasttabak = () => {
              try {
                axios.get('https://suhba.server.com.ly/back/api/getLasttabak')
                .then(({ data }) => {
                 console.log(data.AtbakID)
                 const newOrderItems = listItem.map((item, index) => ({
                  AtbakID:data.AtbakID,
                 ItemID :item.ItemID,
                    UserID:userid,
                   
                    }));
                    //setTableData(newOrderItems);
                    console.log('newOrderItems',newOrderItems)
                     axios.post('https://suhba.server.com.ly/back/api/tabak_items',{newOrderItems}) .catch(function (error) {
                      if (error.response) {
                         console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                      } else if (error.request) {
                        console.log(error.request);
                      } else {
                        console.log('Error', error.message);
                      }
                      console.log(error.config);
                    });
                                props.parentFunction()  
setOriginalPrice(0)
setTotal(0)
                                setTabakPrice('');
                               setTabakName('')
                                setQuantity('') 
                                setlistItem('')  
                                setMessage('')
                                                                                         closeInviteModal()

                               
                })
                        } catch (error) {
                console.error('Error fetching last customer:', error);
              }}
  
                
            const createEmployee = async(e)=>{
              e.preventDefault();
              const formData = new FormData();
              formData.append('TabakName', TabakName);
              formData.append('TabakPrice', TabakPrice);
              formData.append('OriginalPrice', total);
              formData.append('QuantityInStock', Quantity);

              formData.append('Quantity', Quantity);
              formData.append('UserID', userid);
              formData.append('TabakDate', "2024-01-31");

              formData.append('ItemID', ItemID);
              console.log('form',formData)
              await axios.post('https://suhba.server.com.ly/back/api/Atbaks', formData)
              .then(res=>{
                  if(res.data.status === 200)
                  swal("عذرا",res.data.message,"warning");
              } ) 
                
              setTotal(0)
              getLasttabak();
            // getfet();
 
           //   getcities();
 
 
            } 

            const [message, setMessage] = useState(null)
        
            const lastIndex = currentPage * recordsPage;
            const firstIndex = lastIndex - recordsPage;   
            const records = data.slice(firstIndex, lastIndex);
            const npage = Math.ceil(data.length / recordsPage)
            const number = [...Array(npage + 1).keys()].slice(1)
            const [listItem, setlistItem] = React.useState([])

          
         
                  const [total, setTotal] = useState(0)
                  const isValidInput = (inputValue) => {
                    // Regular expression to match valid numeric inputs including a single decimal point
                    const validNumberPattern = /^\d*\.?\d*$/;
                  
                    // Allow an empty string
                    if (inputValue === '') return true;
                  
                    // Test the pattern and ensure there's at most one decimal point
                    const isValidFormat = validNumberPattern.test(inputValue);
                    const decimalPointCount = (inputValue.match(/\./g) || []).length;
                  
                    return isValidFormat && decimalPointCount <= 1;
                  };
                  const handleInputChange2 = (e) => {
                    const { name, value } = e.target;
                    setError('');
                  
                    // Validate and update state based on the input field name
                    switch (name) {
                      case "Quantity":
                        if (value === '' || /^\d+$/.test(value)) {
                          setQuantity(e.target.value)
                          setError('');
                        } else {
                          setError('الرجاء ادخال قيمة عددية صحيحة');
                        }
                        break;
                  
                      case "TabakPrice":
                        if (isValidInput(value)) {
                          setTabakPrice(e.target.value)
                                                    setError('');
                        } else {
                          setError('الرجاء ادخال قيمة عددية');
                        }
                        break;
                  
                      case "TabakName":
                        setTabakName(e.target.value)
                        break;
                        case "TabakDate":
                          setTabakDate(e.target.value)
                                                    break;
                      case "ItemID":
                        setItemID(e.target.value)
                         break;
                  
                      default:
                        break;
                    }
                  };
                 
            return(
                <>
                    <Modal className="modal fade" id="exampleModal1" show={inviteModal}  centered>   
                                                  <form onSubmit={createEmployee}>

                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel1">أسماء الادوات</h1>
                                <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={()=> closeInviteModal()}></button>
                            </div>
                            <div className="modal-body py-2 px-4 ">
                                <div className="row">
                                    <div className="col-xl-12">
                                    <div style={{paddingRight :'10px',color:'red'}}> {error}</div>

                                        <div className="row">
                                            
                                            <div className="col-xl-6">
                                                <label className="form-label mt-3 ">اسم الطبق</label> 
                                                     <input style={{  height: "2rem" }} 
                                                    type="text" 
                                                    pattern="[a-zA-Z\u0600-\u06FF\s]+"
                                                    title="الرجاء إدخال أحرف فقط، الفراغات، والحروف العربية"
                                                    required 
                                                    id="inputcity"
                                                      name="TabakName"
                                                    className="form-control  " 
                                                      value={TabakName}
                                                     onChange={handleInputChange2}/>
                                                  </div>
                                                  <div className="col-xl-6">
                                                <label className="form-label mt-3 ">الكمية </label> 
                                                  
                                                     <input style={{  height: "2rem" }} 
                                                    type="text" 
                                                    pattern="[0-9]+"
                                                    title="هدا الحقل لا يقبل الا الارقام"
                                                    required
                                                    id="inputcity"
                                                      name="Quantity"
                                                    className="form-control " 
                                                      value={Quantity}
                                                     onChange={handleInputChange2}/>
                                                </div>   
                                                 <div className="col-xl-6 mb-1"> 
                                                        <label htmlFor="exampleFormControlInput99" className="form-label mt-3 " >  التاريخ     <span className="text-danger">*</span></label>                                    

        <input
                                       type="date"
                                       className="form-control  " style={{float: "right"}}
                                       id="same-address"    Name="TabakDate"
                                       onChange={handleInputChange2}/>

                                  
                                                </div> 
                                                 <div className="col-xl-6">
                                <label className="form-label mt-3">الصنف</label>
                             
                                <select className="default-select form-control" name="ItemID"  
                                                                                        id="inputcity"
                               required   onChange={handleInputChange2}> 

                                 <option  data-display="Select">اختر من القائمة</option>
                                 {
                                   FoodItem.map((row)=>(
                                  <option key={row.ItemID} value={row.ItemID}>
                                            {row.Name}
                                    </option>
                                     ))}    
                                 </select> 
                                   </div>

 <div className="col-xl-6">
                                                <label className="form-label mt-3 ">السعر </label> 
                                                 
                                                     <input style={{  height: "2rem" }} 
                                                    type="text" 
                                                    pattern="[+-]?\d+(\.\d+)?"
                                                    title="هدا الحقل لا يقبل الا الارقام"
                                                    required                                                    id="inputcity"
                                                      name="TabakPrice"
                                                      
                                                    className="form-control  " 
                                                      value={TabakPrice}
                                                     onChange={handleInputChange2}/>
                                                         </div>
                                                         <div className="col-xl-6">
                                                <label className="form-label mt-3 "> السعر الاصلي  </label> 
                                                 
                                                     <input style={{  height: "2rem" }} 
                                                    type="text" 
                                                    disabled="false"

                                                
                                                    id="inputcity"
                                                      name="city"
                                                    className="form-control  " 
                                                      value={total}
                                                    />
                                                         </div>

                                                <div className="col-xl-12">

                                                       <button   className="btn btn-primary btn-lg btn-block mt-3"
                                                       onClick={() => listClickHandler(ItemID)}
                                                       style={{  padding: "0.5rem 5rem" , fontsize:" 0.90rem !important",  margintop: "10px" }}> اضف </button>
 
 

                                                                                                 </div>

                                              
                                                </div>

                                             
         
                                        <div className="table-responsive active-projects task-table my-2 mx-2" style={{  height: "2غ00px" }}>
                                        <table className="table">
                                    <thead>
                                        <tr className="center" >
                                           
                                            <th scope="col">الصنف</th>
                                            <th scope="col">السعر</th>

                                         </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listItem.length > 0 && (
                                                listItem.map((row,key)=>(
                                                    <tr key={key}> 
                                                     <td>  {row.ItemName}   </td>
                                                     <td> {row.ItemPrice}</td>
                                                     <td>  <Link  onClick={() => handleDelete(key)}><i class="fas fa-trash-alt"></i></Link></td>

                                                    </tr> 
                                                ))
                                            )
                                        }
         
                                    </tbody>
                                </table>
                                                </div>
        
                                    </div>
                                </div>                                
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary " >حفظ</button>
                                <button type="button" className="btn btn-danger " onClick={()=> setInviteModal(false)}>الغاء</button>

                             
                             </div>

                        </div> 
               </form>
                    </Modal>
                </>
            )
        })
        export default InviteCustomer;