//-------احصائيات
 import { SVGICON } from '../jsx/constant/theme';
 import Sts from './Statistices';
import InviteCustomer from '../jsx/constant/AddTools';
import InviteCustomer1 from './toolReport';


//----------------
import React, { useEffect, useState ,useRef} from "react";
import { Link,useNavigate } from "react-router-dom";
  import axios from "axios";


const Inbox = () => {
  const navigate = useNavigate();
  const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');
const userPermissions = JSON.parse(userPermissionsJSON);
function getActionsForPage(pageName) {
  const pagePermissions = userPermissions.find(permission => permission.resource === pageName);
console.log('actionsForPage',pagePermissions); 
 if (pagePermissions) {
     return pagePermissions.action;
 } else {
navigate('/dashboard_1')
    return [];
 }
}
const pageName = 'Tools';
const actionsForPage = getActionsForPage(pageName);

//-------------
//----بوب اب
const invite = useRef();const invite2 = useRef();

const [data, setData] = useState([])

    useEffect(() => {
        fetchProducts();
    }, [])

    const fetchProducts = async () => {
        await axios.get('https://suhba.server.com.ly/back/api/tools_items').then(({ data }) => {setData(data)}) 

        /*catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
          });*/
    }
//----------------
  return (
   <>
       
        <div className="container-fluid ">
          <div className="row">
          

                            

                    <div className="card">            
                <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">   

                 <div className="tbl-caption">
                            <h4 className="heading mb-0">المعدات</h4>
                            <div>
                            {(actionsForPage.includes('create') || actionsForPage.includes('edit')) && (                                 <Link to={"#"} className="btn btn-primary btn-sm ms-1" data-bs-toggle="offcanvas"
                                 onClick={() => invite.current.showInviteModal()}
                                >+ إضافة / تعديل المعدات</Link> 
                            )}
                             {actionsForPage.includes('export')  && (                         
                                      <Link to={"/ToolReport"} className="btn btn-secondary btn-sm ms-1" data-bs-toggle="offcanvas"
                               
                                > تقارير المعدات</Link> 
                            )}
                               {/* <Link to={"/ToolsReport"} className="btn btn-secondary btn-sm ms-1" data-bs-toggle="offcanvas">
                                 
                                تقارير المعدات</Link>*/}
 
                                
                            </div>
                        </div>  
                        </div>   </div> </div> 
            <div className="col-lg-12">    
              <div className="card mb-0 h-auto">
                <div className="card-body py-0 pe-0">
                  <div className="row gx-0">
                    
                         <div className="email-left-box dlab-scroll  pt-3 ps-0">


                         <div className="row mt-2">  



          <div className="row mt-2">    
          {
                                    data.length > 0 && (
                                        data.map((row,key)=>(
                                      <div className="col-xl-2 col-xxl-3 col-md-4 col-sm-6" key={key}>
                                          <div className="card">
                                         <div className="card-body">
                                         <div className="col-lg-12"> 
                                           <div className="row">
                                           <span className="item">
      {row.status === 'غير متوفر' ? (
        <i className="fa-solid fa-circle-xmark text-danger me-2" />
      ) : (
        <i className="fa fa-check-circle text-success me-2" />
      )} 
    </span>
                                             <div className="col-sm-6">    
                                             <div className="new-arrival-content text-right ">
                                               <h6>
                                               <span className="price" >{row.TName}</span> </h6>
                                                </div> 
                                                </div>

                                               <div className="col-sm-6"> 
                                               <div className="new-arrival-content text-right ">
                                       
                                               <h6> <span className="price" > {row.TItemPrice}د </span> </h6>        
                                                </div> </div>
                                               
                                                 
                                    
                                               <div className="col-sm-6 ">   
                                               <h3>
                                               <span className="item" >الكمية</span> </h3>
                                                                    
                                                 </div> 
                                                 <div className="col-sm-6"> 
                                       
                                               <h3> <span className="item" > {row.Quantity} </span> </h3>        
                                               </div>
                                               <div className="col-sm-6 ">   
                                               <h3>
                                               <span className="item" >المتوفر</span> </h3>
                                                                    
                                                 </div> 
                                                 <div className="col-sm-6"> 
                                       
                                               <h3> <span className="item" > {row.QuantityInStock} </span> </h3>        
                                               </div>                 
                                                     </div>
                                    
                                              </div>
                                            </div>
                                       </div> 
                                     
                                    </div>
                                        ))
                                    )
                                }
                 
                               

                        </div>



    </div>

                        </div>
                  
                 
                       
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <InviteCustomer
                ref={invite}     
                parentFunction= {fetchProducts}
            /> 
            <InviteCustomer1
                ref={invite2}     
            /> 
</>
  );
};

export default Inbox;

