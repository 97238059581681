
        import React, { useState, forwardRef, useImperativeHandle  ,useEffect} from 'react';
        import {Modal} from 'react-bootstrap';
         import { Dropdown } from 'react-bootstrap';
         import axios from "axios";
          import { useNavigate ,useParams} from "react-router-dom";
          import swal from 'sweetalert';
        const InviteCustomer = forwardRef((props, ref) => {
            const [inviteModal , setInviteModal] = useState(false);
            const [TName,setTName] = useState('')
            const [QuantityInStock,setQuantityInStock] = useState('')
            const [TItemPrice,setTItemPrice]= useState('')
           // const [AreaName,setAreaName] = useState('')

            const [UserID, setUserID] = useState('')
            const [status, setStatus] = useState();
             const [data, setData] = useState([])
          //  const { AreaID } = useParams();
          const navigate = useNavigate();
        
          useImperativeHandle(ref, () => ({
            showInviteModal() {
                setInviteModal(true)
            }}));
        
            const userdata = localStorage.getItem('USER_data');
            const userData = JSON.parse(userdata);
              const userid = userData.id;
              useEffect(() => {
                const handleEnterKey = (e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    const form = e.target.form;
                    const fields = Array.from(form.elements).filter(el => (
                      (el.tagName === 'INPUT' && !el.disabled) ||
                      (el.tagName === 'TEXTAREA' && !el.disabled) ||
                      (el.tagName === 'SELECT' && !el.disabled)
                    ));      const currentFieldIndex = fields.findIndex(el => el === document.activeElement);
                    const nextFieldIndex = currentFieldIndex + 1;
                    if (nextFieldIndex < fields.length) {
                      fields[nextFieldIndex].focus();
                    }
                  }
                };
                
                document.addEventListener('keydown', handleEnterKey);
                
                return () => {
                  document.removeEventListener('keydown', handleEnterKey);
                };
              }, []);
               

            /////////////////////////////////////
            const [color, setColor] = useState('blue')
        
                     const [tableData, setTableData] = useState([]);
                   
                   const handleEdit = async (id, field, value) => {
                    console.log('field',field)
           setColor('red')
                     const updatedData = tableData.map((row) =>
                      row.id === id ? { ...row, [field]: value } : row
                     );
                     setTableData(updatedData);
                     const formData = new FormData();
                     formData.append('_method', 'PATCH')
                    formData.append('TItemID', id);
                     formData.append('TName', value);
                     formData.append('UserID', userid);
                     if(field === 'Quantity')
                      {
                        const error = await axios.put(`https://suhba.server.com.ly/back/api/tools_items/${id}`, {
                          ['QuantityInStock']: value, 
                          ['Quantity']: value, 

                         })
                          .catch(function (error) {
                  if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                  }
                  console.log(error.config);
                });
                getcities();
                props.parentFunction()
                return;   }
                   const error = await axios.put(`https://suhba.server.com.ly/back/api/tools_items/${id}`, {
                    [field]: value,  })
                    .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
          });
          getcities();

          props.parentFunction()
           
                     } 
                    // console.log(formData);
        
                    
                    ///////////////////////////////////////////////
              const getcities = () => {
                axios.get('https://suhba.server.com.ly/back/api/tools_items')
                 .then(({ data }) => {
                    setData(data)
                 })}
        
                 const [currentPage , setCurrentPage] = useState(1);
                 const recordsPage = 8;
                 //const lastIndex = currentPage * recordsPage;
                // const firstIndex = lastIndex - recordsPage;   
                // const records = data.slice(firstIndex, lastIndex);
               //  const npage = Math.ceil(data.length / recordsPage)
             //    const number = [...Array(npage + 1).keys()].slice(1)
             const input = document.querySelector('input[name="city"]');
             if(input)
        {
             input.addEventListener('invalid', function (event) {
               if (event.target.validity.valueMissing) {
                 event.target.setCustomValidity('الرجاء ادخال اسم الاداة');
               } 
             })
             
             input.addEventListener('change', function (event) {
               event.target.setCustomValidity('');
             })}
                
             
                
              useEffect(() => {
                getcities();
                
                const myElement = document.getElementById('myElementId');
                if(myElement){
        
                if (selectedItem  === 'متوفر') {
                  myElement.className = 'Complete';
                }else{
                    myElement.className = 'Pending';
                }}               
        
            }, [])
            const createEmployee = async(e)=>{
                e.preventDefault();
                const formData = new FormData();
                formData.append('TName', TName);
                formData.append('TItemPrice', TItemPrice);
                formData.append('QuantityInStock', QuantityInStock);
                formData.append('Quantity', QuantityInStock);
                formData.append('UserID', userid);

                formData.append('status', "متوفر");
                await axios.post('https://suhba.server.com.ly/back/api/tools_items', formData)
                .then(res=>{
                    if(res.data.status === 200)
                    swal("عذرا",res.data.message,"warning");
                } ) 
                  
              //  const myElement = document.getElementById('inputcity');
              
                //   myElement.value = '';
                  
                getcities();
                props.parentFunction()
                setTItemPrice('');
                setTName('')
                setQuantityInStock('')
              } 
            const [message, setMessage] = useState(null)
        
            const lastIndex = currentPage * recordsPage;
            const firstIndex = lastIndex - recordsPage;   
            const records = data.slice(firstIndex, lastIndex);
            const npage = Math.ceil(data.length / recordsPage)
            const number = [...Array(npage + 1).keys()].slice(1)
           
            const [statusPriority, setStatusPriority] = useState([]);
          
            const handleAction =  async(id, field, value)	=> {
                    let temp = statusPriority.map((data) => {
              if (id === data.id) {
                return { ...data, status: value };
              }
              return data;
            });
            setStatusPriority(temp);
                const formData = new FormData();
                formData.append('_method', 'PATCH')
               formData.append('TItemID', id);
                formData.append('status', value);
                formData.append('UserID',userid);
                console.log(formData)
                console.log(id);
            const error = await axios.put(`https://suhba.server.com.ly/back/api/tools_items/${id}`, {
               [field]: value,  });
                   if (error.response) {
                     console.log(error.response.data);
                     console.log(error.response.status);
                     console.log(error.response.headers);
                   } else if (error.request) {
                     console.log(error.request);
                   } else {
                     console.log('Error', error.message);
                   }
                   console.log(error.config);
                   getcities();

                   props.parentFunction()
                  } 
                  const [selectedItem, setSelectedItem] = useState();
        
                  const handleSelect = (eventKey, rowIndex) => {
                    const updatedData = [...data];
                    updatedData[rowIndex].status = eventKey;
                    setSelectedItem(eventKey) 
                    console.log(eventKey)
        
                               setData(updatedData);console.log(rowIndex);
                  };
            return(
                <>
                    <Modal className="modal fade" id="exampleModal1" show={inviteModal}  centered>                
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel1">أسماء الادوات</h1>
                                <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={()=> setInviteModal(false)}></button>
                            </div>
                            <div className="modal-body py-2 px-4 ">
                                <div className="row">
                                    <div className="col-xl-12">
                                    <form  onSubmit={createEmployee}>
        
                                        <div className="row">
                                            <div className="col-xl-12">
                                            <div className="row">
                                                
                 
                                            </div>
                                            <div className="row">
                                            <div className="col-xl-2">
                                                <label className="form-label mt-3 ">اسم الاداة</label> 
                                                 </div>
                                                <div className="col-xl-4">
                                                <div className="input-group ">
                                                    <input style={{  height: "2rem" }} 
                                                    type="text" 
                                                    required  
                                                    id="inputcity"
                                                      name="city"
                                                    className="form-control mt-3" 
                                                      value={TName}
                                                     onChange={(e)=>{setTName(e.target.value)}}/>
                                                </div>   </div>
                                                <div className="col-xl-2">
                                                <label className="form-label mt-3 ">السعر </label> 
                                                 </div>
                                                <div className="col-xl-4">
                                                <div className="input-group ">
                                                    <input style={{  height: "2rem" }} 
                                                    type="text" 
                                                    required  
                                                    id="inputcity"
                                                      name="city"
                                                    className="form-control mt-3" 
                                                      value={TItemPrice}
                                                     onChange={(e)=>{setTItemPrice(e.target.value)}}/>
                                                </div>   </div>
                                                </div> 
                                                <div className="row">
                                                <div className="col-xl-2">
                                                <label className="form-label mt-3 ">الكمية </label> 
                                                 </div>
                                                <div className="col-xl-8">
                                                <div className="input-group ">
                                                    <input style={{  height: "2rem" }} 
                                                    type="text" 
                                                    required  
                                                    id="inputcity"
                                                      name="city"
                                                    className="form-control mt-3" 
                                                      value={QuantityInStock}
                                                     onChange={(e)=>{setQuantityInStock(e.target.value)}}/>
                                                </div>   </div>

                                                  <div className="col-xl-1 pe-0" style={{ paddingTop:"0.375rem", width: "50px"}}>
                                                      <button  id="toastr-success-top-left" className="btn btn-primary mt-2 me-2 btn-icon-xs">  +  </button>
                                                </div>
                                              
                                                </div>

                                            </div>
                                            
                                        </div>
        
                                        </form>
                                        <div className="table-responsive active-projects task-table my-2 mx-2" style={{  height: "400px" }}>
                                        <table className="table">
                                    <thead>
                                        <tr className="center" >
                                            <th scope="col">اسم الاداة</th>
                                            <th scope="col">الحالة</th>
                                            <th scope="col">السعر</th>
                                            <th scope="col">الكمية</th>
                                         </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.length > 0 && (
                                                data.map((row,key)=>(
                                                    <tr key={key}> 
                                                        
                                                        <td style={{  color: {color} }}
                        contentEditable
                         onBlur={(e) => handleEdit(row.TItemID, 'TName', e.target.textContent)}
                      >
                      {row.TName}
                      
                                                            
                                                            
                                                            
                                                             </td>
                                                             <td>     
                                                             <Dropdown  className="task-dropdown-2" onSelect={(eventKey, event) => handleSelect(eventKey, key)}>
                                                                                    <div>
                                                                { row.status ==="متوفر" ?(
                                                               <Dropdown.Toggle  id="myElementId" as="div" className="Complete">
                                                               {row.status ? ` ${row.status}` : 'Select an option'}</Dropdown.Toggle>
        
                                                                       ) : (
        
                                                                        <Dropdown.Toggle id="myElementId" as="div" className="Pending">
                                                                        {row.status ? ` ${row.status}`: 'Select an option'}</Dropdown.Toggle>
        
                                                                    ) }
                                                                    </div>
                                        
                                                                        
                                                                        <Dropdown.Menu className='task-drop-menu'>
                                          <Dropdown.Item eventKey="غير متوفر" onClick={()=>handleAction(row.TItemID,'status','غير متوفر')}>غير متوفر</Dropdown.Item>
                                          <Dropdown.Item eventKey="متوفر" onClick={()=>handleAction(row.TItemID,'status','متوفر')}>متوفر</Dropdown.Item>
         
                                        </Dropdown.Menu>
                                      </Dropdown>
                                                                        </td>
                                                                        <td style={{  color: {color} }}
                        contentEditable
                         onBlur={(e) => handleEdit(row.TItemID, 'TItemPrice', e.target.textContent)}
                      >
                      {row.TItemPrice}
                      
                                                            
                                                            
                                                            
                                                             </td>   
                                                             <td style={{  color: {color} }}
                        contentEditable
                         onBlur={(e) => handleEdit(row.TItemID, 'Quantity', e.target.textContent)}
                      >
                      {row.QuantityInStock}
                      
                                                            
                                                            
                                                            
                                                             </td>        
                                                    </tr> 
                                                ))
                                            )
                                        }
         
                                    </tbody>
                                </table>
                                                </div>
        
                                    </div>
                                </div>                                
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger light" onClick={()=> setInviteModal(false)}>اغلاق</button>
                             </div>
                        </div>               
                    </Modal>
                </>
            )
        })
        export default InviteCustomer;