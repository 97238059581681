import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link ,useNavigate} from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";

import { IMAGES } from '../jsx/constant/theme';
import { ToastContainer, toast } from "react-toastify";


const Company = () => {
  const [companies, setCompanies] = useState(0);
  
  const navigate = useNavigate();


  
//-----------------------------------------------------------
//const navigate = useNavigate();
     
const fetchProducts = async () => {
  await axios.get(`https://suhba.server.com.ly/back/api/maincompanies`).then((data) => {
    if (data) {
      setCompanyID(data.data.CompanyID)
    //  setCompanies('1')
       setCompanyName(data.data.CompanyName)
       setAddress(data.data.Address)
        setContactNumber(data.data.ContactNumber)
      setEmail(data.data.Email)
       setWebsite(data.data.Website)
       setImageName(data.data.ImageName)
      setDescription(data.data.Description)
       setUserID(data.data.UserID)


    }

  })

    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
} 
const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');

// Parse the JSON string back to an array
const userPermissions = JSON.parse(userPermissionsJSON);
// Function to search for permissions for a specific page
function getActionsForPage(pageName) {
  // Find the permissions object for the given page
  const pagePermissions = userPermissions.find(permission => permission.resource === pageName);
console.log('actionsForPage',pagePermissions); 

  // If permissions for the page are found, return the actions
  if (pagePermissions) {
      return pagePermissions.action;
  } else {
 navigate('/dashboard_1')

      return [];
  }
}

// Usage
const pageName = 'MainCompany';
const actionsForPage = getActionsForPage(pageName);

useEffect(() => {
  fetchProducts();

}, [])
const [Address, setAddress] = useState('')
const [ContactNumber, setContactNumber] = useState('')
const [Email, setEmail] = useState('')
 const [Website, setWebsite] = useState('')
const [ImageName, setImageName] = useState('')
const [Description, setDescription] = useState('')
const [UserID, setUserID] = useState('')
const [CompanyID,setCompanyID] = useState('')
const [CompanyName,setCompanyName] = useState('')
   
 const notifyTopRight = () => {
  toast.success("✔️ تمت عملية التعديل بنجاح", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
  });
}; 
 const [selectedImage, setSelectedImage] = useState(null);
 const handleImageChange = (e) => {
  const file = e.target.files[0];
  //setNewCompany({ ...newCompany, ImageName: e.target.files[0] })
   if (file) {
    // Use FileReader to read the selected image as a data URL
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(file);
  setImageName(file);

  }
};
const [newCompany, setNewCompany] = useState({})
 useEffect(() => {
     console.log(newCompany);
    }, [newCompany]); 
    const userdata = localStorage.getItem('USER_data');
    const userData = JSON.parse(userdata);
      const userid = userData.id;
    const handleChange = (e) => {
      const { name, value } = e.target;
      setNewCompany(prevCompany => ({
        ...prevCompany,
        [name]: value
      }));
      if(name==="CompanyName")
    { setCompanyName(value)}
    };
  
 
 const createEmployee = async(e)=>{
     e.preventDefault();
     const formData = new FormData();
     formData.append('CompanyName', CompanyName)
     formData.append('Address', Address)
     formData.append('ContactNumber', ContactNumber)
     formData.append('Email', Email)
      formData.append('Website', Website)
     formData.append('ImageName', ImageName)
     formData.append('Description', Description)
     formData.append('UserID', userid)
     formData.append('CompanyID', CompanyID)

    // formData.append('ImagePath', ImagePath)
    console.log('formData',formData)
    console.log('CompanyID',CompanyID)

       if (CompanyID) {
        

        await axios.put(`https://suhba.server.com.ly/back/api/maincompanies/${CompanyID}`, formData)
                  .then((data) => {
            console.log('in put',data.data)
        }).catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
          });
        
          
      } else {
     await axios.post('https://suhba.server.com.ly/back/api/maincompanies', formData).catch(function (error) {
       if (error.response) {
         // The request was made and the server responded with a status code
         // that falls out of the range of 2xx
         console.log(error.response.data);
         console.log(error.response.status);
         console.log(error.response.headers);
       } else if (error.request) {
         // The request was made but no response was received
         // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
         // http.ClientRequest in node.js
         console.log(error.request);
       } else {
         // Something happened in setting up the request that triggered an Error
         console.log('Error', error.message);
       }
       console.log(error.config);
     });
      }
       
    navigate('/MainCompany') } 

    const changeHandler = (e)=>{
      setImageName(e.target.files[0]);
      console.log(e.target.files[0])
  }
 
 
  return (
    <>
      <form className="profile-form" onSubmit={createEmployee} enctype="multipart/form-data" >

        <div className="container-fluid">
          <div className="row">

            <div className="col-xl-3 col-lg-4">
              <div className="clearfix">
                <div className="card card-bx profile-card author-profile m-b30">
                  <div className="card-body">
                    <div className="p-5">
                      <div className="author-profile">

                        <div className="author-media">
                          <img style={{ width: '100px', height: '100px' }} src={`https://suhba.server.com.ly/back/storage/ImageName/${ImageName}`} />
                          <div className="update-flie" title="" data-toggle="tooltip" data-placement="right" data-original-title="update">
                          <input multiple className="update-flie" type="file" id="formFile" name='ImageName'defaultValue={ImageName}
                      onChange={(e) => changeHandler(e)}/>
                         
                            <i className="fa fa-camera"></i>
                          </div>
                        </div>
                       
                        <div className="author-info">
                          <h6 className="title">صحبة طيبة</h6>
                          <span>معمل مأكولات</span>
                        </div>
                      </div>
                    </div>
                    <div className="info-list">
                      <ul>
                        <li><Link to={"/app-profile"}>مسؤل نطام</Link><span>36</span></li>
                        <li><Link to={"/uc-lightgallery"}>شيف</Link><span>3</span></li>
                        <li><Link to={"/app-profile"}>موظف</Link><span>1</span></li>
                        <li><Link to={"/app-profile"}>عامل توصيل</Link><span>1</span></li>

                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="card  card-bx m-b30">
                <div className="card-header">
                  <h6 className="title">بيانات الشركة</h6>
                </div>
                <div className="card-body">
                  <div className="row">
                  <div className="col-xl-6 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1">
                          <label htmlFor="exampleFormControlInput1" className="form-label"> الاسم <span className="text-danger">*</span></label>
                        </div><div className="col-xl-9 mb-1">
                        <input type="text" name="CompanyName"className="form-control" id="exampleFormControlInput1" placeholder="" 
              defaultValue={CompanyName}
              onChange={handleChange}/>
                        </div> </div>



                    </div>
                    <div className="col-xl-6 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1">
                          <label htmlFor="exampleFormControlInput1" className="form-label">النشاط<span className="text-danger">*</span></label>
                        </div><div className="col-xl-9 mb-1">
                        <input type="text" className="form-control" id="exampleFormControlInput88" placeholder="" value={Description}
         onChange={(e)=>{setDescription(e.target.value)}}/>
                        </div> </div>



                    </div>




                    <div className="col-xl-6 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1">
                          <label htmlFor="exampleFormControlInput1" className="form-label"> رقم الهاتف <span className="text-danger">*</span></label>
                        </div><div className="col-xl-9 mb-1">
                        <input type="number" className="form-control" id="exampleFormControlInput88" placeholder=""
 value={ContactNumber}
 onChange={(e)=>{setContactNumber(e.target.value)}}/> 
                        </div> </div>



                    </div>
                    <div className="col-xl-6 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1">
                          <label htmlFor="exampleFormControlInput1" className="form-label">البريد الالكتروني<span className="text-danger">*</span></label>
                        </div><div className="col-xl-9 mb-1">
                        <input type="Email" className="form-control" id="exampleFormControlInput88" placeholder=""  value={Email}
 onChange={(e)=>{setEmail(e.target.value)}}/>
                        </div> </div>



                    </div>
                    <div className="col-xl-6 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1">
                          <label htmlFor="exampleFormControlInput1" className="form-label"> العنوان <span className="text-danger">*</span></label>
                        </div><div className="col-xl-9 mb-1">
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" 
              value={Address}
              onChange={(e)=>{setAddress(e.target.value)}}/>
                        </div> </div>



                    </div>
                    <div className="col-xl-6 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1">
                          <label htmlFor="exampleFormControlInput1" className="form-label">الموقع الالكتروني<span className="text-danger">*</span></label>
                        </div><div className="col-xl-9 mb-1">
                        <input type="text" className="form-control" id="exampleFormControlInput88" placeholder="" value={Website}
         onChange={(e)=>{setWebsite(e.target.value)}}/>
                        </div> </div>



                    </div>
                  </div>
                </div>
                <div className="card-footer pt-5">
                  <button type='submit' className="btn btn-primary">حفظ</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
export default Company;