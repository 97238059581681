import React, { useState, forwardRef, useEffect,useImperativeHandle  } from 'react';
import {Modal} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import axios from "axios";
 import {useNavigate, useParams} from "react-router-dom";

const edit = forwardRef((props, ref) => {
   const navigate = useNavigate();
   const { id } = useParams();

     const [startDate, setStartDate] = useState('');
    const [inviteModal , setInviteModal] = useState(true);
    useImperativeHandle(ref, () => ({
        showInviteModal() {
            setInviteModal(true)
        }
    
      }))      ;
      const showInviteModal = () => {   
        
        setInviteModal(false)
        navigate('/WeddingHall')

    }
    const userdata1 = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata1);
    const userid = userData.id;
    const [hallData, setHallData] = useState({
        HallID: null,
      HallName: '',
      HallAddress: '',
      HallContactNumber: '',
      ItemPrice: '',
      UserID: userid,
      PreName: '',
    });
    useEffect(() => {
      fetchProducts();
  }, [])
 
  const fetchProducts = async () => {
    
      await axios.get(`https://suhba.server.com.ly/back/api/wedding_halls/${id}`).then(({ data }) => 
      {setHallData(data.data)
        console.log(data.data);

        console.log(hallData);
       } )
       .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  } 
 


  /*
      const [HallName,setHallName] = useState('')
      const [HallAddress, seHallAddress] = useState('')
      const [HallContactNumber, setHallContactNumber] = useState('')
      const [ItemPrice, setItemPrice] = useState('')
       const [UserID, setUserID] = useState('')
      
    
     const createNewHalls = async(e)=>{
          e.preventDefault();
          const formData = new FormData();
          formData.append('HallName', HallName)
          formData.append('HallAddress', HallAddress)
          formData.append('HallContactNumber', HallContactNumber)
          formData.append('ItemPrice', ItemPrice)
           formData.append('Website', Website)
           formData.append('UserID', UserID)
            console.log(formData)
          await axios.post('https://suhba.server.com.ly/back/api/wedding_halls', formData).catch(function (error) {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
             console.log(error.request);
            } else {
              console.log('Error', error.message);
            }
            console.log(error.config);
          });} 
*/

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHallData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async(e)=>{
    e.preventDefault();
    await axios.post('https://suhba.server.com.ly/back/api/wedding_halls', hallData)
     // setNotification('User was successfully created')
     // navigate('/users')
     .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
     // setInviteModal(false) 
      //parentFunction();
      setInviteModal(false);
      props.parentFunction()
      setHallData('')
   
      ;  };
    return(
        <>
                 <div className="modal-content">
                <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">بيانات صالة جديدة</h1>
                        <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={()=>setInviteModal(false)}></button>
                    </div>
                          <form onSubmit={handleSubmit}>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-xl-12">
                      

                            <div className="row">
                                    <div className="col-xl-6">
                                        <label className="form-label ">{props.Title} الاسم الحالي<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <input type="text"className="form-control" name="HallName" value={hallData.HallName} onChange={handleInputChange} />

                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <label className="form-label ">الاسم السابق<span className="text-danger">*</span></label>
                                        <div className="input-group">
 
                                        <input type="text" className="form-control" name="PreName" value={hallData.PreName} onChange={handleInputChange} />

                                         </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                <label className="form-label mt-3">العنوان<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name="HallAddress" value={hallData.HallAddress} onChange={handleInputChange} />
                                </div>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <label className="form-label mt-3">رقم الهاتف<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                        <input type="number" className="form-control" name="HallContactNumber" value={hallData.HallContactNumber} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <label className="form-label mt-3">سعر التوصيل<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                        <input type="number" className="form-control"  name="ItemPrice" value={hallData.ItemPrice} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <input type="text" hidden="true" name="UserID" value={userid} onChange={handleInputChange} />

                            </div>
                        </div>                                
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-primary" >
                       
                        حفظ</button>
                        <button type="button" className="btn btn-danger light" onClick={()=>showInviteModal()}>إلغاء</button>

                    </div> 
                     </form>
                </div>               
         </>
    )
})
export default edit;