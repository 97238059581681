
//=====================
import MOCK_DATA from '../jsx/components/table/FilteringTable/MOCK_DATA_2.json';
import { COLUMNS } from '../jsx/components/table/FilteringTable/ColumnsUsers.js';
import { GlobalFilter } from '../jsx/components/table/FilteringTable/GlobalFilter.js';
import React, { Fragment, forwardRef, useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import InviteCustomer from '../jsx/constant/ModalList.js';
import InviteCustomer2 from '../jsx/constant/ModalList2.js';
import Testarray from "./testarray.js";
import Secoundtest from "./secoundtest.js"
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

import '../jsx/components/table/FilteringTable/filtering.css';
import { Dropdown } from 'react-bootstrap';
//.......................................
import { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import { SVGICON } from '../jsx/constant/theme.js';
import axios from "axios";
import { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { Row, Card, Col, ListGroup } from "react-bootstrap";

let nextId = 0;
//=========
const Users = () => {
  const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');
 const userPermissions = JSON.parse(userPermissionsJSON);
 function getActionsForPage(pageName) {
   const pagePermissions = userPermissions.find(permission => permission.resource === pageName);
console.log('actionsForPage',pagePermissions); 
  if (pagePermissions) {
      return pagePermissions.action;
  } else {
 navigate('/dashboard_1')
     return [];
  }
}
const pageName = 'ManageUsers';
const actionsForPage = getActionsForPage(pageName);


  const [data, setData] = useState([])
  const columns = useMemo(() => COLUMNS, [])

  const tableInstance = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 }
  }, useFilters, useGlobalFilter, usePagination)
  const navigate = useNavigate();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance

  const { globalFilter, pageIndex } = state
  useEffect(() => {
    fetchProducts();
  }, [])

  const fetchProducts = async () => {
    await axios.get('https://suhba.server.com.ly/back/api/userpermissions').then(({ data }) => { setData(data) 
   // const user = data.find(item => item.id == 14);
   // if (user) {
     // setUser(user)
      setCalculatedRows(user.userPermissions || []);
      
      console.log("User permissions:", user.userPermissions);
   // } else {
    //  console.log("User with ID 25 not found.");
   // }
   
  })
  .catch(error => {
    console.error('Error fetching user permissions:', error);
  });    

    /*catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });*/
  }
  const [empname, setempname] = useState('')
  const [Arabicpagename, setArbicPagename] = useState('')
  const [user, setUser] = useState('')
  const [OffOn, setOffOn] = useState(true)
  const [inviteModal ,setinviteModal]=useState(false)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [pagename, setPagename] = useState('')
  const mapPeemissionsToArabic = (englishPageName) => {
    const permissionnameMap = {
      "Add": "إضافة",
      "Edit": "تعديل",
      "Delete": "حذف",
      "Show": "عرض",
      "Print": "طباعة",

    }
    return permissionnameMap[englishPageName] || englishPageName;
  }

  const mapPageNameToArabic = (englishPageName) => {
    console.log('englishPageName',englishPageName)

    const pagenameMap = {
   "AllOrderDateLimits":"إدارة التواريخ" ,
   "EmployeeStatus":"حالات الموظف" ,

  "ManageUsers":   "بيانات المستخدمين" ,
     "Garantees": "ضمانات الطلبيات" ,
     "NotConfirmedOrder": "فاتورة مبدئية" ,
      "FormValidation": "صلاحيات المستخدم",
      "CompanyExpensesreport": "تقارير المصروفات للشركة",
      "Income": "الدخل",
      "CompanyPurchases": "مشتريات الشركة",
      "Ingredients": "المكونات",
      "First": "إحصائية دخل الشركة",
      "Atbak": "الأطباق",
      "Customers": "الزبائن",
      "Departments": "الأقسام",
      "ExpenseTypes": "أنواع المصروفات",
      "IntTypes": "أنواع المكونات",
      "CompanyExpenses": "مصاريف الشركة",
      "Dashboard_1": "الشاشة الرئيسة",
      "ToolReport": "تقرير الأدوات",
      "AsnafReport": "تقرير الأصناف",
      "Tools": "المعدات",
      "MainCompany": "الشركة الرئيسية",
      "WeddingHall": "قاعة الزفاف",
      "guaranteetype": "نوع الضمان",
      "ProcessStatus": "قفل / فتح تاريخ ",
      "Orders": "الطلبات",
      "OrdersList": "كل الطلبات",
      "Al-asnaf": "الأصناف",
      "city": "المدينة",
      "Empolyee": "الموظفون",
      "StorageType": "نوع التخزين"
    };

    return pagenameMap[englishPageName] || englishPageName;
  };
  const [calculatedRows, setCalculatedRows] = useState([]);
 // Empty dependency array ensures this effect runs only once on component mount
 const calculate = (permission, pagename, arabicpagename, username, password) => {

  if(pagename ==='اختر من القائمة' || permission ==='اختر من القائمة')
    {

      swal('الرجاء اختر من القائمة المنسدلة')
    }else{

   
  // Check if there's an existing row with the same pagename
  const existingIndex = calculatedRows.findIndex(row => row.PageName === pagename);

  // If an existing row is found, update the permissions array
  if (existingIndex !== -1) {
    const existingRow = calculatedRows[existingIndex];
    const updatedPermissions = [...existingRow.permission, permission];
    const updatedRow = { ...existingRow, permission: updatedPermissions };
    calculatedRows[existingIndex] = updatedRow;
  } else {
    // If the pagename doesn't exist, create a new row object
    const newRow = {
      permission: [permission],
      PageName: pagename,

    };
    // Push the new row to the calculatedRows array
    calculatedRows.push(newRow);
  }

  // Update the state with the new calculatedRows array
  setCalculatedRows([...calculatedRows]);
}
};


const deletePermission = (permission, pagename) => {
  // Find the index of the row with the specified pagename
  const rowIndex = calculatedRows.findIndex(row => row.PageName === pagename);
console.log('rowIndex',pagename)
  // If the row is found
  if (rowIndex !== -1) {
    // Filter out the specified permission from the permission array
    const updatedPermissions = calculatedRows[rowIndex].permission.filter(perm => perm !== permission);

    // Update the row with the new permission array
    calculatedRows[rowIndex].permission = updatedPermissions;

    // Update the state with the modified calculatedRows array
    setCalculatedRows([...calculatedRows]);
  }
};

  const deletePage = (pagename, index) => {
    // Check if the index is within the bounds of the array
    if (index >= 0 && index < calculatedRows.length) {
      calculatedRows.splice(index, 1);
      setCalculatedRows([...calculatedRows]);
      // Show success message using Sweet Alert
      Swal.fire({
        icon: 'success',
        title: 'تم حدف الصفحة',
      });
    } else {
      // Show error message using Sweet Alert if the index is out of bounds
      Swal.fire({
        icon: 'error',
        title: 'Invalid Index!',
        text: `The index ${index} is out of bounds for the array.`
      });
    }
  };
  const Pagenamearray = [
    { value: "FormValidation", name: "صلاحيات المستخدم" },
    { value: "CompanyExpensesreport", name: "تقارير المصروفات للشركة" },
    { value: "Income", name: "الدخل" },
    { value: "CompanyPurchases", name: "مشتريات الشركة" },
    { value: "Ingredients", name: "المكونات" },
    { value: "AllOrderDateLimits", name: "إدارة التواريخ" },
    { value: "EmployeeStatus", name: "حالات الموظف" },

    { value: "First", name: "إحصائية دخل الشركة" },
    { value: "Atbak", name: "الأطباق" },
    { value: "Customers", name: "الزبائن" },
    { value: "Departments", name: "الأقسام" },
    { value: "ExpenseTypes", name: "أنواع المصروفات" },
    { value: "IntTypes", name: "أنواع المكونات" },
    { value: "ManageUsers", name: "بيانات المستخدمين" },
    { value: "Garantees", name: "ضمانات الطلبيات" },
   { value: "NotConfirmedOrder", name: "فاتورة مبدئية" },
    { value: "CompanyExpenses", name: "مصاريف الشركة" },
    { value: "Dashboard_1", name: "الشاشة الرئيسة" },
    { value: "ToolReport", name: "تقرير الأدوات" },
    { value: "AsnafReport", name: "تقرير الأصناف" },
    { value: "Tools", name: "المعدات" },
    { value: "MainCompany", name: "الشركة الرئيسية" },
    { value: "WeddingHall", name: "قاعة الزفاف" },
    { value: "guaranteetype", name: "نوع الضمان" },
    { value: "ProcessStatus", name: "قفل / فتح تاريخ"  },
    { value: "Orders", name: "الطلبات" },
    { value: "OrdersList", name: "كل الطلبات" },
    { value: "Al-asnaf", name: "الأصناف" },
    { value: "city", name: "المدينة" },
    { value: "Empolyee", name: "الموظفون" },
    { value: "StorageType", name: "نوع التخزين" }
];
  const clearAllPermissions = () => {
    setCalculatedRows([])
    setPagename('')
  }

  const [showPassword, setShowPassword] = useState(false);
  const addAllPermissions = () => {
    const permission = ['Add', 'Edit', 'Show', 'Delete', 'Print']; // Array of permissions

    console.log('pagenameArray', Pagenamearray)


    // Iterate over each page name in the page names list
    for (const page of Pagenamearray) {
      // Iterate over each permission in the permission list
      for (const perm of permission) {
        // Call the calculate function to add the combination of page name and permission to the calculatedRows state
        calculate(perm, page.value, page.name, username, password);
      }
    }

    // Show success message using Sweet Alert
    Swal.fire({
      icon: 'success',
      title: 'تم!',
      text: 'تمت إضافة كافة الصلاحيات الى المستخدم '
    });
  };
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  )
  const [selectedValue, setSelectedValue] = useState('');
    console.log('calculatedRowsin in', calculatedRows)

  const handleSelectChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    setSelectedValue(e.target.options[selectedIndex].text);

  }; 
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('calculatedRows befor print', calculatedRows)

    axios.put(`https://suhba.server.com.ly/back/api/userpermissions/${user.id}`, {
      calculatedRows: calculatedRows
    })
      .then(response => {
        console.log('response response response', response.data)
        setinviteModal(false)
      })
      .catch(error => {
        console.error('Error updating user permissions:', error);
        // Handle error
      });
  };
   function EditData(id) {
   
    const user = data.find(item => item.id == id);
    if (user) {
      setUser(user)
      setCalculatedRows(user.userPermissions || []);
      setinviteModal(true)
      console.log("User permissions:", user.userPermissions);
    } else {
      console.log("User with ID 25 not found.");
    }
   
  
} 
  return (

    <>


      <div className="card">
        <div className="card-header border-0 pb-0 flex-wrap">
          <h4 className="heading mb-0">المستخدمين</h4>
        </div>
        <div className="container-fluid"  >
          <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting p-0">


          </div>
          <div className="table-responsive">
            <table {...getTableProps()} className="table dataTable display table-hover">
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      // Check if the column accessor is 'age' and render accordingly
                      column.id !== 'id' ? (
                        <th {...column.getHeaderProps()}>
                          {column.render('Header')}
                          {column.canFilter ? column.render('Filter') : null}
                        </th>
                      ) : null
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="" >

                {page.map((row) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        // Check if the column accessor is 'age' and render accordingly
                        cell.column.id !== 'id' ? (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        ) : null
                      ))}
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            className="light sharp i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu dir="rtl" className="dropdown-menu-right">
                           
                          {actionsForPage.includes('edit') && (
                            <Dropdown.Item onClick={(e) =>EditData(row.original.id)}>

                              <Link to className=""
                               

                              >تعديل
                              </Link>
                            </Dropdown.Item>)}

                          </Dropdown.Menu>
                        </Dropdown>
                      </td>

                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{''}
              </span>
              <span className="table-index">
                Go to page : {' '}
                <input type="number"
                  className="ml-2"
                  defaultValue={pageIndex + 1}
                  onChange={e => {
                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(pageNumber)
                  }}
                />
              </span>
            </div>
            <div className="text-center mb-3">
              <div className="filter-pagination  mt-3">
                <button className="previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {/* {'<'} */}
                  <i className='fa-solid fa-angle-right' />
                </button>

                <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                  السابق
                </button>
                <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                  التالي
                </button>
                <button className="next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                  {/* {'>'} */}
                  <i className='fa-solid fa-angle-left' />
                </button>
              </div>
            </div>
          </div></div>

      </div>
      <Modal className="modal fade " size="xl" id="exampleModal1" show={inviteModal} centered>
 
        <div className="modal-content">
        <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">تعديل بيانات المستخدم</h1>
                        <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={()=> setinviteModal(false)}></button>
                    </div>
          <div className="modal-body">
          <form className='pe-5' onSubmit={handleSubmit}>

             
            <div className="row mb-3" style={{ display: 'flex' }}>
              <div className="col-md-4" style={{ display: 'flex' }}>
                <label htmlFor="startMonth" style={{ fontsize: '15px', fontWeight: 'bold' }}>اسم المستخدم :</label>
                <div style={{ color: 'blue' }}> <strong>{user.email}</strong></div>
              </div>
              <div className="col-md-4" style={{ display: 'flex' }}>
                <label style={{ fontsize: '15px', fontWeight: 'bold' }} htmlFor="startMonth"> كلمة المرور:</label>
                <div style={{ color: 'red' }}><strong>{user.realpassword}</strong></div>
              </div>
              <div className="col-md-4" style={{ display: 'flex' }}>
                <label style={{ fontsize: '15px', fontWeight: 'bold' }} htmlFor="startMonth">اسم الموظف:</label>
                <div style={{ color: 'green' }}><strong>{user.EmpName}</strong>        </div>
              </div>
            </div>
           
              <div className="col-lg-12">
              <center> <div className="row" style={{ justifycontent: 'center'  }} >
              <button className="col-lg-5 btn btn-primary btn-sm m-2  " onClick={addAllPermissions}>
                  إضافة كل الصلاحيات
                </button>
                <button className="col-lg-5 btn btn-secondary btn-sm m-2  " onClick={clearAllPermissions}>
                  إزالة كل الصلاحيات
                </button>
              </div></center> 
            </div>
            <div className="row px-0" style={{ justifycontent: 'center' ,display:'flex'}}>

            <div className=" mb-3 col-lg-5 m-2 px-0" >
    <label className="col-form-label col-lg-3" htmlFor="val-username">
      اسم الصفحة
      <span className="text-danger">*</span>
    </label>
    <select
      onChange={(e) => { setPagename(e.target.value) 
        setOffOn(false);
      }}
      className="default-select form-control col-lg-6 "
      id="val-skill"
      name="val-skill"
      value={pagename}
      required
      placeholder='اختر اسم الصفحة'
    >
               <option data-display="Select">اختر من القائمة</option>
                     
                                 {Pagenamearray.map((item, index) => (
                                  <option key={index} value={item.value}>{item.name}</option>
                                ))}
    </select>
  </div>
            <div className=" mb-3 col-lg-5 m-2   px-0" >
              <label className="col-form-label col-lg-3 " htmlFor="val-username">
                الاجراءات
                <span className="text-danger">*</span>
              </label>
              <select
      onChange={(e) => { calculate(e.target.value,pagename, 'ali', '123456') }}
      className="default-select form-control col-lg-6"
      id="val-skill"
      name="val-skill"
      disabled={OffOn}
      placeholder='اختر نوع الاجراء'
required
    >      <option data-display="Select">اختر من القائمة</option>

      <option value="Add">أضافة</option>
      <option value="Edit">تعديل</option>
      <option value="Show">عرض</option>
      <option value="Delete">حدف</option>
      <option value="Print">طباعه</option>
    </select>
            </div>    </div>
                <div className="table-responsive active-projects task-table my-3 mx-2" style={{ height: "200px" }}>
                         <div class="row me-2">
                          {calculatedRows.map((pagenameArray, index) => (
                            <div key={index} className="col-lg-3 border m-2 p-2">
                     <p><strong>{mapPageNameToArabic(pagenameArray.PageName)}</strong>
                         <i class="fa-regular fa-circle-xmark mx-1" style={{float:'left'}}
                         onClick={() => deletePage(pagenameArray.pagename,index)}>   </i></p>
                              <ul style={{
                                display: "flex",
                                flexwrap: "wrap"
                              }}>
                                {pagenameArray.permission.map((row, idx) => (
                                   <li key={idx}>
                                  {mapPeemissionsToArabic(row)}
                                   <i class="fa-solid fa-circle-xmark mx-1" onClick={() => deletePermission(row,pagenameArray.PageName)}>   </i>
                                   
                                 </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </div>             </div>
                       

                        <button onClick={handleSubmit} className="btn btn-primary ">
                        حفظ الاعدادات
                        </button>

         </form>
          </div>
        </div>

      </Modal>

    </>
  );
}
export default Users;