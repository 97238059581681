import React, { useState, forwardRef, useImperativeHandle ,useEffect } from 'react';
import {Modal} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import '../components/Reports/Invoice.css'; // You can define your CSS styles in this file
import logo from '../components/Reports/food.jpg';
const InviteCustomer = forwardRef((props, ref) => {

   const [items, setitems] = useState([])
   
      const [List, setList] = useState({
       
         	Description:'',
           	Amount:'',
             	ExpenseDate :'',
              	OrderID 	:'',
                reference_number :'',
                	ExpenseType :'',
                  	notes :'',
                  
      })
       useEffect(() => {
         setitems(props.data)
         setList(props.data1)
 
   
      }, [props.data, setitems]);
    const [startDate, setStartDate] = useState(new Date());
    const [inviteModal , setInviteModal] = useState(false);
    useImperativeHandle(ref, () => ({
        showInviteModal() {
            setInviteModal(true)
        }
    
      }));
   
      const userdata = localStorage.getItem('USER_data');
            const userData = JSON.parse(userdata);
              const userid = userData.name;

        
      
      console.log('props.data',props.data1)
      console.log('items',List.reference_number)

      const printInvoice12 = () => {
        let printElement = document.getElementById('invoiceDiv');
        var printWindow = window.open('', 'PRINT');
        printWindow.document.write(document.documentElement.innerHTML);
        setTimeout(() => { // Needed for large documents
          printWindow.document.body.style.margin = '0 0';
          printWindow.document.body.innerHTML = printElement.outerHTML;
          printWindow.document.close(); // necessary for IE >= 10
          printWindow.focus(); // necessary for IE >= 10*/
          printWindow.print();
          printWindow.close();
        }, 1000)
      }   
     const printInvoice1 = () => {
         const printContents = document.getElementById('invoiceDiv').innerHTML;
         const originalContents = document.body.innerHTML;
         document.body.innerHTML = printContents;
         window.print();
         document.write = originalContents;
         window.focus()
       };
       const printInvoice3 = () => {
        const printContents = document.getElementById('invoiceDiv').innerHTML;
        const originalContents = document.body.innerHTML;
      
        // Create a new div to hold the print contents
        const printDiv = document.createElement('div');
        printDiv.innerHTML = printContents;
      
        // Hide all buttons or other elements you want to hide while printing
        const elementsToHide = document.querySelectorAll('.button-class'); // Adjust the selector as needed
        elementsToHide.forEach(element => {
          element.style.display = 'none';
        });
      
        // Append the print contents to the document body
        document.body.innerHTML = '';
        document.body.appendChild(printDiv);
      
        // Print the contents
        window.print();
      
        // Restore original content and show hidden elements
        document.body.innerHTML = originalContents;
        elementsToHide.forEach(element => {
          element.style.display = ''; // Restore original display property
        });
      
        window.focus();
      };
      const printInvoice = () => {
        const printContents = document.getElementById('invoiceDiv').innerHTML;
        const originalContents = document.body.innerHTML;
      
        const newWindow = window.open('', 'PRINT', 'height=600,width=800');
        newWindow.document.write('<html><head><title>Invoice</title>');
        newWindow.document.write('<link rel="stylesheet" href="./Invoice.css" type="text/css" />'); // Include your CSS file
        newWindow.document.write('</head><body>');
        newWindow.document.write(printContents);
        newWindow.document.write('</body></html>');
      
        newWindow.document.close(); // Close the document for writing
      
        newWindow.focus(); // Focus on the new window
        newWindow.print(); // Print the contents
        newWindow.close(); // Close the new window
      
        // Restore original content
       // document.body.innerHTML = originalContents;
      };
      
    return(
        <>
            <Modal className="modal fade" id="exampleModal1" show={inviteModal} onHide={setInviteModal} centered>                
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">{props.Title}</h1>
                        <button type="button" className="btn-close" padding="" onClick={()=>setInviteModal(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-xl-12">
                            <div className="" id="invoiceDiv" style={{backgroundColor:"white"}}>
      <div className="header2">
      <div className="company-details">
          <h2>صحبة طيبة</h2>
         </div>
        <img src={logo} alt="Company Logo" className="logo" />
        
         
      </div>
      <div className="row ">
      <div className="invoice-info">
      <p><div style={{ float: "left"}}>{List.reference_number}</div><strong style={{ float: "left"}}>رقم الفاتورة:</strong><strong>التاريخ:</strong>{List.expenses_date}</p>

        <p></p>
      </div>
                </div>
      <table className=" invoice-table">
        <thead>
          <tr>
            <th>اسم المكون</th>
            <th>الكمية</th>
            <th>الوحدة</th>
           
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td>{item.IngName}</td>
              <td>{item.Amount}</td>
              <td>{item.IngCatName}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="col-xl-12">
<p>إعد بواسطة :  {userid}</p>
<center><p>الختم</p></center>
    </div>    </div>

          
                            </div>
                        </div>                                
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger light" onClick={()=>setInviteModal(false)}>Close</button>
                        <button onClick={printInvoice} className="btn btn-primary">طباعه</button>
                    </div>
                </div>               
            </Modal>
        </>
    )
})
export default InviteCustomer;