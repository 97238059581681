import React, { useState, forwardRef, useEffect,useImperativeHandle,useRef  } from 'react';
import {Modal} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import axios from "axios";
 import {useNavigate, Link} from "react-router-dom";
 import { useLocation } from 'react-router-dom';
 import swal from 'sweetalert';
 import InviteCustomer1 from '../../components/SanadSaref';

const InviteCustomer = forwardRef((props, ref) => {
   const navigate = useNavigate();
      const showInviteModal = () => {   
         props.show(false)   
        }
        const userdata1 = localStorage.getItem('USER_data');
        const userData = JSON.parse(userdata1);
          const userid = userData.id;
        const [orderItems, setOrderItems] = useState([]);
        const [data, setData] = useState([]);
        const invite1 = useRef();
        const [inviteModal1, setInviteModal1] = useState(false);

        useEffect(() => {
      
    
          setData(props.data2)
        setOrderItems(props.data);
        
        
      // const intervalId = setInterval(() => {
       //   setOrderDate(new Date());
      //  }, 1000); // Update every 1000 milliseconds (1 second)
    
        // Cleanup function to clear the interval when the component unmounts
      //  return () => clearInterval(intervalId);
       
          }, [props.data],[setOrderItems])
        const [toolReturnStatus, setToolReturnStatus] = useState(Array(orderItems.length).fill(false));
        console.log('befor return',data)  

      
//  DeliveryID 	OrderID 	DeliveryPersonID 	DeliveryDate 	DelPrice 	Address 	Status 	UserID 
const [ReturnedStatuse, setReturnedStatuse] = useState(false);

const handleReturn = async (id,index)=> {
  orderItems.map((item) => {
    if (id == item.OrderItemID) {
        axios.post(`https://suhba.server.com.ly/back/api/returntool/${id}`, item)
  .catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  });  
    }
  })
   
  swal({
    title: "تم",
    text: "تم الاعادة بنجاح",
    icon: "success",
    timer: 2000,
    button: false
  })
  const updatedStatus = [...toolReturnStatus];
  updatedStatus[index] = true;
  setToolReturnStatus(updatedStatus);} 
             

            
  const [report, setReport] = useState([]);
   
          
              const EditData556 = async (id) => {
                // console.log('userid',user.ExpenseID);
            
               axios.get(`https://suhba.server.com.ly/back/api/getreceiptexpenses/${id}/${userid}`)  
                                 .then(({data}) => {
                        //props.parentFunction()
                        if (data.Receiptexpenses)
                       { setReport(data.Receiptexpenses)
                        invite1.current.showInviteModal()
console.log('gggggg',data.Receiptexpenses)               
                       }else{
                        swal("لا يوجد إيصال استلام الضمان");
                        props.show(false) 
                       }
})
                    .catch(function (error) {
                        if (error.response) {
                          // The request was made and the server responded with a status code
                          // that falls out of the range of 2xx
                          console.log(error.response.data);
                          console.log(error.response.status);
                          console.log(error.response.headers);
                        } else if (error.request) {
                          // The request was made but no response was received
                          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                          // http.ClientRequest in node.js
                          console.log(error.request);
                        } else {
                          // Something happened in setting up the request that triggered an Error
                          console.log('Error', error.message);
                        }
                        console.log(error.config);
                      });
                     
              }
            //OrderItemID 	OrderID 	ItemID 	TItemID 	Quantity 	GuaranteeTypesID 	UserID 
          
           
        
         
           
           // Assuming you have an array of items you want to add
           
           // Use a loop to add items to orderItems
           
         console.log('recci',props.data)  
           
         
         //------------------------
 
        


  
 
     const handleDelete = async (id)=> {
   
  };
 //---------------------------------------------
    return(
        <>
         <div>

         </div>
             <Modal className="fade bd-example-modal-lg"  id="exampleModal1"   show={props.inviteModal}  centered> 
            
        
                <div className="modal-content">
                <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">   إعادة معدات الطلبية  </h1>
                        <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={()=>showInviteModal()}></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-xl-12">

                              <div className="row">
                           
                          
                              

                              
                                        
                                     <div className="row">
                                     <div className="table-responsive active-projects dlab-scroll" style={{  height: "200px" }}>
                                        <table id="projects-tbl4" className="table">
                                            <thead>
                                                <tr>
                                                    <th>اسم الاداة</th>
                                                    <th>الكمية</th>
                                                    <th>إعادة</th>

                                                 </tr>
                                            </thead>
                                            <tbody>
                                            {orderItems.length > 0 ? (
    orderItems.map((item, ind) => (
        <tr key={ind}>
            <td>{item.TName}</td>
            <td>{item.Quantity}</td>
            <td>
                {item.Returned === 1 || toolReturnStatus[ind] ? (
                    "تمت الاعادة"
                ) : (
                    <Link onClick={() => handleReturn(item.OrderItemID, ind)}>
                        <i className="fa-solid fa-rotate-left"></i>
                    </Link>
                )}
            </td>
        </tr>
    ))
) : (
    <tr>
        <td colSpan="3">تمت إعادة كل الادوات لهده الطلبية </td>
    </tr>
)}

                                            </tbody>     
                                        </table>
                                    </div>
                                 </div>
 
                             
 
                                 
                               
                                 </div>


 
                                 <hr className="mb-4" />
                               
                          
                                 <Link
                                    className="btn btn-primary btn-lg btn-block"
                                   to="#"       
                                  onClick={(e) => EditData556(data.OrderID)}
                                   >    
                                    طباعة إيصال إعادة الضمان
                                 </Link>
                                  </div>    
                                </div>
                                </div>
                                </div>   
            </Modal>

            <InviteCustomer1
             ref={invite1}
        data={data}
        data2={report}
      //  parentFunction={fetchProducts}
        inviteModal={inviteModal1}
        //show={show1}
         />
        </>
    )
})
export default InviteCustomer;