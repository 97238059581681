import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useReactToPrint } from "react-to-print";
import logo from '../components/Reports/food.jpg';

const InviteCustomer = forwardRef((props, ref) => {
  const [inviteModal, setInviteModal] = useState(false);
  const [items, setItems] = useState([]) 
  const userdata1 = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata1);
    const userid = userData.id;
    const username = userData.name;

  useEffect(() => {
    setItems(props.data);
  }, [props.data ,setItems]);
console.log("items",items)
  const componentRef = useRef();

  useImperativeHandle(ref, () => ({
    showInviteModal() {
      setInviteModal(true);
    }
  }));

  const generatePDF = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Userdata",
    onAfterPrint: () => alert("Data saved in PDF")
  });

  const printInvoice = () => {
    window.print();
  };

  return (
    <>
      <Modal className="modal fade" id="exampleModal1" show={inviteModal} onHide={() => setInviteModal(false)} centered>
        <div className="modal-content">
        { items.length>0  && items.map((items, ind)=>(  
          <div ref={componentRef} className="modal-body" dir="rtl">
            <div className="row">
              <div className="col-xl-12">
                <center><img src={logo} alt="Logo" className="logo" style={{ height: "100px", width: "150px", textAlign: "center" }} /></center>
                <div className="shop-info">
                  <h2 style={{ textAlign: "center" }}>صحبة طيبة</h2>
                  <p style={{ textAlign: "center" }}>ايصال قبض مبلغ مالي </p>
                </div>
              </div>
            </div>
            <div style={{ width: '100%' }} className="table-responsive">
              <table className="table table-bordered verticle-middle table-responsive-sm" style={{ borderCollapse: "unset", borderWidth: '1 px' }}>
                <tbody>
                  <tr>
                    <td style={{ boxShadow: '0 1px 1px 0 black' , border: '1px solid #ccc' }}>رقم الايصال :{items.reference_number}</td>
                    <td style={{ boxShadow: '0 1px 1px 0 black',border: '1px solid #ccc' }}> </td>
                  </tr>
                  <tr>
                    <td style={{ boxShadow: '0 1px 1px 0 black',border: '1px solid #ccc' }}>استلمت من: {items.CustomerName}</td>
                    <td style={{ boxShadow: '0 1px 1px 0 black' ,border: '1px solid #ccc'}}>بتاريخ: {items.deposit_date}</td>
                  </tr>
                  <tr>
                    <td style={{ boxShadow: '0 1px 1px 0 black' ,border: '1px solid #ccc'}}>مبلغ مالي بقيمة: {items.amount}</td>
                    <td style={{ boxShadow: '0 1px 1px 0 black' ,border: '1px solid #ccc',verticalAlign:'top'}} rowspan="2"> ودلك لي :{items.deposits} </td>
                  </tr>
                  <tr >
                    <td style={{ boxShadow: '0 1px 1px 0 black' ,border: '1px solid #ccc'}}>المبلغ المتبقي: {items.reastAmount}</td>
                  </tr>
                  <tr >
                    <td style={{ boxShadow: '0 1px 1px 0 black'  ,border: '1px solid #ccc'}} colSpan="2">المبلغ بالحروف</td>
                  </tr>
                </tbody>
              </table>
              <div className="col-xl-12">
<p>إعد بواسطة :  {username}</p>
<center><p>الختم</p></center>
    </div> 
            </div>
          </div>))}
          <div className="modal-footer">
            <button type="button" className="btn btn-danger light" onClick={() => setInviteModal(false)}>إلغاء</button>
             <button className="btn btn-success" onClick={generatePDF}>طباعه</button>
          </div>
        </div>
      </Modal>
    </>
  );
});

export default InviteCustomer;
