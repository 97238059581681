import React, { useState, forwardRef, useImperativeHandle  } from 'react';
import {Modal} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import product1 from "../../../src/images/product/1.jpg";
import {Link} from 'react-router-dom';

const InviteCustomer = forwardRef((props, ref) => {
    const [startDate, setStartDate] = useState(new Date());
    const [inviteModal , setInviteModal] = useState(false);
    useImperativeHandle(ref, () => ({
        showInviteModal() {
            setInviteModal(true)
        }
    
      }));
    return(
        <>
            <Modal className="modal fade" id="exampleModal1" show={inviteModal} centered>                
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">{props.Title}</h1>
                        <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={()=>setInviteModal(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                        <div className="col-xl-3 col-lg-6  col-md-6">
                           <label htmlFor="formFile" className="form-label">   الشهادةالصحية</label>

                        <img className="img-fluid rounded mb-3" src={product1} alt="" />


                               
                        </div> 
                        <div className="col-xl-9 col-lg-6  col-md-6 col-sm-12">
						<div className="product-detail-content">
							{/*Product details*/}
							<div className="new-arrival-content pr">
                            <div className="row">
								<div className="col-xl-5 mb-1">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">اسم الموظف  </label>
									</div><div className="col-xl-7 mb-1">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">احمد علي  </label>
                                </div> </div> 
                                <div className="row">
								<div className="col-xl-5 mb-1">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">تاريخ انتهاء الشهاد</label>
									</div><div className="col-xl-7 mb-1">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">احمد علي  </label>
                                </div> </div> <div className="row">
								<div className="col-xl-5 mb-1">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">الصفة</label>
									</div><div className="col-xl-7 mb-1">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">احمد علي  </label>
                                </div> </div> <div className="row">
								<div className="col-xl-5 mb-1">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">المرتب</label>
									</div><div className="col-xl-7 mb-1">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">احمد علي  </label>
                                </div> </div> 

                               						
							</div>
						</div>
						</div>
                        
                         </div>  
                         <div className="row">
                         <div className="product-detail-content">
							{/*Product details*/}
							<div className="new-arrival-content pr">
                            <div className="row">
								<div className="col-xl-2 mb-1">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">رقم الهاتف</label>
									</div><div className="col-xl-4 mb-1">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">احمد علي  </label>
                                </div>
                            
								<div className="col-xl-2 mb-1">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">رقم الهاتف</label>
									</div><div className="col-xl-4 mb-1">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">احمد علي  </label>
                                </div>                                 </div> 

                                <div className="row">
								<div className="col-xl-2 mb-1">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">العنوان</label>
									</div><div className="col-xl-4 mb-1">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">احمد علي  </label>
                                </div> 

                             
								<div className="col-xl-2 mb-1">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">نوع المرتب</label>
									</div><div className="col-xl-4 mb-1">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">احمد علي  </label>
                                </div>	
                         </div>    
                         
                            </div>    
                              </div>                         
                    </div>
                    <div className="card-footer" >
                        <button type="button" className="btn btn-danger light" onClick={()=>setInviteModal(false)}>اغلاق</button>
                     </div>
                </div> 
                </div>               
              
            </Modal>
        </>
    )
})
export default InviteCustomer;