import React,{ useRef,useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import MOCK_DATA from '../jsx/components/table/FilteringTable/MOCK_DATA_2.json';
import { COLUMNS } from '../jsx/components/table/FilteringTable/ColumnsComExpenses';
 import { Link,useNavigate } from "react-router-dom";
 import axios from "axios";
 import swal from 'sweetalert'; 
import '../jsx/components/table/FilteringTable/filtering.css';
import { Col, Row ,Dropdown} from 'react-bootstrap';
import InviteCustomer1 from '../jsx/constant/CompanyExpensesModal';
import InviteCustomer from '../jsx/constant/EditCompanyExpenses';
//import InviteCustomer2 from '../jsx/constant/AsnafExpenses';

import  { useEffect,useState     } from 'react';


export const CompanyExpenses = () => {
	const columns = useMemo( () => COLUMNS, [] )
	//const data = useMemo( () => MOCK_DATA, [] )
  const [data, setData] = useState([])
  const [data2, setData2] = useState([])
  const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');

  // Parse the JSON string back to an array
  const userPermissions = JSON.parse(userPermissionsJSON);
  // Function to search for permissions for a specific page
  function getActionsForPage(pageName) {
    // Find the permissions object for the given page
    const pagePermissions = userPermissions.find(permission => permission.resource === pageName);
  console.log('actionsForPage',pagePermissions); 
  
    // If permissions for the page are found, return the actions
    if (pagePermissions) {
        return pagePermissions.action;
    } else {
   navigate('/react/demo/dashboard_1')
  
        return [];
    }
  }
  
  // Usage
  const pageName = 'CompanyExpenses';
  const actionsForPage = getActionsForPage(pageName);
  const [ID, setID] = useState(0)
  const [inviteModal , setInviteModal] = useState(false);
	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0}
	}, useFilters, useGlobalFilter, usePagination)
	
	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance
    const {globalFilter, pageIndex} = state	
    const navigate = useNavigate();

    const userdata = useRef();
    const invite1 = useRef();
    const invite = useRef();
    const invite2 = useRef();

    useEffect(() => {
      fetchProducts();
  }, [])

  const fetchProducts = async () => {
      await axios.get('https://suhba.server.com.ly/back/api/expenses').then(({ data }) => {setData(data)}) 
  }
    const deleteUser = async (e,id)=> {
        e.preventDefault();
        swal({
          title: "هل متأكد من الحدف",
          text: " في حال حدف الملف لايمكنك استعادة هدا الملف",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then(willDelete => {
          if (willDelete) {
    
            axios.delete(`https://suhba.server.com.ly/back/api/expenses/${id}`)
            
              .then(res => {
                fetchProducts();
                swal({
                  title: "تم",
                  text: "تم الحدف بنجاح",
                  icon: "success",
                  timer: 2000,
                  button: false
                })
                navigate('#')
            
               
            });
        
          } else {
            swal("تم الغاء عملية الحدف");
          }
        
        });
    
    };
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
          </g>
        </svg>
    )

    const EditData = async (e,id)=> {
      console.log(id);

      e.preventDefault();
      await axios.get(`https://suhba.server.com.ly/back/api/expenses/${id}`).then(({ data }) => 
      {setData2(data.data)
        console.log('data',data2);
        console.log('data.darta',data2.data);
       } )
       .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
      setID(id);setInviteModal(true)
     // invite.current.showEmployeModal()
    }
    const show =(value)=>
{
setInviteModal(value)

}
    return(
<>
<div className='container-fluid'>
				<Row>
					<Col lg="12">
						<div className="card">
            <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">   

                 <div className="tbl-caption">
                 <h4 className="heading mb-0">مصروفات الشركة</h4>
                 <div>
                 {actionsForPage.includes('create') && (

                        < Link to={"#"} className="btn btn-primary btn-sm m-1" data-bs-toggle="offcanvas"
                                 onClick={() => invite1.current.showInviteModal()}
                               > + إضافة مصروفات </Link> )}
                                                           {actionsForPage.includes('export') && (
 
                                  <Link to={"/CompanyExpensesreport"} className="btn btn-secondary btn-sm mt-1 mt-sm-0" data-bs-toggle="offcanvas">
                                   تقارير المصروفات</Link>
                                                           )}
                                                       

                            </div>
                        </div>  
                        </div>   </div> 
                   
                        
                        		 
								<div className="table-responsive">
									<table {...getTableProps()} className="table dataTable display table-hover">
										<thead>
										{headerGroups.map(headerGroup => (
												<tr {...headerGroup.getHeaderGroupProps()}>
												      {headerGroup.headers.map((column) => (
                        // Check if the column accessor is 'age' and render accordingly
                        column.id !== 'ExpenseID' ? (
                          <th {...column.getHeaderProps()}>
                            {column.render('Header')}
                            {column.canFilter ? column.render('Filter') : null}
                          </th>
                        ) : null
                      ))}
												</tr>
										))}
										</thead> 
                                        <tbody {...getTableBodyProps()} className="" >
                                        
                                        {page.map((row) => {
                                            prepareRow(row)
                                            return(
                                                <tr {...row.getRowProps()}>

                                                    {row.cells.map((cell) => (
          // Check if the column accessor is 'age' and render accordingly
          cell.column.id !== 'ExpenseID' ? (
            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
          ) :null
        ))}


                                                     <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        className="light sharp i-false" 
                      >
                           {svg1}
                       </Dropdown.Toggle>
                      <Dropdown.Menu dir="rtl" className="dropdown-menu-right">
                   

                      {actionsForPage.includes('edit') && (

                        <Dropdown.Item  onClick={(e) =>EditData(e,row.original.ExpenseID)}
>                                
                                                        
                         <Link to className=""
                               >تعديل
                            </Link>
                        </Dropdown.Item>
                      )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
									</table>



									<div className="d-flex justify-content-between mx-2">
										<span>
											الصفحه{' '}
											<strong>
												{pageIndex + 1} من {pageOptions.length}
											</strong>{''}
										</span>
										<span className="table-index ">
											ادهب الى : {' '}
											<input type="number" 
												className="ml-2"
												defaultValue={pageIndex + 1} 
												onChange = {e => { 
													const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
													gotoPage(pageNumber)
												} } 
											/>
										</span>
									</div>
                                    <div className="text-center mb-3">  
                                        <div className="filter-pagination  mt-3">
                                            <button className="previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                                {/* {'<'} */}
                                                <i className='fa-solid fa-angle-right' />
                                            </button>
                                            
                                            <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                                السابق
                                            </button>
                                            <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                                التالي
                                            </button>
                                            <button className="next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                                {/* {'>'} */}
                                                <i className='fa-solid fa-angle-left' />
                                            </button>
                                        </div>
                                    </div>
								</div>
						 
						</div>
					</Col>
				</Row>
			</div>
            <InviteCustomer1
                ref={invite1}     
                parentFunction={fetchProducts}  
            /> 
             
             <InviteCustomer
                
                data={data2}
                parentFunction={fetchProducts}  
                inviteModal={inviteModal}
                show={show}
            /> 
</>


    );
}
export default CompanyExpenses;