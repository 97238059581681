import React from 'react';
import  { useState, forwardRef, useImperativeHandle ,useEffect } from 'react';
import DatePicker from "react-datepicker";
import {Link, useNavigate} from 'react-router-dom';
import  { CurrentTimePicker } from '../jsx/components/Forms/Pickers/TimePicker';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import axios from "axios";
import swal from 'sweetalert';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Employee from './Employee';

const EmpData =forwardRef((props, ref) => {
    const navigate = useNavigate();
    const [adress,setAddress] = useState('')
    const [Datadepartment,setDatadepartment] = useState([]) 
     const [employee, setemployee] = useState([])

  const [empdata,setempdata] = useState('')

  const [imageDate, setimageDate] = useState('')
  const [ImageName, setImageName] = useState('')
  const [Salary, setSalary] = useState('')
  const [DepartmentID, setDepartmentID] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState('')
  const [WorkTime, setWorkTime] = useState('')
  const [ToWorkTime, setToWorkTime] = useState('')
  const [PhoneNumber1, setPhoneNumber1] = useState('')
  const [EmpStatus, setEmpStatus] = useState('')
  const [HireType, setHireType] = useState('')
  const [HireDate, setHireDate] = useState('')
  const [ToHireDate, setToHireDate] = useState('')
  const [FirstName, setFirstName] = useState('')

  const resetAllFields = () => {
    setAddress('');
//    setempdata('');
    setimageDate('');
    setImageName('');
    setSalary('');
    setDepartmentID('');
    setPhoneNumber('');
    setWorkTime('');
    setToWorkTime('');
    setPhoneNumber1('');
    setEmpStatus('');
    setHireType('');
    setHireDate('');
    setToHireDate('');
    setFirstName('');
  };
    const [UserID, setUserID] = useState('')
    const notifyTopRight = () => {
      toast.success("✔️ تمت عملية الحفظ بنجاح", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
    }; 
  const userdata = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata);
    const userid = userData.id;
     //'FirstName' ,	'' ,	'PhoneNumber1' ,	'DepartmentID' , imageDate
 //	'' 	,'' ImagePath
 //	,'WorkTime' ,'ToWorkTime' 	EmpStatus,'' ,	'HireType' ,HireDate	'ToHireDate' ,
 //'ImageName' ,	'' 	,'  ,	'' ,	'UserID' 	];

  const changeHandler = (e)=>{
      setImageName(e.target.files[0]);
      console.log(e.target.files[0])
  }
 
    const getdepartment = () => {
      axios.get('https://suhba.server.com.ly/back/api/departments')
       .then(({ data }) => {
        console.log(data)
          setDatadepartment(data)
       })}
       const getemployee = () => {
        axios.get('https://suhba.server.com.ly/back/api/employee_status_types')
         .then(({ data }) => {
          console.log(data)
          setemployee(data)
         })}
       // StatusTypeID 	StatusName employee_status_types
    const createEmployee = async(e)=>{
      e.preventDefault();
      const formData = new FormData();
      formData.append('FirstName', FirstName)
      formData.append('PhoneNumber', PhoneNumber)
      formData.append('PhoneNumber1', PhoneNumber1)
      formData.append('DepartmentID', DepartmentID)
      formData.append('imageDate', imageDate)
      formData.append('Salary', Salary)
      formData.append('WorkTime', WorkTime)
      formData.append('ToWorkTime', ToWorkTime)
      formData.append('EmpStatus', EmpStatus)
      formData.append('ToHireDate', ToHireDate)
      formData.append('HireDate', HireDate)
      formData.append('UserID', userid)

      formData.append('HireType', HireType)
      formData.append('ImagePath', adress)

      formData.append('ImageName', ImageName)
       

        console.log(formData)
      await axios.post('https://suhba.server.com.ly/back/api/CompEmployee', formData)
     .then(res => {
       
      notifyTopRight();
         
 })
      
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
      resetAllFields();
      props.activeTab('Profile')
      ;} 
 
 
 
 
 const radiocheckBox = [
  {title:'  مرتب', check: 'primary'}, {title:'   يومي', check: 'secondary'}, 
 ];
 const radiocheckBox2 = [
  {title:'  يعمل', check: 'primary'}, {title:'   في إجازة', check: 'secondary'}, {title:'   انهاء العمل', check: 'secondary'},{title:' مفصول', check: 'secondary'},
 ];
 useEffect(() => {
  getdepartment();
    getemployee()
 
}, [])


 return (
  <>
  <ToastContainer />

<form onSubmit={createEmployee} method="POST" enctype="multipart/form-data">
<div className="row">
    <div className="col-xl-4 mb-3">
    <div className="row">
    <div className="col-xl-3 mb-1">
        <label htmlFor="exampleFormControlInput1" className="form-label">اسم الموظف <span className="text-danger">*</span></label>
        </div><div className="col-xl-9 mb-1">
             <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" required name ='FirstName'
              value={FirstName} onChange={(e)=>{setFirstName(e.target.value)}}
></input>
    </div> </div>
    
    
    
    </div>
    <div className="col-xl-4 mb-3">
    <div className="row">
    <div className="col-xl-3 mb-1">
        <label htmlFor="exampleFormControlInput99" className="form-label" > تاريخ انتهاء الشهادة  <span className="text-danger">*</span></label>                                    
        </div><div className="col-xl-9 mb-1">
        <input type='date'
            className="form-control" required name ='imageDate'
            value={imageDate} onChange={(e)=>{setimageDate(e.target.value)}}
        />
        </div></div>
    </div>  
   
    <div className="col-xl-4 mb-3">
    <div className="row">
    <div className="col-xl-3 mb-1">
        <label className="form-label">الصفة<span className="text-danger">*</span></label>
        </div><div className="col-xl-9 mb-1">
        <select className="default-select form-control" required name ="FoodItemID"  value={DepartmentID} onChange={(e)=>{setDepartmentID(e.target.value)}}>
                                 <option  data-display="Select">اختر من القائمة</option>
                                 {
                                   Datadepartment.map((row)=>(
                                  <option key={row.DepartmentID} value={row.DepartmentID}>
                                            {row.DepartmentName}
                                    </option>
                                     ))}    
                                 </select> 
       
    </div>      </div></div>
    <div className="col-xl-4 mb-3">
    <div className="row">
    <div className="col-xl-3 mb-1">
        <label htmlFor="exampleFormControlInput88" className="form-label">المرتب<span className="text-danger">*</span></label>
        </div><div className="col-xl-9 mb-1">
<input type="text" className="form-control" id="exampleFormControlInput88" placeholder="" required name ='Salary'
 value={Salary} onChange={(e)=>{setSalary(e.target.value)}}
/> 
    </div> 
        </div></div>
    <div className="col-xl-4 mb-3">
    <div className="row">
    <div className="col-xl-3 mb-1">
        <label htmlFor="exampleFormControlInput88" className="form-label">رقم الهاتف <span className="text-danger">*</span></label>
        </div><div className="col-xl-9 mb-1">
             <input type="text" className="form-control" id="exampleFormControlInput88" placeholder=""  required name ='PhoneNumber'
         value={PhoneNumber} onChange={(e)=>{setPhoneNumber(e.target.value)}}
/>
    </div> </div></div>
    <div className="col-xl-4 mb-3">
    <div className="row">
    <div className="col-xl-3 mb-1">
        <label htmlFor="exampleFormControlInput88" className="form-label">رقم الهاتف 2 <span className="text-danger">*</span></label>
        </div><div className="col-xl-9 mb-1">  
          <input type="text" className="form-control" id="exampleFormControlInput88" placeholder="" required name ='PhoneNumber1'
          value={PhoneNumber1} onChange={(e)=>{setPhoneNumber1(e.target.value)}}
></input>
    </div>     </div>     </div>
    <div className="col-xl-4 mb-3">
    <div className="row">
    <div className="col-xl-3 mb-1" > 
    <label className="form-label">العنوان <span className="text-danger">*</span></label>

    </div><div className="col-xl-9 mb-1"  >  
        <textarea rows="2" required name ='Address' className="form-control" value={adress} onChange={(e)=>{setAddress(e.target.value)}}
 ></textarea>
    </div></div></div>
   
   <div className="col-xl-4 mb-3">
   <div className="row">

   <div className="col-xl-3 mb-1" > 
   <label htmlFor="exampleFormControlInput88" className="form-label">نوع المرتب<span className="text-danger">*</span></label>

    </div>
    
    <div className="col-xl-9 mb-1" > 

<select className="default-select form-control" required name ="HireType"  value={HireType} onChange={(e)=>{setHireType(e.target.value)}}
 >
                                 <option  data-display="Select">اختر من القائمة</option>
                                 {
                                   radiocheckBox.map((row)=>(
                                  <option key={row.title} value={row.title}>
                                            {row.title}
                                    </option>
                                     ))}    
                                 </select> 

</div>
</div></div>

<div className="col-xl-4 mb-3">
<div className="row">
   <div className="col-xl-3 mb-1" > 
   <label htmlFor="exampleFormControlInput88" className="form-label">حالة الموظف<span className="text-danger">*</span></label>

    </div>
    
    <div className="col-xl-9 mb-1" > 

<select className="default-select form-control" required name ="EmpStatus"   value={EmpStatus} onChange={(e)=>{setEmpStatus(e.target.value)}}
>
                                 <option  data-display="Select">اختر من القائمة</option>
                                 {
                                   employee.map((row)=>(
                                  <option key={row.StatusTypeID} value={row.StatusTypeID}>
                                            {row.StatusName} 	
                                    </option>
                                     ))}    
                                 </select> 

</div>
</div>
</div>

    <div className="col-xl-4 mb-3">
    <div className="row">
    <div className="col-xl-3 mb-1">
                    <label htmlFor="formFile" className="form-label">   الشهادةالصحية</label>
                    </div>
                    <div className="col-xl-9 mb-1"> 
                      <input multiple className="form-control" type="file" id="formFile" required name ='ImageName' 
                      onChange={(e) => changeHandler(e)}
                      />

        </div></div></div>
    
      <div className="col-md-4 mb-3">
      <div className="row">

      <div className="col-xl-3 mb-1">
        
                    <label htmlFor="formFile" className="form-label">تاريخ البدأ</label>
                    </div>
                    <div className="col-xl-9 mb-1"> 
     <input
                                       type="date"
                                       className="form-control  " style={{float: "right"}}
                                       id="same-address"    required name ="HireDate" value={HireDate} onChange={(e)=>{setHireDate(e.target.value)}}
></input>
 
 </div> 
 </div> 
                                                </div> 
                                                <div className="col-md-4 mb-3">
                                                <div className="row">

      <div className="col-xl-3 mb-1">
                    <label htmlFor="formFile" className="form-label">تاريخ الانتهاء</label>
                    </div>
                    <div className="col-xl-9 mb-1"> 
     <input
                                       type="date"
                                       className="form-control  " style={{float: "right"}}
                                       id="same-address" value={ToHireDate} onChange={(e)=>{setToHireDate(e.target.value)}}
   required name ="ToHireDate"></input>
 
 </div> 
 </div> 
                                                </div> 
                                                <div className="col-xl-3 mb-1 "  >
                                                <p className="mb-1" > توقيت العمل من  </p>

                                  <input
                                    type="time"
                                    className="form-control ms-3 mb-3 "  
                                    id="same-address"
                                    required name ="WorkTime"
                                    value={WorkTime} onChange={(e)=>{setWorkTime(e.target.value)}}

                                   /></div>
  
  <div className="col-xl-3 mb-1 "  >
                                                <p className="mb-1" >الى </p>

                                  <input
                                    type="time"
                                    className="form-control ms-3 mb-3 "  
                                    id="same-address"
                                    required name ="ToWorkTime"
                                    value={ToWorkTime} onChange={(e)=>{setToWorkTime(e.target.value)}}

                                   /></div>
          

  


 </div>
<div className="card-footer">
    <button type="submit" id="toastr-success-top-right" className="btn btn-primary me-1">حفظ</button>
    
    <Link to={"#"}  id="toastr-success-top-right"   className="btn btn-danger light mx-2">إلغاء</Link>
</div>
</form>
</>

)}
)
export default EmpData;